import config from 'player/config';
import type { Asset } from '@schibsted-svp/svp-api-types';

async function makeFetch<Data extends Asset | Asset[]>(
    url: string,
): Promise<Data> {
    const response = await fetch(url);

    if (!response.ok) {
        return Promise.reject(response);
    }

    const data: Data = await response.json();
    return data;
}

export const APPNEXUS_PROVIDER_ID = 'appnexus';

export const assetAdditionalData = [
    'settings',
    'chapters',
    'cuePoints',
    'externalId',
    'barrels',
    'externalCategoryId',
    'nextAsset',
    'sourceFiles',
    'subtitles',
    'metadata',
    'access',
    'tags',
    'stories',
    'playlist',
].join('|');

export const api = ({
    vendor,
    endpoint,
}: {
    vendor: string;
    endpoint: string;
}): Promise<Asset> => makeFetch<Asset>(config.api.getUrl(vendor, endpoint));

export const relatedApi = ({
    path,
    query,
}: {
    path: string;
    query: string;
}): Promise<Asset[]> => {
    const relatedApiUrl = config.api.relatedApiUrl.replace(/\/$/, '');

    return makeFetch<Asset[]>(`${relatedApiUrl}/${path}?${query}`);
};
