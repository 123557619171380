import type { Asset } from '@schibsted-svp/svp-api-types';
import { getRelatedAssets } from 'player/services/api/related';
import { getById } from 'player/services/api/asset';
import Stream from './stream';
import AssetModel from './asset';

type Source = 'byId' | 'related';

const createResponse = ({
    nextAsset,
    source,
}: {
    nextAsset?: Asset;
    source: Source;
}): {
    nextAsset: AssetModel | null;
    source: Source;
} => ({
    nextAsset: nextAsset ? new AssetModel(nextAsset) : null,
    source,
});

const handlers = {
    /**
     * Check if given asset has next stream
     * Used for setting/disabling postrolls
     */
    hasPlayNext(asset: AssetModel): boolean {
        if (asset.getStreamType() === 'live') {
            return false;
        }

        if (asset.getNextAssetId()) {
            return true;
        }

        return false;
    },

    async fetchNextById({
        vendor,
        assetId,
    }: {
        vendor: string;
        assetId: number;
    }): Promise<{
        nextAsset: AssetModel | null;
        source: Source;
    }> {
        const nextAsset = await getById({
            vendor,
            assetId,
        });

        return createResponse({
            source: 'byId',
            nextAsset,
        });
    },

    async fetchNextFromRelatedEngine({
        vendor,
        assetId,
    }: {
        vendor: string;
        assetId: number;
    }): Promise<{
        nextAsset: AssetModel | null;
        source: Source;
    }> {
        const assets = await getRelatedAssets({
            vendor,
            assetId,
            limit: 1,
        });

        return createResponse({
            nextAsset:
                Array.isArray(assets) && assets.length > 0
                    ? assets[0]
                    : undefined,
            source: 'related',
        });
    },

    /**
     * Fetch next for given asset
     * Cascade  lookup from direct linkage (nextAsset.id) to retrieving from category
     */
    async getNext(asset: AssetModel): Promise<{
        nextAsset: AssetModel | null;
        source: Source;
    }> {
        const vendor = asset.getVendor();
        const assetId = asset.getId();

        return this.fetchNextFromRelatedEngine({
            vendor,
            assetId,
        });
    },

    /**
     * Fetch next for given asset
     * Cascade  lookup from direct linkage (nextAsset.id) to retrieving from category
     * Rejected when nextAsset is not found
     */
    async fetchNext(asset: AssetModel): Promise<{
        nextAsset: AssetModel | null;
        source: Source;
    }> {
        const { nextAsset, source } = await this.getNext(asset);

        if (!nextAsset) {
            return Promise.reject({
                reason: 'nextAssetNotFound',
                source,
            });
        }

        const stream = new Stream(nextAsset.attributes);

        if (!stream.isActive()) {
            return Promise.reject({
                reason: 'nextAssetNotActive',
                source,
            });
        }

        return {
            nextAsset,
            source,
        };
    },
};

export default handlers;
