import utils from 'utils/utils';
import config from 'player/config';
import { styleLoader } from 'utils/style-loader';
import Provider from '../playback/provider';
import SvpPlugins from '../plugins';
import { onPlayerEvent } from './helpers';

/**
 * @param {Object} options - Caution, param is bound in constructor
 * @param {Object} settings
 */
export function onceConfigReady(options, settings) {
    this.model = new Provider(this.config);

    // important listeners has to be attached before setup
    this.listenTo(this.model, 'all', onPlayerEvent, this);

    this.listenTo(
        this.model,
        'play',
        function () {
            this.isCompleted = false;
        },
        this,
    );

    // turn on cachebreaker for preview mode
    if (settings && settings.preview === true) {
        config.api.cb = true;
    }

    // once per player instance error append stylesheet
    this.once(
        'error',
        function () {
            styleLoader(this.config.get('skin').url);
        },
        this,
    );

    Promise.all([
        new Promise((resolve) => {
            const handleReady = () => {
                resolve();
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                this.off('assetError', handleError);
            };

            const handleError = (error) => {
                resolve(error);
                this.off('assetError', handleReady);
            };

            this.once('assetReady', handleReady);
            this.once('assetError', handleError);
        }),
        SvpPlugins.load(options.plugins),
    ]).then(([error]) => {
        utils.each(options.plugins, (pluginConfig, plugin) => {
            const Plugin = SvpPlugins.get(pluginConfig.name || plugin);

            if (Plugin) {
                // support for new loading method
                this.addPlugin(new Plugin(pluginConfig.config || pluginConfig));
            }
        });

        if (!error) {
            this.model.setup();
        }
    });

    // asset can be passed as id or an object
    this.setAsset(options.asset || options.id);
}
