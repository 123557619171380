/* eslint-disable no-multi-assign */

const loadedStylesheets = [];

/**
 * @param {string} href
 * @param {() => void} onSuccess
 * @param {() => void} onError
 */
// eslint-disable-next-line consistent-return
function loadStylesheet(href, onSuccess, onError) {
    if (loadedStylesheets.indexOf(href) > -1) {
        return onSuccess();
    }

    const callback = onSuccess || function () {};
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = href;

    let done = false;

    link.onreadystatechange = link.onload = function () {
        if (
            !done &&
            (!this.readyState ||
                this.readyState === 'loaded' ||
                this.readyState === 'complete')
        ) {
            done = true;
            callback();

            // Handle memory leak in IE
            link.onload = link.onreadystatechange = null;
        }
    };

    link.onerror = function () {
        loadedStylesheets.splice(loadedStylesheets.indexOf(href), 1);
        onError();
    };

    loadedStylesheets.push(href);
    head.appendChild(link);
}

/**
 * @param {string} path
 */
export const styleLoader = (path) =>
    new Promise((resolve, reject) => {
        loadStylesheet(path, resolve, reject);
    });
