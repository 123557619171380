import logger from 'utils/logger';

/**
 * events hashmap playerId -> eventBus
 * each player has its own events bus
 *
 * @type Record<string, Backbone.Events>
 */
export const events = {};

/**
 * Homad AAB ads have single event bus for all players
 *
 * @param {CustomEvent} event
 */
function parseHomadEvent(event) {
    const details = event && event.detail;
    // skip parsing when there is missing data or player is not registered
    if (!details || !events[details.containerId]) {
        return;
    }

    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        name,
        container,
        containerId,
        player,
        videoTag,
        videoTagId,
        ...data
    } = details;

    logger('Homad').log(details.name, data);

    events[details.containerId].trigger(details.name, data);
}

export function translateHomadEvents() {
    window.addEventListener('hdEvent', parseHomadEvent);
}
