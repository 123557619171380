/**
 * Stream completed, fired for every item in playlist
 * Clear settings which are only for one stream
 * @param {string} reason
 */
export function onComplete(reason) {
    if (!this.isCompleted) {
        this.isCompleted = true;

        this.config.set('time', null);
        this.config.set('chapter', null);

        this.trigger('complete', reason);
    }
}
