import utils from 'utils/utils';
/**
 * Check if preroll is scheduled in configuration
 *
 * @param {*} config
 * @returns {boolean}
 */
function hasPreroll(config) {
    const playlistItem = config && config.playlist[0];
    let result = false;

    if (playlistItem.adschedule) {
        utils.each(playlistItem.adschedule, function (slot) {
            if (slot && slot.offset === 'pre') {
                result = true;
            }
        });
    }

    return result;
}

/**
 * Check if given stream can be autoplayed
 * Rules for autoplay depends on device but also on flags given to playback
 *
 * @deprecated new autoplay policies require starting of video to detect if autoplay will work
 * @param {*} config - JW Configuration
 * @returns {boolean}
 */
function canAutoplay(config) {
    var canDeviceAutoplay = utils.device.canAutoplay();

    // enable autoplay for devices with disabled ads and at least muted autoplay ability ios10
    var result = canDeviceAutoplay === 'always';

    if (canDeviceAutoplay === 'muted' && !hasPreroll(config)) {
        result = true;
    }

    return result;
}

export default {
    canAutoplay: canAutoplay,
};
