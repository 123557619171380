/* eslint-disable no-param-reassign */
import utils from 'utils/utils';
import Model from 'utils/model';

/**
 * @param {SvpCategory} data
 */
const Category = function (options) {
    this.attributes = {
        id: 0,
    };

    this.initialize(options);
};

Category.prototype = {
    /**
     * @param {SvpCategory} data
     */
    initialize(data) {
        utils.extend(this.attributes, this.parse(data));
    },

    /**
     * @param {SvpCategory} data
     * @returns {ParsedCategory}
     */
    parse(data) {
        if (data.additional) {
            utils.each(data.additional, (value, key) => {
                if (typeof data[key] === 'undefined') {
                    data[key] = value;
                }
            });

            data.images = utils.extend({}, data.images || {}, {
                background: data.background,
                main: data.image,
                logo: data.logo,
                playerLogo: data.playerLogoImage,
            });

            delete data.additional;
            delete data.background;
            delete data.image;
            delete data.logo;
            delete data.playerLogoImage;
        }

        return data;
    },

    /**
     * @returns {number}
     */
    getId() {
        return this.get('id');
    },

    /**
     * @returns {string}
     */
    getTitle() {
        return this.get('title');
    },
};

utils.extend(Category.prototype, Model);

export default Category;
