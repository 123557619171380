import type {
  Category,
  CategoryAdditional,
} from '@schibsted-svp/svp-api-types';

const defaultCategoryMetadata: CategoryAdditional['metadata'] = {};
Object.freeze(defaultCategoryMetadata);
export const getCategoryMetadata = (category?: Category) => {
  return category?.additional?.metadata ?? defaultCategoryMetadata;
};

export const getCategoryMetadataValue = (
  category: Category | undefined,
  name: string
) => getCategoryMetadata(category)[name]?.value;

export const getCategoryShortPath = (category: Category) =>
  category.additional?.shortPath ?? undefined;

export const getCategoryShortPreviewUrl = (category?: Category) =>
  getCategoryMetadata(category).shortpreview?.value;
