import type { Player } from '@schibsted-svp/web-player';

import type { DeviceType } from '../types';

type StartMode = 'silent' | 'auto' | 'manual';

function getStartMode(
  autoplay: boolean | 'viewable',
  mute: boolean
): StartMode {
  if (autoplay && mute) {
    return 'silent';
  }

  if (autoplay) {
    return 'auto';
  }

  return 'manual';
}

function getVideoPlaybackMethod(startMode: StartMode) {
  switch (startMode) {
    case 'auto':
      // autoplay, sound on
      return 1;
    case 'silent':
      // autoplay, sound off
      return 2;
    default:
      // click to play - default
      return 3;
  }
}

/**
 * @returns {'ios'|'android'|'unknown'}
 */
function getMobileOS() {
  const containsIPhone = navigator.userAgent.match(/iphone/i);
  const containsAndroid = navigator.userAgent.match(/android/i);

  if (containsIPhone && !containsAndroid) {
    return 'ios';
  }

  if (containsAndroid && !containsIPhone) {
    return 'android';
  }

  return 'unknown';
}

/**
 * Returns a set of environment related keywords. These are not used for targeting, but instead for reporting and debugging issues.
 */
function getEnvironmentKeywords(player: Player, deviceType: DeviceType) {
  const config = player.getRawConfig();
  const { mute, autoplay } = config;

  const startMode = getStartMode(autoplay!, mute!);

  return {
    'se-generic-mobile-os': getMobileOS(),
    'se-generic-sitemode': deviceType.replace('phone', 'mobile'),
    'se-aftonbladet-navigation': 'spa',
    'se-generic-appmode': 'notinapp',
    'se-generic-screenheight': window.screen.height.toString(),
    'se-generic-screenwidth': window.screen.width.toString(),
    'se-generic-viewportheight': window.innerHeight.toString(),
    'se-generic-viewportwidth': window.innerWidth.toString(),
    'vskippable': '0',
    'vplaybackmethod': getVideoPlaybackMethod(startMode).toString(),
  };
}

export { getEnvironmentKeywords };
