import { scriptLoader } from 'utils/script-loader';
import logger from 'utils/logger';
import { createClass as createPluginClass } from 'player/plugins/model';

/**
 * @type {Record<string, PluginModel>}
 */
const plugins = {};

const Plugins = {
    /**
     * @param {PluginModel} plugin
     */
    register(plugin) {
        const name = plugin.getName();

        // register the same plugin only once
        if (!plugins[name]) {
            plugins[name] = createPluginClass(plugin);
        }
    },

    load(options) {
        // no plugins provided, complete immediately
        if (!options) {
            // timeout for proper event listener fire
            return Promise.resolve();
        }

        const scripts = Object.keys(options);

        // do not change this to an arrow function - it breaks iOS playback
        async function loadPlugin(pluginSrc) {
            try {
                await scriptLoader(pluginSrc);
            } catch (error) {
                logger().error(`An error occur while loading ${pluginSrc}`);
            }

            return pluginSrc;
        }

        return Promise.all(scripts.map(loadPlugin));
    },

    /**
     * @param {string} name
     * @returns {PluginModel|undefined}
     */
    get(name) {
        return plugins[name];
    },
};

export default Plugins;
