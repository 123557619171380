import type { GamKeywords, XandrKeywords } from './types';

/**
 * @example
 * input { keyword: ['value1', 'value2'] }
 * output { keyword: 'value1,value2' }
 */
export const collapseArrayKeywords = (values: XandrKeywords) => {
  return Object.entries(values).reduce(
    (keywords, [key, value]) => ({
      ...keywords,
      [key]: Array.isArray(value) ? value.join(',') : value,
    }),
    {} as GamKeywords
  );
};

/**
 * @example
 * input { keyword1: 'value1', keyword2: undefined, keyword3: [] }
 * output { keyword1: 'value1' }
 */
export const cleanupKeywordsObject = (values: Partial<XandrKeywords>) =>
  Object.entries(values)
    // exclude undefined values
    .filter(
      (pair): pair is [string, XandrKeywords[keyof XandrKeywords]] =>
        pair[1] !== undefined && pair[1] !== null
    )
    // exclude empty array values
    .filter(([, value]) => !(Array.isArray(value) && value.length === 0))
    .reduce(
      (keywords, [key, value]) => ({ ...keywords, [key]: value }),
      {} as XandrKeywords
    );

/**
 * @example
 * input { 'aa-keyword2': 'value2' }
 * output { 'aa-keyword2': 'value2', 'kw_aa-keyword2': 'value2' }
 */
export const duplicateAndPrefixKeywords = (values: XandrKeywords) => {
  const keywords = { ...values };
  Object.keys(keywords).forEach((key) => {
    // duplicate keywords
    if (
      key.indexOf('no-') === 0 ||
      key.indexOf('aa-') === 0 ||
      key.indexOf('se-') === 0
    ) {
      keywords[`kw_${key}`] = values[key];
    }
  });

  return keywords;
};

export const prepareXandrKeywords = (values: Partial<XandrKeywords>) =>
  duplicateAndPrefixKeywords(cleanupKeywordsObject(values));

export const prepareGamKeywords = (values: Partial<XandrKeywords>) =>
  collapseArrayKeywords(cleanupKeywordsObject(values));
