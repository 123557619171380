import { getQueryStringParams } from '../../helpers/getQueryStringParams';

function getQueryStringKeywords() {
  const params = getQueryStringParams();

  return Object.keys(params)
    .filter((key) => /^kv_/.test(key))
    .reduce(
      (acc, key) => {
        const normalizedKey = key.replace(/^kv_/, '');
        acc[normalizedKey] = params[key];

        return acc;
      },
      {} as Partial<Record<string, string>>
    );
}

export { getQueryStringKeywords };
