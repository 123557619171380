import { Provider } from '@vgtv/api-client';

/**
 * Returns the hostname of Schibsted Video Portal for the given provider.
 */
function getPortalHostname(provider: Provider | 'brandstudio') {
  if (provider === Provider.AB) {
    return 'tv.aftonbladet.se';
  }

  return 'tv.vg.no';
}

export { getPortalHostname };
