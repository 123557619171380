import { isIOS, isSafari } from './device';

const isAppleClient = () => {
  return isSafari || isIOS;
};

const getSafariVersion = () => {
  const [, safariVersion] =
    navigator.userAgent.match(/Version\/(\d+(?:\.\d+)+)/i) || [];

  return parseInt(safariVersion!.split('.')[0], 10);
};

const getIosVersion = () => {
  const [, iosVersion] =
    navigator.userAgent.match(/OS ((\d+_?){2,3})\s/i) || [];

  return parseInt(iosVersion!.split('.')[0], 10);
};

export function areAirplayAdsEnabled() {
  const SUPPORTED_VERSION = 16;
  if (!isAppleClient()) {
    return false;
  }
  try {
    let version;
    if (isSafari) {
      version = getSafariVersion();
      return version! >= SUPPORTED_VERSION;
    }

    if (isIOS) {
      version = getIosVersion();
      return version! >= SUPPORTED_VERSION;
    }
  } catch {
    return false;
  }
  return false;
}
