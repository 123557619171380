/**
 * @type {Record<string, string>}
 */
let translations = {};

/**
 * @param {Record<string, string>} data
 */
const setTranslations = (data) => {
    translations = data;
};

/**
 * @returns {Record<string, string>}
 */
const getTranslations = () => translations;

/**
 * @param {string} key
 * @param {string} [substitute]
 * @returns {string}
 */
// eslint-disable-next-line arrow-body-style
const translate = (key, substitute) => {
    // typeof to check keys like "0"
    return (
        translations[key] ||
        (typeof substitute === 'undefined' ? key : substitute)
    );
};

/**
 * @param {Date} date
 * @returns {string|undefined}
 */
const getMonthName = (date) => {
    const months = translations.months || [];
    return months[date.getMonth()] || date.getMonth();
};

export default {
    translate,
    setTranslations,
    getMonthName,
    getTranslations,
};
