import { capitalizeFirstLetter } from 'utils/string';
import logger from 'utils/logger';
import utils from 'utils/utils';
import { isEmpty } from 'utils/underscore';
import configuration from 'player/config';
import PluginModel from './model';
import PulseTracker from './pulse-stats/tracker';
import AdEvents from './pulse-stats/ad-events';
import PlaybackEvents from './pulse-stats/playback-events';
import UiEvents from './pulse-stats/ui-events';
import CategoryTree from '../model/category-tree';

/**
 * Retrieve tags from asset
 * @param asset
 */
const getTags = (asset) =>
    (asset.get('tags') || [])
        .filter((tag) => utils.isObject(tag) || !isEmpty(tag.tag))
        .map(({ tag }) => tag);

export const getMediaAssetId = (provider, mediaId) =>
    `sdrn:${provider}:mediaasset:${mediaId}`;

/**
 * @param {string[]} accessControl
 * @param {string} provider
 * @param {{variant: {type: 'Subscription' | 'Login'}}} experiment
 * @returns {'Free' | 'Login' | 'Paid'}
 */
export const getAssetLevel = (accessControl, provider, experiment) => {
    if (accessControl.length > 0) {
        if (
            accessControl.includes('login') ||
            // special case for vgtv configuration - premium is login :)
            (provider === 'vgtv' && accessControl.includes('premium'))
        ) {
            return 'Login';
        }
        return 'Paid';
    }
    if (experiment?.variant?.Type) {
        if (experiment.variant.Type === 'Subscription') {
            return 'Paid';
        }
        if (experiment.variant.Type === 'Login') {
            return 'Login';
        }
    }
    return 'Free';
};

/**
 * @param {string[]} accessControl
 * @param {{variant: {type: 'Subscription' | 'Login'}}} experiment
 * @returns {string}
 */
export const getAssetLevelName = (accessControl, experiment) => {
    if (accessControl.length > 0) {
        return accessControl.join(',');
    }
    if (experiment?.variant?.Type) {
        return experiment.variant.Type;
    }
    return 'Free';
};

const getImageUrl = (asset) => {
    const image = asset.getThumbnail();

    if (image) {
        return `${image}?t[]=480q80`;
    }

    return undefined;
};

/**
 * Integration with Pulse Tracker
 * https://github.schibsted.io/spt-dataanalytics/pulse-autotracker
 *
 * @param options
 * @constructor
 */
class PulseStatsPlugin extends PluginModel {
    constructor(options, experiment, isExperimentEnabled, isMockAsset) {
        super(options);
        this.provider = options.provider;
        this.sdkConfig = options.sdkConfig;
        this.decorator = options.decorator || ((data) => data);
        this.intervals = {
            ...(options?.engagementIntervals?.live && {
                live: options.engagementIntervals.live,
            }),
            ...(options?.engagementIntervals?.vod && {
                vod: options?.engagementIntervals?.vod,
            }),
        };
        this.embed = options.embed === true;
        this.previousAssetId = options.previousAssetId;
        this.tracker = null;
        this.experiment = experiment;
        this.isExperimentEnabled = isExperimentEnabled;
        this.isMockAsset = isMockAsset;
        this.setupErrorEventEmitted = false;
        this.tts = options.tts || { enabled: false };
    }

    // eslint-disable-next-line
    getName() {
        return 'PulseStats';
    }

    get jw() {
        try {
            return this.player.model.player || {};
        } catch (e) {
            return {};
        }
    }

    get asset() {
        const asset = this.playerAsset;
        const additional = asset.get('additional') || {};
        const accessControl = Object.keys(additional.access || {});
        const tags = getTags(asset);

        const data = {
            assetType: capitalizeFirstLetter(asset.get('assetType')),
            title: asset.get('title'),
            // duration should be in seconds
            duration: Math.round(asset.get('duration') / 1000),
            imageUrl: getImageUrl(asset),
        };

        if (accessControl.length > 0) {
            // @deprecated
            data.accessControl = accessControl.join(',');
        }

        data.accessLevel = getAssetLevel(
            accessControl,
            asset.get('provider'),
            this.experiment,
        );
        data.accessLevelName = getAssetLevelName(
            accessControl,
            this.experiment,
        );

        if (tags.length > 0) {
            data.tags = tags;
        }

        // check if stream has category
        if (asset.getCategory()) {
            data.category = asset.getCategory().getId();
        }

        // delete duration from live assets
        if (asset.getStreamType() === 'live') {
            delete data.duration;
        }

        if (additional.originAssetId) {
            data.originAssetId = additional.originAssetId;
        }

        if (this.tts.enabled) {
            data.assetType = 'AudioTTS';
        }

        return data;
    }

    get apiUrl() {
        const { url } = configuration.api;
        const asset = this.playerAsset;
        const protocol = !url.startsWith('http') ? 'https:' : '';
        return `${protocol}${url}${asset.getVendor()}/assets/${asset.getId()}?appName=linkpulse`;
    }

    get isAutoplay() {
        const autoplay = this.player.config.get('autoplay');
        return autoplay === true || autoplay === 'viewable';
    }

    getCategoryPath(categoryId) {
        if (typeof categoryId !== 'number') {
            return null;
        }

        // check if id exist and create category path
        if (!this.categoryPath) {
            this.categoryPath = this.categoryTree
                .getPath(categoryId)
                .map((category) => category.title.toLowerCase())
                .join(' > ');
        }

        return this.categoryPath;
    }

    /**
     * Track Pulse event
     * Defer tracking when Pulse library is not loaded yet
     */
    async track(eventName, eventData) {
        const tracker = await this.getTracker();
        const data = {};
        Object.assign(
            data,
            eventData,
            this.isExperimentEnabled && {
                experiments: [
                    {
                        id: this.experiment.id,
                        variant: this.experiment.variant
                            ? this.experiment.variant.id
                            : '0',
                        platform: this.provider,
                    },
                ],
            },
        );
        logger('Pulse').log(JSON.stringify(data, null, 4));
        tracker.track(eventName, data);
    }

    async trackMediaEvent(data) {
        try {
            if (!this.isMockAsset) {
                await this.categoryTree.fetch();
            }
            // category data is available on ready event
            const categoryPath = this.getCategoryPath(data.object.category);

            if (categoryPath) {
                data.object.category = categoryPath;
            }
            await this.track(
                'engagementEvent',
                this.decorator(data, this.playerAsset),
            );
        } catch (e) {
            logger('Pulse').log('Could not track event');
        }
    }

    getTrackerOrNull() {
        return new Promise((resolve) => {
            const resolveTracker = () => resolve(this.tracker);

            if (this.tracker !== null || this.setupErrorEventEmitted) {
                resolveTracker();
            } else {
                this.once('ready', resolveTracker);
                this.once('setupError', resolveTracker);
            }
        });
    }

    getTracker() {
        return new Promise((resolve) => {
            const resolveTracker = () => resolve(this.tracker);

            if (this.tracker === null) {
                this.once('ready', resolveTracker);
            } else {
                resolveTracker();
            }
        });
    }

    setup() {
        // prevent asset to be changed with playnext before stream completes
        this.playerAsset = this.player.getAsset({
            shouldLogDeprecatedWarning: false,
        });

        this.categoryTree = CategoryTree(this.playerAsset.getVendor());

        this.adEvents = new AdEvents(this.player);
        this.playbackEvents = new PlaybackEvents(this.player, {
            vodInterval: this.intervals.vod,
            liveInterval: this.intervals.live,
        });
        this.uiEvents = new UiEvents(this.player);

        // initialize only once
        Promise.all([
            PulseTracker.init(this.provider, this.sdkConfig),
            this.isMockAsset ? null : this.categoryTree.fetch(),
        ])
            .then((data) => this.onReady(data))
            .catch(() => {
                this.setupErrorEventEmitted = true;
                this.trigger('setupError');
            });

        this.listenTo(this.adEvents, 'all', this.onAdEvent, this);
        if (!this.isMockAsset) {
            this.listenTo(
                this.playbackEvents,
                'all',
                this.onPlaybackEvent,
                this,
            );
        }
        this.listenTo(this.uiEvents, 'all', this.onUiEvent, this);
    }

    onReady(data) {
        const [tracker] = data;

        this.tracker = tracker;
        this.trigger('ready');
    }

    attachSessionData(trackingData, data) {
        const asset = this.playerAsset;
        if (data.previousAssetId) {
            trackingData.object.previousAssetId = getMediaAssetId(
                asset.getVendor(),
                data.previousAssetId,
            );
            trackingData.object.playbackSource = data.playbackSource;
        } else if (this.previousAssetId) {
            trackingData.object.previousAssetId = getMediaAssetId(
                asset.getVendor(),
                this.previousAssetId,
            );
            trackingData.object.playbackSource = 'config';
        }
    }

    onPlaybackEvent(intent, data = {}) {
        const asset = this.playerAsset;

        /**
         * Autoplay is not set for load method thus we use value set in player constructor
         * For other events method of playback start is checked
         * Autoplay is set to true when player actually autostarts or when its started with external call (.play())
         */
        const trackingData = {
            intent,
            '@type': 'Engagement',
            position: data.position,
            // duration is in ms
            duration: data.duration * 1000,
            object: {
                '@id': getMediaAssetId(asset.getVendor(), asset.getId()),
                '@type': 'MediaAsset',
                name: asset.getTitle(),
                muted: this.jw.getMute(),
                fullscreen: this.jw.getFullscreen(),
                accessControl: asset.accessControl || 'free',
                autoplay:
                    data.playMethod === null
                        ? this.isAutoplay
                        : data.playMethod === 'autostart' ||
                          data.playMethod === 'external' ||
                          data.playMethod === 'viewable',
                adSequenceCount: data.count,
                embed: this.embed,
                streamType: asset.getStreamType(),
                adblock: this.player.model.player.getAdBlock(),
                apiUrl: this.apiUrl,
                preview: Boolean(this.player.config.get('videoPreview')),
                'spt:custom': {
                    'spt:streamProviderId': asset.getVendor(),
                },
                ...this.asset,
            },
        };

        const { adSequenceDuration } = this.adEvents.adData;
        if (adSequenceDuration) {
            trackingData.object.adSequenceDuration = adSequenceDuration;
            trackingData.object.adSequenceCount = adSequenceDuration.length;
        }

        if (data.start === true) {
            trackingData.start = true;
        }

        this.attachSessionData(trackingData, data);

        this.trackMediaEvent(trackingData);
    }

    onAdEvent(intent, data) {
        const asset = this.playerAsset;
        const trackingData = {
            intent,
            '@type': 'Engagement',
            position: data.position,
            // position and duration are equal because ads does not allow to seek through content
            // duration is in ms
            duration: data.position && data.position * 1000,
            object: {
                '@id': `sdrn:${this.provider}:mediaad:${data.id}`,
                '@type': 'MediaAd',
                assetType: capitalizeFirstLetter(data.type),
                muted: this.jw.getMute(),
                fullscreen: this.jw.getFullscreen(),
                autoplay: this.isAutoplay,
                mediaAssetId: getMediaAssetId(asset.getVendor(), asset.getId()),
                adSequenceCount: data.count,
                adblock: this.player.model.player.getAdBlock(),
                apiUrl: this.apiUrl,
            },
        };

        if (data.adSequenceDuration) {
            trackingData.object.adSequenceDuration = data.adSequenceDuration;
            trackingData.object.adSequenceCount =
                data.adSequenceDuration.length;
        }

        if (data.sequence) {
            trackingData.object.adSequencePosition = data.sequence;
            if (
                data.adSequenceDuration &&
                data.adSequenceDuration[data.sequence - 1]
            ) {
                trackingData.object.duration =
                    data.adSequenceDuration[data.sequence - 1];
            }
        }

        if (data.start === true) {
            trackingData.start = true;
        }

        if (asset.getCategory()) {
            trackingData.object.category = this.categoryTree
                ?.getPath(asset.getCategory()?.getId())
                .map((i) => i.title)
                .join(' > ')
                .toLocaleLowerCase();
        }

        this.attachSessionData(trackingData, this.playbackEvents.eventData);

        return this.trackMediaEvent(trackingData);
    }

    async onUiEvent(eventName, data) {
        const asset = this.playerAsset;
        const trackingData = {
            '@type': 'View',
            object: Object.assign(data, {
                '@id': getMediaAssetId(asset.getVendor(), data.id),
                '@type': 'UIElement',
                apiUrl: this.apiUrl,
            }),
        };

        this.track('viewEvent', trackingData);
    }

    destroy() {
        this.stopListening();

        this.adEvents.destroy();
        this.playbackEvents.destroy();
    }
}

export default PulseStatsPlugin;
