export const fallbackChromecastApp = 'A753C1FE';
export const chromecastApps = {
    CB3CFFEE: ['ab'],
    '96E78488': ['svd'],
    C6559D78: ['ridenws'],
    E43A13ED: ['brandstudio'],
    '8AE3CE4D': ['riks'],
    '9E7A477E': ['100sport'],
    '35E82475': ['sa'],
    B6C9EA8E: ['bt'],
    E64998E3: ['ap'],
    '615D672F': ['schibsted'],
    A7738716: ['tvnu'],
    A5965089: ['vgtv', 'vgtvexternal', 'vgtvs'],
    '06A55D09': [
        'fvn',
        'adressa',
        'altaposten',
        'folkebladet',
        'harstad',
        'itromso',
        'rbnett',
        'tronderavisa',
        'adressabrandstudio',
        'smp',
        'steinkjer',
        'avisast',
        'bladet',
        'banett',
        'innherred',
        'fosnafolket',
        'opp',
        'tronderbladet',
        'stjordalsnytt',
        'mn24',
        'hitrafroya',
        'framtidinord',
        'vaganavisa',
        'vol',
        'nyttiuka',
        'vikebladet',
        'fjt',
        'vestlandsnytt',
        'fjuken',
        'vigga',
        'dolen',
        'fjordabladet',
        'morenytt',
        'fjordingen',
        'driva',
        'andalsnes',
        'nyss',
        'agderposten',
        'lindesnes',
        'farsund',
        'varden',
        'lp',
        'venneslatidende',
        'gat',
        'bomlonytt',
        'sunnhordland',
    ],
};
