import type { Player } from '@schibsted-svp/web-player';
import type { Asset } from '@schibsted-svp/svp-api-types';
import type { AssetCuePoint } from '@schibsted-svp/svp-api-types/types/asset';

import type { ResolvedExperiment } from '../../../plugins/skin-vgtv2/hooks/ExperimentsProvider';

const getCuePointTimeline = (experiments: ResolvedExperiment[]) => {
  const variant = experiments.find((e) => e.id === 'in-feed-bumper')?.variant;

  let timeline = 0;

  if (typeof variant === 'number') {
    timeline = variant;
  } else if (typeof variant === 'string') {
    timeline = parseInt(variant, 10);
  }

  return timeline > 0 ? timeline : undefined;
};

export const getAdCuePoint = (
  asset: Asset,
  experiments: ResolvedExperiment[]
) => {
  const timeline = getCuePointTimeline(experiments);

  if (timeline && timeline < asset.duration / 1000) {
    return {
      timeline,
    };
  }

  return undefined;
};

export const addCuePoint = (player: Player, cuePoint: AssetCuePoint) => {
  try {
    // @ts-expect-error temporary hack to run the experiment
    player.model.stream.attributes.cuePoints.push(cuePoint);

    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
};
