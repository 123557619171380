const PLAYER_CONTAINER_CLASS_NAME = 'svp-flag-video-preview';
const PREVIEW_CONTAINER_CLASS_NAME = 'jw-preview';
const PREVIEW_ELEMENT_CLASS_NAME = 'svp-video-preview';

/**
 * @param {HTMLElement} container
 * @return {{previewContainer: HTMLDivElement, previewElement?: HTMLVideoElement}}
 */
function queryVideoPreviewElements(container) {
    const previewContainer = container.querySelector(
        `.${PREVIEW_CONTAINER_CLASS_NAME}`,
    );
    const previewElement =
        previewContainer.querySelector(`.${PREVIEW_ELEMENT_CLASS_NAME}`) ||
        undefined;
    return { previewContainer, previewElement };
}

/**
 * @this Player
 */
function removeVideoPreviewElement() {
    // in case when asset is not published and player is removed
    if (!this.model || !this.model.player) {
        return;
    }
    const container = this.getContainer();
    container.classList.remove(PLAYER_CONTAINER_CLASS_NAME);

    const { previewContainer, previewElement } =
        queryVideoPreviewElements(container);
    if (!previewElement) {
        return;
    }

    previewContainer.removeChild(previewElement);
}

/**
 * @param {HTMLDivElement} previewContainer
 * @return {HTMLVideoElement}
 */
function createPreviewElement(previewContainer) {
    const previewElement = document.createElement('video');
    previewElement.loop = true;
    previewElement.autoplay = true;
    previewElement.muted = true;
    previewElement.classList.add(PREVIEW_ELEMENT_CLASS_NAME);
    ['autoplay', 'disableremoteplayback', 'muted', 'playsinline'].forEach(
        (attr) => {
            previewElement.setAttribute(attr, '');
        },
    );
    previewContainer.appendChild(previewElement);
    return previewElement;
}

/**
 * @this Player
 */
async function addVideoPreviewElement() {
    const stream = this.model.getStream();
    const previewUrl = this.config.getVideoPreviewUrl(stream);
    const autoplay = this.config.get('autoplay');
    if (!previewUrl || autoplay) {
        return removeVideoPreviewElement.call(this);
    }

    const container = this.getContainer();
    container.classList.add(PLAYER_CONTAINER_CLASS_NAME);

    const {
        previewContainer,
        previewElement = createPreviewElement(previewContainer),
    } = queryVideoPreviewElements(container);

    previewElement.src = previewUrl;
    previewElement.load();
    return previewElement.play();
}

export default {
    addVideoPreviewElement,
    removeVideoPreviewElement,
};
