/**
 * @type {number}
 */
/**
 * @type {*}
 */
var config;

/**
 * @param {number} pixelRatio
 * @returns {number}
 */
function getImageQuality(pixelRatio) {
    return pixelRatio >= 2 ? 50 : 80;
}

/**
 * @param {number} size
 * @param {number} pixelRatio
 * @returns [number, number]
 */
function normalizeTransformSize(size, pixelRatio) {
    // return default value
    return [size, getImageQuality(pixelRatio)];
}

/**
 * @param {number[]} transformSizes
 * @param {number} maxDimension
 * @returns {number|undefined}
 */
function getClosestSize(transformSizes, maxDimension) {
    return transformSizes.reduce(function (prev, curr) {
        return Math.abs(curr - maxDimension) < Math.abs(prev - maxDimension)
            ? curr
            : prev;
    });
}

/**
 * @returns {number}
 */
function getPixelRatio() {
    // we do not support px lower than 1 and bigger than 2
    return Math.max(1, Math.min(2, config.getPixelRatio()));
}

/**
 * Returns transformation for given image key and width
 * Adjusts the best
 */
const getImageTransform = (function () {
    /**
     * @type {Record<string, string>}
     */
    var cache = {};

    /**
     * @param {string} key
     * @param {number} width
     * @param {number} height
     * @returns {string}
     */
    function imageTransform(key, width, height) {
        const pixelRatio = getPixelRatio();
        const cacheKey = key + width + height + pixelRatio;

        // first check if there is cached object
        if (cache[cacheKey]) {
            return cache[cacheKey];
        }

        const id = key.split('.');
        let transformSizes = config.sizes;

        let i = 0;
        while (i < id.length) {
            transformSizes = transformSizes[id[i]];

            if (!transformSizes) {
                throw new Error(`Image transformation missing for key ${key}`);
            }

            i++;
        }

        const transformType = height > width ? 'autoWidth' : 'autoHeight';
        transformSizes = transformSizes[transformType];

        // transformation is adjusted to not 'auto' side
        let maxDimension = transformType === 'autoHeight' ? width : height;
        maxDimension *= pixelRatio;

        const dimensions = normalizeTransformSize(
            getClosestSize(transformSizes, maxDimension),
            pixelRatio,
        );

        // auto width transform starts with 'x'
        // http://svp.vg.no/svp/api/v1/docs/
        let transform = transformType === 'autoWidth' ? 'x' : '';
        transform = `${transform}${dimensions[0]}q${dimensions[1]}`;

        cache[cacheKey] = transform;

        return transform;
    }

    return imageTransform;
})();

const image = {
    /**
     * @param {*} options
     */
    initialize: function (options) {
        config = options;
    },
    /**
     * @param {string} imageId
     * @param {string} key
     * @param {number} width
     * @param {number} height
     * @returns {string|null}
     */
    getImageSrc: function (imageId, key, width, height) {
        if (imageId) {
            const extension =
                imageId.indexOf('.jpg') === -1 && imageId.indexOf('.png') === -1
                    ? '.jpg'
                    : '';
            return `${imageId + extension}?t[]=${getImageTransform(
                key,
                width,
                height,
            )}`;
        }
        return null;
    },
};

export default image;
