import utils from 'utils/utils';
import Model from 'utils/model';
import device from 'utils/device';
import ServerTime from 'utils/server-time';
import config from 'player/config';

/**
 * @param {SvpAsset} options
 * @constructor
 */
const Stream = function (options) {
    this.attributes = {
        id: 0,
    };

    this.initialize(options);
};

Stream.prototype = {
    /**
     * @param {SvpAsset} data
     */
    initialize(data) {
        utils.extend(this.attributes, this.parse(data));
    },

    /**
     * Parse data from asset
     * Be aware, data went through Asset.parse method
     *
     * @param {SvpAsset} data
     * @returns {ParsedStream}
     */
    parse(data) {
        const result = {
            id: data.id,
            vendor: data.vendor,
            title: data.title,

            streams: {
                hls: data.streamUrls.hls,
                mp4: data.streamUrls.mp4 ? [data.streamUrls.mp4] : [],
            },
            status: data.status,
            type: data.streamType,
            mediaType: data.assetType,
            streamConfiguration: data.streamConfiguration,

            images: {
                main: data.images.main,
                snapshots: data.images.snapshots || null,
            },

            captions: data.captions || [],

            flightTimes: {
                start: null,
                end: null,
            },

            playback: {
                begin: null,
                end: null,
            },

            externalId: data.externalId || null,
            category: data.category,
        };

        if (data.duration) {
            result.duration = data.duration / 1000;
        }

        if (data.additional && data.additional.metadata) {
            result.disableNextVideo = data.additional.metadata.disableNextVideo;
            result.chunkDuration = Number(
                data.additional.metadata.chunkDuration,
            );
            result.isPodcast = data.additional.metadata.isPodcast === 'true';
            result.hasSubtitlesInHls =
                data.additional.metadata.hasSubtitlesInHls;
            result.tts = Boolean(data.additional.metadata.tts_articleId);

            result.videoPreviews = Object.entries(
                data.additional.metadata,
            ).reduce((videoPreviews, [metadataKey, metadataValue]) => {
                if (!metadataKey.includes('preview')) {
                    return videoPreviews;
                }
                return {
                    ...videoPreviews,
                    [metadataKey]: metadataValue,
                };
            }, {});
        }

        // override start and end if set in api
        if (data.flightTimes) {
            result.flightTimes = {
                start: data.flightTimes.start ? data.flightTimes.start : null,
                end: data.flightTimes.end ? data.flightTimes.end : null,
            };
        }

        if (data.playback) {
            result.playback = {
                begin: data.playback.begin,
                end: data.playback.end,
            };
        }

        // additional stream settings
        if (data.settings) {
            result.showAds = data.settings.showAds;
        }

        // cue points, empty array if nothing set
        result.cuePoints = data.cuePoints;

        // playlist, empty array if nothing set
        result.playlist = data.playlist || [];

        // add midroll in 10s TODO remove after tests
        // result.cuePoints = [31];
        return result;
    },

    /**
     * @returns {number}
     */
    getId() {
        return this.get('id');
    },

    /**
     * @returns {string}
     */
    getVendor() {
        return this.get('vendor');
    },

    /**
     * @returns {Category}
     */
    getCategory() {
        return this.get('category');
    },

    /**
     * @param {string} property
     * @return {boolean}
     */
    hasProperty(property) {
        return (
            this.get('streamConfiguration').properties.indexOf(property) > -1
        );
    },

    /**
     * @returns {boolean}
     */
    hasSubtitlesInHls() {
        return this.get('hasSubtitlesInHls') === 'true';
    },

    /**
     * Get duration stored in API
     *
     * @returns {number}
     */
    getDuration() {
        return this.get('duration');
    },

    /**
     * @returns {number[]}
     */
    getCuePoints() {
        return this.get('cuePoints');
    },

    /**
     * Check if stream has Akamai Secure Token
     * @returns {boolean}
     */
    isSecure() {
        return this.hasProperty('tokenSecured');
    },

    /**
     * Check if stream is behind paywall
     * @returns {boolean}
     */
    hasAccess() {
        return !!this.get('access');
    },

    /**
     * Check wheter give stream contains mock data to allow embedding player without asset id
     * @returns {boolean}
     */
    isMock() {
        return this.get('type') === 'mock';
    },

    /**
     * Check if stream is available only in certain areas
     * @returns {boolean}
     */
    isGeoblocked() {
        return this.hasProperty('geoblocked');
    },

    /**
     * @returns {boolean}
     */
    isLive() {
        return this.get('type') === 'live';
    },

    /**
     * @returns {boolean}
     */
    wasLive() {
        return this.get('type') === 'wasLive';
    },

    /**
     * @returns {boolean}
     */
    isActive() {
        return this.get('status') === 'active' && !this.isPast();
    },

    /**
     * @returns {boolean}
     */
    isFuture() {
        return this.get('flightTimes') && this.get('flightTimes').start
            ? this.get('flightTimes').start > ServerTime.getTime()
            : false;
    },

    /**
     * @returns {boolean}
     */
    isPast() {
        return this.get('flightTimes') && this.get('flightTimes').end
            ? this.get('flightTimes').end < ServerTime.getTime()
            : false;
    },

    /**
     * @returns {boolean}
     */
    hasPlaylist() {
        return this.get('playlist').length > 0;
    },

    /**
     * @returns {boolean}
     */
    isDisabledNextVideo() {
        return !!this.get('disableNextVideo');
    },
    /**
     * Streams might not be available while asset is created
     *
     * @returns {boolean}
     */
    hasPlayableSource() {
        if (this.getTimeToStart() > 0 || !this.isActive()) {
            return false;
        }

        return true;
    },

    getUrl(streamType) {
        const isOnHttp = window.location.protocol
            .toLowerCase()
            .startsWith('http:');
        const type = streamType.toLowerCase();

        let streamUrl = this.get('streams')[type];

        if (utils.device.isIE() && isOnHttp) {
            streamUrl = streamUrl.replace('https', 'http');
        }

        return streamUrl;
    },

    /**
     * @returns {string}
     */
    getPoster() {
        return this.get('images').main;
    },

    getAllPreviewsUrls() {
        return this.get('videoPreviews') || {};
    },

    /**
     * Returns preview video URL
     * @param {string} metadataKey
     * @returns {string|undefined}
     */
    getVideoPreviewUrl(metadataKey) {
        const videoPreviews = this.get('videoPreviews');
        return videoPreviews ? videoPreviews[metadataKey] : undefined;
    },

    /**
     * Get chunk duration of live video
     *
     * @returns {number}
     */
    getChunkDuration() {
        return this.get('chunkDuration');
    },

    /**
     * Get snapshots url
     * @returns {string|null} - url string or null if snapshots not found
     */
    getSnapshots() {
        return `${
            config.api.thumbnailsUrl + this.getVendor()
        }/assets/${this.getId()}`;
    },

    /**
     * Not every asset has snapshot (lives, wasLives)
     *
     * @returns {boolean}
     */
    hasSnapshots() {
        return this.get('images').snapshots !== null;
    },

    /**
     * Get captions associated with stream
     *
     * @returns {boolean}
     */
    getCaptions() {
        return this.get('captions') || [];
    },

    /**
     * Check if mp4 streams are available
     *
     * @returns {boolean}
     */
    hasMp4Streams() {
        return this.get('streams').mp4.length > 0;
    },

    /**
     * Check if asset is published
     * Method relay on server time to avoid timezone differences
     *
     * @returns {number} seconds to live
     */
    getTimeToStart() {
        return (this.get('flightTimes').start - ServerTime.getTime()) / 1000;
    },

    /**
     * Asset can be virually sliced into small chunks
     * These are only indicators and do not slice stream
     *
     * @param position
     *
     * @returns {number|null}
     */
    getPlaybackTime(position) {
        const playback = this.get('playback');

        if (position === 'begin') {
            if (this.get('playAhead') > 0) {
                return this.get('playAhead');
            }

            return playback.begin > 0 ? playback.begin : null;
        }

        if (position === 'end' && playback.end > 0) {
            return playback.end;
        }

        return null;
    },

    /**
     * External id
     * Used for JW Player statistics
     *
     * @returns {string|undefined}
     */
    getExternalId() {
        return this.get('externalId');
    },

    /**
     * Check wethever stream has ads disabled
     *
     * @returns {boolean}
     */
    hasAdsDisabled() {
        return this.get('showAds') === false;
    },

    /**
     * @returns {boolean}
     */
    isAudio() {
        return this.get('mediaType') === 'audio';
    },

    /**
     * @returns {boolean}
     */
    hasTextSpeech() {
        return this.get('tts');
    },

    /**
     * Check if stream is podcast
     * @returns {boolean}
     */
    isPodcast() {
        return this.get('isPodcast') === true;
    },

    /**
     * Get VMAP API URL for ads schedule
     * @param {Record<string, string>} [params]
     * @returns {string}
     */
    getVmapApiUrl(params = {}) {
        let supplyType = 'web_desktop';

        if (device.isMobile()) {
            supplyType = 'web_phone';
        } else if (device.isTablet()) {
            supplyType = 'web_tablet';
        }

        const queryParams = new URLSearchParams({
            gdpr: '__gdpr__',
            gdpr_consent: '__gdpr_consent__',
            ...params,
            supplyType,
        }).toString();

        return `${
            config.api.vmapUrl
        }/${this.getVendor()}/${this.getId()}?${queryParams}`;
    },
};

utils.extend(Stream.prototype, Model);

export default Stream;
