import type { Asset } from '@schibsted-svp/svp-api-types';
import { getRelatedAssets } from 'player/services/api/related';
import AssetModel from '../asset';

const mapResponse = (vendor: string, assets: Asset[]): AssetModel[] =>
    assets.map(
        (asset) =>
            new AssetModel({
                vendor,
                ...asset,
            }),
    );

export async function fetchFromRelated({
    vendor,
    assetId,
}: {
    vendor: string;
    assetId: number;
}): Promise<AssetModel[]> {
    const assets = await getRelatedAssets({ vendor, assetId });
    return mapResponse(vendor, assets);
}
