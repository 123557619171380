/* jshint ignore:start */
/* eslint-disable */

var isLoaded = false;
var exportz = {};

export default function (conf) {
    if (!isLoaded) {
        isLoaded = true;

        (function(k) {
            k = k || {}; (function(l) {
                function b() { this.u = 'setup'; }l.g = b; b.prototype = {
                    c: function(a) { a = a && typeof a === 'number' && a > 0 ? Math.round(a) : 10; for (var g = '', e = 0; e < a; e++)g += String.fromCharCode((Math.random() < 0.5 ? 65 : 97) + Math.floor(26 * Math.random())); return g; },
                    A: function(a) {
                        function g(d) {
                            var a = '';
                            var c;
                            var g;
                            for (c = 7; c >= 0; c--)g = d >>> 4 * c & 15, a += g.toString(16);return a;
                        }function e(d, a) { return d << a | d >>> 32 - a; }
                        var c;
                        var h;
                        var b = Array(80);
                        var l = 1732584193;
                        var q = 4023233417;
                        var p = 2562383102;
                        var k = 271733878;
                        var t = 3285377520;
                        var f;
                        var m;
                        var n;
                        var r;
                        var d;
                        a = a && typeof a === 'string'
                                ? a : '';f = a.length;var u = [];for (c = 0; c < f - 3; c += 4)h = a.charCodeAt(c) << 24 | a.charCodeAt(c + 1) << 16 | a.charCodeAt(c + 2) << 8 | a.charCodeAt(c + 3), u.push(h);switch (f % 4) { case 0: c = 2147483648; break; case 1: c = a.charCodeAt(f - 1) << 24 | 8388608; break; case 2: c = a.charCodeAt(f - 2) << 24 | a.charCodeAt(f - 1) << 16 | 32768; break; case 3: c = a.charCodeAt(f - 3) << 24 | a.charCodeAt(f - 2) << 16 | a.charCodeAt(f - 1) << 8 | 128; }for (u.push(c); u.length % 16 != 14;)u.push(0);u.push(f >>> 29);u.push(f << 3 & 4294967295);for (a = 0; a < u.length; a += 16) {
                                for (c = 0; c < 16; c++)b[c] = u[a + c]; for (c = 16; c <= 79; c++)b[c] = e(b[c - 3] ^ b[c - 8] ^ b[c - 14] ^ b[c - 16], 1); h = l; f = q; m = p; n = k; r = t; for (c = 0; c <= 19; c++)d = e(h, 5) + (f & m | ~f & n) + r + b[c] + 1518500249 & 4294967295, r = n, n = m, m = e(f, 30), f = h, h = d; for (c = 20; c <= 39; c++)d = e(h, 5) + (f ^ m ^ n) + r + b[c] + 1859775393 & 4294967295, r = n, n = m, m = e(f, 30), f = h, h = d; for (c = 40; c <= 59; c++)d = e(h, 5) + (f & m | f & n | m & n) + r + b[c] + 2400959708 & 4294967295, r = n, n = m, m = e(f, 30), f = h, h = d; for (c = 60; c <= 79; c++)d = e(h, 5) + (f ^ m ^ n) + r + b[c] + 3395469782 & 4294967295, r = n, n = m, m = e(f, 30), f = h, h = d; l = l + h & 4294967295; q = q + f & 4294967295; p = p + m & 4294967295; k = k + n
                    & 4294967295; t = t + r & 4294967295;
                            }return (g(l) + g(q) + g(p) + g(k) + g(t)).toLowerCase();
                    },
                    j: function(a) {
                        for (var g = '', e, c, b, l, k, q, p = 0; p < a.length;) {
                            e = a.charCodeAt(p++), c = a.charCodeAt(p++), b = a.charCodeAt(p++), l = e >> 2, e = (e & 3) << 4 | c >> 4, k = (c & 15) << 2 | b >> 6, q = b & 63, isNaN(c) ? k = q = 64 : isNaN(b) && (q = 64), g = g + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='.charAt(l) + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='.charAt(e) + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='.charAt(k)
                + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='.charAt(q);
                        } return g;
                    },
                    fireEvent: function(a, g, b) {
                        var c = this.u;
                        var h = document.createEvent('CustomEvent');
                        b.state = c;h.initCustomEvent(g, !1, !1, b);a.dispatchEvent(h);
                    },
                    l: function(a, g, b) { g || (g = 'hdEvent'); b || (b = window); this.fireEvent(b, g, a); },
                    track: function(a, b, e) {
                        if (e) {
                            if (!homad || !homad.M)window.setTimeout(function() { this.track(a, b, e); }.bind(this), 500); else if (homad.isEnabled) {
                                var c = `${homad.T()}?homad_track_key=${encodeURIComponent(a)}&homad_track_value=${
                                    encodeURIComponent(b)}`; homad.o(c, !0); c = homad.o(c, !0); c.data.homad_url_prefix_alias = ''; c.data.homad_track_key = encodeURIComponent(a); c.data.homad_track_value = encodeURIComponent(b); homad.P(c, function() {});
                            }
                        }
                    }
                };
            }(k)); var v = k.g || v; new v(); var w = new v(); (function(l) {
                function b(a) { this.data = a || {}; }l.f = b; b.S = function(a, b) {
            var e = {};
            var c;
            for (c in b)e[c] = b[c];for (c in a)e[c] = a[c];return e;
        }; b.w = { code: '1000', type: 'penalty', message: '' }; b.V = { code: '1001', type: 'mediaerror', message: '' }; b.C = { code: '1002', type: 'vpaid', message: '' };
                b.O = { code: '1003', type: 'emptyVast', message: '' }; b.F = { code: '1004', type: 'malformattedXML' }; b.J = { code: '1005', type: 'adStart' }; b.G = { code: '1006', type: 'adFirstQuartile' }; b.I = { code: '1007', type: 'adMidPoint' }; b.K = { code: '1008', type: 'adThirdQuartile' }; b.i = { code: '1009', type: 'adComplete' }; b.H = { code: '1010', type: 'adImpressionsCalled' }; b.B = { code: '1011', type: 'vastLoadingFailed' }; b.U = { code: '1012', type: 'noCreative' }; b.R = { code: '1013', type: 'emptyVastFromHomadServerEvent', message: '' }; b.D = {
                    code: '1014',
                    type: 'wrapperLimitReached',
                    message: ''
                }; b.L = { code: '1015', type: 'clickthrough', message: '' }; b.N = { code: '1016', type: 'continueContent', message: '' }; b.X = { code: '1017', type: 'variableDeletedEvent', message: '' }; b.W = { code: '1018', type: 'noVariableSettingEvent', message: '' }; b.prototype = {};
            }(k)); var z = k.f || z; (function(l) {
                function b() {}l.h = b; b.prototype = {
                    s: function(a) {
                        var b = 0;
                        var e;
                        var c;
                        var h;
                        if (a.length == 0) return b;e = 0;for (h = a.length; e < h; e++)c = a.charCodeAt(e), b = (b << 5) - b + c, b |= 0;return Math.abs(b);
                    },
                    m: function() {
                        return Math.round(Date.now() / 1E3 / 3600 / 24) + navigator.appCodeName
                + navigator.appName + navigator.appVersion + navigator.userAgent + navigator.platform + (navigator.language || navigator.i || 'x');
                    },
                    a: function(a) { a = parseInt(a); if (a >= 65 && a <= 90 || a >= 97 && a <= 122) return String.fromCharCode(a); if (a <= 0) return ''; if (a < 65) return this.a(a + 65); if (a > 90) return this.a(Math.round(a / 2)); },
                    reverse: function(a) { for (var b = '', e = a.length - 1, b = ''; e >= 0; b += a[e--]);return b; },
                    b: function() {
                        for (var a = this.s(`${this.m()}`), a = (`${a}${this.reverse(`${a}`)}`).match(/.{1,2}/g), b = '', e = 0; e < a.length; e++)b += this.a(a[e]); b.length == 0
            && (b = 'zork'); return b;
                    },
                    v: function() { return `${this.b()}2`; }
                };
            }(k)); var y = k.h || y; (function() {
            function l() {
                if (p)conf.startPlayer && eval(conf.startPlayer), document.fp_error = !0, document.hd_ready = !0; else {
                    var d = document.createElement('script');d.type = 'text/javascript';d.src = `https://hgc-cf-cache-1.svonm.com/hd-main.js?cache=${w.c(10)}`;d.onerror = e;document.head.firstChild ? document.head.insertBefore(d, document.head.firstChild) : document.head.appendChild(d);
                    var d = new y();
                    var a = document.createEvent('CustomEvent');
                    conf.version = '20170704_0930_802';a.initCustomEvent(d.b(), !1, !1, conf);window.setInterval(function() { window.dispatchEvent(a); }, 500);b();
                }
            }function b() { var d = new y(); r = window.setTimeout(function() { a(); }, 15E3); window.addEventListener(d.v(), function() { window.clearTimeout(r); }); }function a() {
                        for (var d = document.getElementsByTagName('video'), a = 0; a < d.length; a++) { try { d[a] && d[a].pause && typeof d[a].pause === 'function' && d[a].pause(); } catch (b) {}d[a].parentNode.removeChild(d[a]); }d = document.getElementsByTagName('object'); for (a = 0; a < d.length; a++)d[a].parentNode.removeChild(d[a]);
                        d = document.getElementsByClassName('video-wrapper'); for (a = 0; a < d.length; a++)d[a] && d[a].parentNode && d[a].parentNode.removeChild(d[a]); d = document.getElementsByClassName('videoplayer'); for (a = 0; a < d.length; a++)d[a] && d[a].parentNode && d[a].parentNode.removeChild(d[a]); if (window.self != window.top) for (d = window.document; d.hasChildNodes();)d.removeChild(d.lastChild);
                    }function g() {
                        if (t) {
                            var d = `fail-init-100.gif${v()}`; k(t, function() {}, function() {}, {
                                homad_url_prefix_alias: f,
                                homad_url_suffix_base64: w.j(d),
                                homad_url_suffix: d,
                                homad_hash_guessed: 'true'
                            });
                        }w.l(z.w); x && typeof x === 'function' ? x() : (a(), window.setInterval(a, 500), window.setTimeout(a, 1500));
                    }function e() { var a = document.createElement('script'); a.type = 'text/javascript'; a.src = `https://s3.amazonaws.com/homad-global-configs.schneevonmorgen.com/hd-main.js?cache=${w.c(10)}`; a.onerror = g; document.head.firstChild ? document.head.insertBefore(a, document.head.firstChild) : document.head.appendChild(a); }function c(a) { p || (p = !q(a.enabled)); l(); }function h(a) {
                        a && a.server && a.server[0]
                && (t = (`${a.server[1]}`).replace('[hash]', w.A(`${(new Date()).getTime()}`)), f = 'homadvastimpressions'); p ? l() : (p = !q(a.enabled)) ? l() : k(a.config, c, l);
                    }function k(a, b, c, e) {
                        var f = new XMLHttpRequest(); f.onreadystatechange = function() { if (b && f.readyState == 4 && f.status == 200) { var a = {}; try { a = JSON.parse(f.responseText); } catch (d) { a.enabled = 'false'; }b(a); }c && f.readyState == 4 && f.status != 200 && c(f); }; try {
                            if (e) {
                                f.open('POST', a, !0); a = e; e = ''; for (var g in a)e += `&${g}=${encodeURIComponent(a[g])}`; e = e.substr(1); f.setRequestHeader('Content-type',
                                    'application/x-www-form-urlencoded'); f.send(e);
                            } else f.open('GET', a, !0), f.send();
                        } catch (h) { c && c(f); }
                    }function v() { var a = '?c=unknown'; window && window.location && window.location.hostname && (a = `?c=${window.location.hostname}`); return a; }function q(a) { return a == 'True' || a == 'true' || a == 1 || a == '1' || a == 1; }
            var p = !1;
            var x = conf.onFailure;
            var t = '';
            var f = '';
            var m = !1;
            var n = 0;
            var r = !1;
            k(conf.globalConfig, function(a) { p ? l() : p = !q(a.enabled); });typeof conf.clientConfig === 'string' ? k(conf.clientConfig, h, function() {
                        window.setTimeout(function() {
                            k(conf.clientConfig,
                                h, function() { window.setTimeout(g, 1500); });
                        }, 500);
                    }) : m = window.setInterval(function() { var a = ''; try { a = conf.clientConfig(), n++, a ? (h(a), window.clearInterval(m)) : n >= 50 && (window.clearInterval(m), g()); } catch (b) {} }, 100);
        }());
        }).call(exportz, exportz);
    }
}
