import utils from 'utils/utils';
import ServerTime from 'utils/server-time';
import config from 'player/config';
import locale from 'player/model/locale';

import countdownTemplate from './countdown.html';
import PluginModel from './model';

const PLAYLIST_REGENERATION_THRESHOLD = 5; // in seconds

/**
 * Format number by adding leading zeros eg 7 -> 07
 *
 * @param numValue
 * @param numLen
 * @returns {string}
 */
const leadingZeros = (numValue, numLen) => {
    const length = numLen || 2;
    let num = String(numValue);

    while (num.length < length) {
        num = `0${num}`;
    }

    return num;
};

/**
 * Renders countdown clock for streams which are not published yet
 *
 * @param options
 * @constructor
 */
class CountdownPlugin extends PluginModel {
    constructor(options) {
        super(options);

        /**
         * Start date for stream (flightTime.start)
         * Javascript Date object
         */
        this.streamStart = options.streamStart;

        this.liveDOM = null;
        this.countdownDOM = null;

        this.isPlaylistRegenerated = false;
    }

    // eslint-disable-next-line
    getName() {
        return 'countdown';
    }

    setup() {
        this.listenTo(this.player, 'playlistItem', this.render, this);
    }

    /**
     * Cleanup old node
     */
    destroy() {
        this.stopListening(this.player);

        if (this.el) {
            this.player.getContainer().removeChild(this.el);
            this.el = null;
        }
    }

    /**
     * Render countdown
     */
    render() {
        try {
            this.player.getContainer();
        } catch (error) {
            return;
        }

        this.el = utils.createNode(utils.template(countdownTemplate));
        this.player.getContainer().appendChild(this.el);

        utils.addClass(this.player.getContainer(), 'svp-countdown-loaded');

        // cached live label dom element
        // eslint-disable-next-line prefer-destructuring
        this.liveDOM = this.el.getElementsByClassName('svp-live-label')[0];
        // eslint-disable-next-line prefer-destructuring
        this.countdownDOM = this.el.getElementsByClassName(
            'svp-countdown-live-timer',
        )[0];

        // countdown is ready
        this.trigger('ready');
        // start event runs on first change of countdown
        this.once('change', () => this.trigger('start'));

        // interval has to be initialized on
        this.intervalId = setInterval(() => this.refresh(), 1000);
        this.refresh();
    }

    /**
     * Refresh state and repaint
     */
    async refresh() {
        if (!this.diff) {
            this.diff = this.getDiff();
        }

        if (this.diff <= 0) {
            this.stop();
        }

        const string = [];

        if (this.isCountdownActive()) {
            const minutes = Math.floor(this.diff / 60) % 60;
            const seconds = this.diff % 60;

            string.push(`${leadingZeros(minutes)}:`);
            string.push(
                `${leadingZeros(seconds)}<span class="svp-timer-unit">s</span>`,
            );

            if (this.currentState !== 'timer') {
                this.currentState = 'timer';
                this.liveDOM.innerHTML = locale.translate(
                    'Video will start in',
                );

                utils.addClass(
                    this.player.getContainer(),
                    'svp-countdown-active',
                );
            }
        } else if (this.currentState !== 'date') {
            this.currentState = 'date';

            string.push(
                `${this.streamStart.getDate()}. ${locale
                    .getMonthName(this.streamStart)
                    .toLowerCase()} `,
            );
            string.push(`${leadingZeros(this.streamStart.getHours())}:`);
            string.push(leadingZeros(this.streamStart.getMinutes()));

            this.liveDOM.innerHTML = locale.translate('Video will start at');
        }

        if (string.length > 0) {
            this.countdownDOM.innerHTML = string.join('');
            this.trigger('change', this.streamStart);
        }

        // sync server time every 60s
        if (this.diff % 60 === 0) {
            await ServerTime.fetch(config.time);
            const newDiff = this.getDiff();
            if (Math.abs(newDiff - newDiff) > 1) {
                this.diff = newDiff;
            }
        }

        if (this.shouldRegeneratePlaylist()) {
            setTimeout(this.regeneratePlaylist.bind(this), 0);
        }

        this.diff -= 1;
    }

    shouldRegeneratePlaylist() {
        return (
            this.player.model.getStream().isSecure() &&
            this.diff <= PLAYLIST_REGENERATION_THRESHOLD &&
            !this.isPlaylistRegenerated
        );
    }

    /**
     * Regenerate playlist to update tokens for secured streams
     * @return {Promise<void>}
     */
    async regeneratePlaylist() {
        this.isPlaylistRegenerated = true;
        const { model } = this.player;
        const { playlist } = await model.getConfig();
        model.player.load(playlist);
    }

    /**
     * End of countdown
     */
    stop() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        this.intervalId = null;
        this.el.parentNode.removeChild(this.el);
        this.el = null;

        utils.removeClass(this.player.getContainer(), [
            'svp-countdown-active',
            'svp-countdown-loaded',
        ]);

        this.trigger('end');
    }

    /**
     * Get difference between stream start and current server time
     * @returns {number}
     */
    getDiff() {
        return Math.ceil(
            (this.streamStart.getTime() - ServerTime.getTime()) / 1000,
        );
    }

    /**
     * Check whatever countdown or date should be displayed
     * Countdown is active when less than 1 hour reminded to start
     * @returns {boolean}
     */
    isCountdownActive() {
        // less than 1 hour
        return this.diff < 60 * 60 - 1;
    }
}

export default CountdownPlugin;
