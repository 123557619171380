import logger from 'utils/logger';
import utils from 'utils/utils';
import config from 'player/config';
import errorIcon from 'icons/error.svg';
import videoPreviewHelper from 'utils/video-preview';
import locale from '../model/locale';
import statusCode, {
    NOT_FOUND,
    GENERAL_ERROR,
    NOT_ACTIVE,
} from '../playback/config/status-codes';
import { TTS_SKIN_CLASS_NAME } from '../playback/skin/tts-skin';

/**
 * Clear error message
 */
export function clearError(node) {
    const previousErrorNode = node.getElementsByClassName(
        'svp-player-error-wrapper',
    );

    // clear old node
    if (previousErrorNode.length > 0) {
        utils.removeClass(node, [
            'svp-player-error',
            'svp-player-error-hasImage',
        ]);

        if (previousErrorNode[0].parentNode) {
            previousErrorNode[0].parentNode.removeChild(previousErrorNode[0]);
        }
    }
}

/**
 * Display error message in given node
 */
function displayError(node, message, code = null, referenceId = null) {
    let template = `<div class="svp-player-message"><span class="svp-player-error-icon">${errorIcon}</span>${message}`;

    if (code) {
        template += `<div class="svp-player-error-code">${locale.translate(
            'Error code',
        )}: ${code}${referenceId ? `, #${referenceId}` : ''}</div>`;
    }

    template += '</div>';

    const posterSrc =
        this.model &&
        this.model.getStream() &&
        this.model.getPoster(this.model.getStream(), node);

    clearError(node);
    utils.addClass(node, 'svp-player-error');

    // add image
    if (posterSrc) {
        utils.addClass(node, 'svp-player-error-hasImage');
        template += `<div class="svp-player-poster" style="background-image: url(${posterSrc})"></div>`;
    }

    const errorNode = utils.createNode(
        `<div class="svp-player-error-wrapper">${template}</div>`,
    );

    node.appendChild(errorNode);

    this.once(
        'complete',
        function () {
            utils.removeClass(node, [
                'svp-player-error',
                'svp-player-error-hasImage',
            ]);

            if (errorNode.parentNode) {
                errorNode.parentNode.removeChild(errorNode);
            }
        },
        this,
    );
}

/**
 * Display error message
 */
export function onError(details) {
    logger('SVP').error(details);
    const node = document.getElementById(this.config.get('node'));
    const { code, message, referenceId } = details || {};

    if (this.model.config.shouldApplyTextToSpeechSkin()) {
        node.classList.add(TTS_SKIN_CLASS_NAME);
    }

    let translatedMessage = locale.translate(message);

    // translation not found
    if (translatedMessage === message) {
        translatedMessage = locale.translate(statusCode(GENERAL_ERROR));
    }

    // details - message in english
    // message - localized, displayed message
    const errorDetails = {
        code,
        details: message,
        message: translatedMessage,
        referenceId,
    };

    if (details.type) {
        errorDetails.type = details.type;
    }

    displayError.call(this, node, translatedMessage, code, referenceId);

    if (this.model.player) {
        this.model.player.stop();
    }

    this.trigger('error', errorDetails);
}

/**
 * Asset fetch failed. Usually happens when network issue occurred
 */
export function onAssetError(error) {
    let code = GENERAL_ERROR;

    videoPreviewHelper.removeVideoPreviewElement.call(this);

    if (error) {
        if (error.status === 404) {
            code = NOT_FOUND;
        } else if (error.status === 401) {
            code = NOT_ACTIVE;
        }
    }

    const details = {
        message: statusCode(code),
        code,
    };

    this.trigger('assetError', details);

    // load stylesheet as player is not attached
    onError.call(this, {
        type: 'assetFetchError',
        ...details,
    });

    logger('SVP').error('error', error);

    // display errors in development mode
    if (config.env === 'development') {
        throw error;
    }
}
