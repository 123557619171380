import type { Asset } from '@schibsted-svp/svp-api-types';
import { assetAdditionalData, api } from '../api';

export { getMockAsset } from './asset/mock';

const getAssetEndpoint = (id: number): string =>
    `/assets/${id}?additional=${assetAdditionalData}`;

export const getById = async ({
    vendor,
    assetId,
}: {
    vendor: string;
    assetId: number;
}): Promise<Asset> =>
    api({
        vendor,
        endpoint: getAssetEndpoint(assetId),
    });
