const { userAgent } = navigator;

/**
 * @param {RegExp} regex
 * @returns {boolean}
 */
function userAgentMatch(regex) {
    return userAgent.match(regex) !== null;
}

/**
 * @param {RegExp} regex
 * @returns {boolean}
 */
function lazyUserAgentMatch(regex) {
    return function () {
        return userAgentMatch(regex);
    };
}

const isIETrident = lazyUserAgentMatch(/trident\/.+rv:\s*11/i);
const isIPad = () =>
    lazyUserAgentMatch(/iPad/i)() ||
    (userAgentMatch(/Macintosh/i, userAgent) &&
        navigator.maxTouchPoints !== undefined &&
        navigator.maxTouchPoints > 1);
const isIPhone = lazyUserAgentMatch(/iPhone/i);
// Check for spiders/bots
/**
 * @returns {boolean}
 */
function isBot() {
    return userAgentMatch(/bot|google|baidu|bing|msn|teoma|slurp|yandex|yeti/i);
}
// Check for Facebook App Version to see if it's Facebook

/**
 * @returns {boolean}
 */
function isEdge() {
    return userAgentMatch(/\sEdge\/\d+/i);
}

/**
 * @returns {boolean}
 */
function isMSIE() {
    return userAgentMatch(/msie/i);
}

/**
 * @returns {boolean|*}
 */
function isIE() {
    return isEdge() || isIETrident() || isMSIE();
}

/**
 * @returns {boolean}
 */
function isSafari() {
    return (
        userAgentMatch(/safari/i) &&
        !userAgentMatch(/(?:Chrome|CriOS|chromium|android)/i)
    );
}

/**
 * @returns {boolean}
 */
function isFirefox() {
    return userAgentMatch(/\sFirefox\/\d+/i);
}

/**
 * Matches iOS devices
 *
 * @param version
 * @returns {boolean}
 */
function isIOS(version) {
    var isAppleIOS = userAgentMatch(/iP(hone|ad|od)/i),
        result = null;

    // check if version matches
    if (isAppleIOS && version) {
        result = /OS ([._\d]+)/.exec(userAgent);

        // get version and parse it
        if (result && parseInt(result[1], 10) === version) {
            return true;
        }
        // didn't match version
        return false;
    }

    return isAppleIOS;
}

/**
 * @returns {boolean}
 */
function isAndroid() {
    return userAgentMatch(/Android/i);
}

/**
 * @returns {boolean}
 */
function isTablet() {
    let result = isIPad() || false;

    // android tables are when
    if (isAndroid() && userAgent.indexOf('Mobile') === -1) {
        result = true;
    }

    return result;
}

/**
 * @returns {boolean}
 */
function isMobile() {
    return (
        userAgent.match(
            // eslint-disable-next-line max-len
            /(iPhone|iPod|Android(?=.*Mobile).*|HTC|Fennec|IEMobile|BlackBerry|SymbianOS(?=.*AppleWebKit).*|Opera Mobi|Symbian|SonyEricsson|Nokia|SAMSUNG|LG)/i,
        ) !== null
    );
}

/**
 * @returns {boolean}
 */
function isDesktop() {
    return !isMobile() && !isTablet();
}

/**
 * @returns {boolean}
 */
function isSamsungInternet() {
    return userAgent.indexOf('SamsungBrowser') !== -1;
}

/**
 * @returns {boolean}
 */
function isHermesApp() {
    return userAgentMatch(/hermes/i);
}

/**
 * @returns {number|boolean}
 */
function getChromeVersion() {
    var raw = userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
}

/**
 * @returns {number}
 */
function getPixelRatio() {
    var devicePixelRatio = window.devicePixelRatio || 1;

    if (devicePixelRatio > 1) {
        devicePixelRatio = 2;
    }

    return devicePixelRatio;
}

/**
 * Tracking is divided by device type
 *
 * @returns {'tablet'|'phone'|'desktop'}
 */
function getDeviceType() {
    if (isTablet()) {
        return 'tablet';
    }

    if (isMobile()) {
        return 'phone';
    }

    return 'desktop';
}

/**
 * @returns {'always'|'muted'|'never'}
 */
function canAutoplay() {
    let result = 'always';

    // default is muted for never ios systems
    if (this.isIOS()) {
        result = 'muted';
    }

    // iOS 8 and 9 can not autoplay any kind of stream
    // also Android Devices are lacking this
    if (this.isIOS(9) || this.isIOS(8) || this.isAndroid()) {
        result = 'never';
    }

    // chrome android can autoplay muted
    if (
        this.isAndroid() &&
        !this.isSamsungInternet() &&
        this.getChromeVersion() >= 53
    ) {
        result = 'muted';
    }

    return result;
}

export default {
    isIOS,
    isIPhone,
    isIPad,
    isSafari,
    isAndroid,
    isEdge,
    isIE,
    isFirefox,
    isDesktop,
    isSamsungInternet,
    isTablet,
    isMobile,
    isHermesApp,
    isBot,
    getChromeVersion,
    getPixelRatio,
    getDeviceType,
    canAutoplay,
};
