import type { Player } from '@schibsted-svp/web-player';

export class AutoplayManager {
  playbackSource?: string;

  player?: Player;

  constructor(player?: Player) {
    this.handlePlayNext = this.handlePlayNext.bind(this);
    if (player) {
      this.setPlayer(player);
    }
  }

  setPlayer(player: Player) {
    if (!this.player) {
      this.player = player;
      player.on('playNext', this.handlePlayNext);
    }
  }

  private handlePlayNext(
    _: unknown,
    options: { pulse?: { playbackSource?: string } }
  ) {
    this.playbackSource = options.pulse?.playbackSource;
  }

  getPrerollFormat() {
    return this.playbackSource?.includes('autoplay') ? 'bumper' : 'preroll';
  }
}
