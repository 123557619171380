import type { Provider } from '@vgtv/api-client';

import type { AdnConfig } from '../types';

const config: AdnConfig<Provider.AB> = {
  live_studio: {
    member: 9943,
    invCodeTemplate: 'se-aftonbladet-${device}-studio_${format}',
    afpTemplate: 'se-aftonbladet-tv-${device}-afp_${format}',
    formats: {
      preroll: true,
      bumper: true,
    },
    keywords: {
      pageType: 'front',
      publisherName: 'aftonbladet',
      publisherGroup: 'schibsted',
    },
    mainSection: 'aftonbladet-studio',
  },
  abtv: {
    member: 9943,
    invCodeTemplate: 'se-aftonbladet-${device}-${section}-${format}',
    afpTemplate: 'se-aftonbladet-tv-${device}-afp_${format}',
    formats: {
      preroll: true,
      bumper: true,
      pause: true,
      midroll: true,
    },
    keywords: {
      pageType: 'article',
      publisherName: 'aftonbladet',
      publisherGroup: 'schibsted',
    },
    mainSection: 'webbtv',
  },
};

export { config };
