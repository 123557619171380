import config from 'player/config';

class CategoryTree {
    /**
     * @param {string} vendor
     */
    constructor(vendor) {
        this.vendor = vendor;
    }

    /**
     * @returns {Promise<Map<number, SvpCategory>>}
     */
    fetch() {
        if (!this.request) {
            this.request = fetch(config.api.getUrl(this.vendor, '/categories'))
                .then((response) => response.json())
                .then((response) => {
                    // eslint-disable-next-line
                    if (response && response._embedded.categories) {
                        // eslint-disable-next-line
                        return response._embedded.categories;
                    }

                    return [];
                })
                .then((categories) => {
                    this.categories = new Map(
                        categories.map((category) => [category.id, category]),
                    );
                });
        }
        return this.request;
    }

    /**
     * @param {number} categoryId
     * @returns {SvpCategory[]}
     */
    getPath(categoryId) {
        const path = [];
        let category = this.categories?.get(categoryId);

        if (category) {
            path.push(category);

            while (category.parentId !== 0) {
                category = this.categories?.get(category.parentId);
                path.unshift(category);
            }
        }

        return path;
    }
}

/**
 * @type {Record<string, CategoryTree>}
 */
const instances = {};

/**
 * @param {string} vendor
 * @returns {CategoryTree}
 */
export default (vendor) => {
    if (!instances[vendor]) {
        instances[vendor] = new CategoryTree(vendor);
    }

    return instances[vendor];
};
