/* eslint-disable no-param-reassign, no-multi-assign */

import Events from './backbone.events';
import device from './device';
import merge from './merge';

const jwUtils = jwplayer.utils;
const jwUnderscore = jwplayer._;
// jwplayer is hard dependecy here
const utils = {
    Events: Events,
    extend: Object.assign,
    // merge with deep copy, similar to jQuery extend
    merge: merge,
    each: jwUnderscore.each,
    isNumber: (data) => typeof data === 'number' || data instanceof Number,
    isString: (data) => typeof data === 'string' || data instanceof String,
    isObject: (data) => {
        const type = typeof data;
        return data != null && (type === 'object' || type === 'function');
    },
    createNode: jwUtils.createElement,
    /**
     *
     * @param {HTMLElement} element
     * @param {string | string[]} classList
     * @returns {void}
     */
    addClass: (element, classList) => {
        if (Array.isArray(classList)) {
            element.classList.add(...classList);
        } else {
            element.classList.add(classList);
        }
    },
    /**
     *
     * @param {HTMLElement} element
     * @param {string | string[]} classList
     * @returns {void}
     */
    removeClass: (element, classList) => {
        if (Array.isArray(classList)) {
            element.classList.remove(...classList);
        } else {
            element.classList.remove(classList);
        }
    },

    /**
     * Template system borrowed from http://ejohn.org/blog/javascript-micro-templating/
     */
    template: (function () {
        var cache = {};

        return function tmpl(str, data) {
            // Figure out if we're getting a template, or if we need to
            // load the template - and be sure to cache the result.
            var fn = !/\W/.test(str)
                ? (cache[str] =
                      cache[str] ||
                      tmpl(document.getElementById(str).innerHTML))
                : // Generate a reusable function that will serve as a template
                  // generator (and which will be cached).
                  // eslint-disable-next-line @typescript-eslint/no-implied-eval
                  new Function(
                      'obj',
                      `${
                          'var p=[],print=function(){p.push.apply(p,arguments);};' +
                          // Introduce the data as local variables using with(){}
                          "with(obj){p.push('"
                      }${
                          // Convert the template into pure JavaScript
                          str
                              .replace(/[\r\t\n]/g, ' ')
                              .split('<%')
                              .join('\t')
                              .replace(/((^|%>)[^\t]*)'/g, '$1\r')
                              .replace(/\t=(.*?)%>/g, "',$1,'")
                              .split('\t')
                              .join("');")
                              .split('%>')
                              .join("p.push('")
                              .split('\r')
                              .join("\\'")
                      }');}return p.join('');`,
                  );

            // Provide some basic currying to the user
            return data ? fn(data) : fn({});
        };
    })(),
    /* jshint ignore:end */

    device: device,

    time: {
        shareTimeToSeconds: function (time) {
            var hours, minutes, seconds, length;

            time = jwUnderscore.compact(time.split(/[msh]/));
            length = time.length; // last is empty

            seconds = parseInt(time[length - 1], 10);
            minutes = time[length - 2];
            hours = time[length - 3];

            return (
                seconds +
                (minutes ? parseInt(minutes, 10) * 60 : 0) +
                (hours ? parseInt(hours, 10) * 3600 : 0)
            );
        },
    },

    number: {
        zeroPad: function (number) {
            number = parseInt(number, 10);
            return number < 10 ? `0${number}` : number.toString();
        },
    },

    string: {
        // timestamp [s]
        getTimeFormatted: function (timestamp) {
            var hours = Math.floor(timestamp / 3600),
                minutes = Math.floor((timestamp % 3600) / 60),
                seconds = timestamp % 60;

            return `${
                (hours > 0 ? `${hours}:` : '') + utils.number.zeroPad(minutes)
            }:${utils.number.zeroPad(seconds)}`;
        },
    },

    /**
     * Check snap points support
     * @returns {boolean}
     */
    getScrollSnapPrefix: function () {
        var scrollSnapSupport = false;

        if ('webkitScrollSnapType' in document.documentElement.style) {
            scrollSnapSupport = '-webkit-';
        } else if ('msScrollSnapType' in document.documentElement.style) {
            scrollSnapSupport = '-ms-';
        } else if ('scrollSnapType' in document.documentElement.style) {
            scrollSnapSupport = '';
        }

        return scrollSnapSupport;
    },

    jsonToUrl: function (obj) {
        return Object.keys(obj)
            .map(function (key) {
                return `${encodeURIComponent(key)}=${encodeURIComponent(
                    obj[key],
                )}`;
            })
            .join('&');
    },
};

export default utils;
