import utils from 'utils/utils';

import { events } from './homad/eventbus';
import { initializeHomadLib } from './homad/global';
import { applyPlayerMixin } from './homad/proxy';
import { waitForAdPlayer } from './homad/utils';

class Homad {
    /**
     * @param {PlayerModel} player
     */
    constructor(player) {
        initializeHomadLib();

        this.player = player;
        this.playerId = player.config.get('node');

        // Proxy Homad global events related to current player instance
        utils.extend(this, utils.Events);
        if (!events[this.playerId]) {
            events[this.playerId] = { ...utils.Events };
        }
        this.listenTo(events[this.playerId], 'all', this.trigger, this);

        // Override SVP Player's methods
        applyPlayerMixin(player, this);

        this.on('contentPlayerPlay', this.onContentPlayerPlay, player);
        this.on('adStart', this.onAdStart, player);
        this.on('continueContent', this.onContinueContent, player);
        this.on('adTime', this.onAdTime, player);
        player.on('adError', this.onPlayerAdError, player);
        player.on('adBreakStart', this.onPlayerAdBreakStart, player);
        player.on('playlistItem', this.onPlayerPlaylistItem, player);
        player.on('adPlay', this.onPlayerAdPlay, player);

        this.on(
            'adComplete adSkipped adPause adPlay',
            this.forwardEventToPlayer,
            this,
        );

        // Find Ad player's container and video elements
        const node = player.getContainer();
        waitForAdPlayer(node).then((dom) => {
            this.container = dom.container;
            this.video = dom.video;
            this.playButton = dom.playButton;
            this.muteButton = dom.muteButton;
            this.trigger('initialize');
        });
    }

    /**
     * Remove player events from messaging bus
     * Prevents memory leaks
     */
    destroy() {
        this.off();
        this.stopListening();
        if (events[this.playerId]) {
            events[this.playerId].off();
            events[this.playerId].stopListening();

            events[this.playerId] = null;
            delete events[this.playerId];
        }
    }

    /**
     * @this {PlayerModel}
     */
    onContentPlayerPlay() {
        this.getContainer().classList.remove('svp-homad-loading');
    }

    /**
     * @this {PlayerModel}
     * @param {object} data
     * @param {"preroll"|"midroll"|"postroll"} data.state
     * @param {number} data.duration
     * @param {{ adID: string }[]} data.adIds
     * @param {boolean} data.adPod
     * @param {string} data.adsession_id
     * @param {string} data.clickthrough
     * @param {number} data.currentTime
     * @param {string} data.homadImpressionAdId
     */
    onAdStart(data) {
        this.adPosition = data.state;

        if (this.adPlaying === false) {
            this.trigger('adSlotStart', {
                position: this.adPosition,
            });

            this.adPlaying = true;
        }

        this.trigger('adStarted', {
            position: this.adPosition,
            meta: {
                duration: data.duration,
                offset: 'pre',
            },
        });
    }

    /**
     * @this {PlayerModel}
     */
    onContinueContent() {
        this.getContainer().classList.remove('jw-flag-ads');

        if (this.adPlaying === true) {
            this.trigger('adSlotComplete', {
                position: this.adPosition,
            });

            this.adPlaying = false;
        }
    }

    /**
     * @this {PlayerModel}
     * @param {number} currentTime
     * @param {number} duration
     */
    onAdTime({ currentTime, duration }) {
        if (currentTime && this.adPlaying) {
            this.trigger('adProgress', currentTime, duration);
        }
    }

    /**
     * Forward Homad event only if an ad is playing
     * @param {string} eventName
     */
    forwardEventToPlayer(eventName) {
        this.on(eventName, () => {
            if (this.player.adPlaying) {
                this.player.trigger(eventName);
            }
        });
    }

    /**
     * @this {PlayerModel}
     */
    onPlayerAdError() {
        const jw = this.player;
        const container = this.getContainer();

        this.hasAdBlocked = true;

        // just to be sure
        this.once(
            'adStarted',
            function () {
                this.adPlayerLoading = false;
            },
            this,
        );

        this.once(
            'adSlotComplete',
            function () {
                container.classList.remove('svp-homad-loading');
            },
            this,
        );

        this.listenToOnce(
            jw,
            'play',
            function () {
                this.adPlayerLoading = true;
            },
            this,
        );

        this.listenToOnce(
            jw,
            'pause',
            function (options) {
                // first external pause is triggered by homad
                // this equals to ad tag loaded and parsed
                if (options && options.pauseReason === 'external') {
                    this.adPlayerLoading = false;
                }
            },
            this,
        );
    }

    /**
     * @this {PlayerModel}
     * @param {string} adposition
     */
    onPlayerAdBreakStart({ adposition } = {}) {
        const duration = this.getDuration();
        const currentTime = this.getCurrentTime();
        const container = this.getContainer();

        // homad has strange constraints for midroll
        if (
            adposition !== 'mid' ||
            (currentTime > 30 && duration - currentTime > 30)
        ) {
            container.classList.add('svp-homad-loading');

            // clean loading in case of any error
            setTimeout(function () {
                container.classList.remove('svp-homad-loading');
            }, 5000);

            this.listenToOnce(this.player, 'adPlay', () => {
                container.classList.remove('svp-homad-loading');
            });
        }
    }

    // turn off ad player loading when there is no ads
    /**
     * @this {PlayerModel}
     * @param playlistItem
     */
    onPlayerPlaylistItem(playlistItem) {
        if (playlistItem.item && !playlistItem.item.adschedule) {
            this.adPlaying = false;
            this.adPlayerLoading = false;
        }
    }

    /**
     * @this {PlayerModel}
     */
    onPlayerAdPlay() {
        this.adPlaying = true;
        this.adPlayerLoading = false;
    }
}

export default Homad;
