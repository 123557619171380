/**
 * Returns an object with query string parameters (key-value pairs) extracted from the current url.
 */
function getQueryStringParams() {
  const params = new URLSearchParams(window.location.search);

  return [...params].reduce(
    (aggr, [key, value]) => ({
      ...aggr,
      [key]: value,
    }),
    {} as Partial<Record<string, string>>
  );
}

export { getQueryStringParams };
