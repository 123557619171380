/**
 * @typedef HomadDOM
 * @property {HTMLVideoElement} video
 * @property {HTMLDivElement} container
 * @property {HTMLImageElement} playButton
 * @property {HTMLImageElement} muteButton
 */

/**
 * Get Ad Player from player container
 *
 * This function awaits for the HOMAD system to bootstrap in the DOM,
 * then finds the appropriate video tag and HOMAD's main container
 *
 * @param {HTMLDivElement} playerContainer
 * @return {Promise<HomadDOM>}
 */
export function waitForAdPlayer(playerContainer) {
    return new Promise((resolve) => {
        const handler = (event) => {
            if (event.detail && event.detail.container === playerContainer) {
                const videoElements = playerContainer.querySelectorAll('video');
                const [video] = [...videoElements].filter((v) => !v.className);
                if (video) {
                    /**
                     * [div] homad main container
                     * └─ [div]
                     *    ├─ [video] click event goes to the advertiser
                     *    ├─ [div] time label
                     *    ├─ [div] controls container
                     *    │  ├─ [img] play button
                     *    │  └─ [img] mute button
                     *    └─ [div] overlay (to unpause)
                     */
                    const container = video.parentNode.parentNode;
                    const controlsContainer =
                        video.nextElementSibling.nextElementSibling;
                    const [playButton, muteButton] =
                        controlsContainer.childNodes;

                    window.removeEventListener('hdEvent', handler);
                    resolve({
                        video,
                        container,
                        playButton,
                        muteButton,
                    });
                }
            }
        };

        window.addEventListener('hdEvent', handler);
    });
}
