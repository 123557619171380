import getUserId from '../getUserId';
import type {
  AppnexusSettings,
  DeviceType,
  GamKeywords,
  GetAdnSettings,
  XandrKeywords,
} from '../types';
import { prepareXandrKeywords, prepareGamKeywords } from '../prepareKeywords';
import {
  getCategoryMetadataValue,
  getCategoryShortPath,
} from '../../../utils/player/category';
import { getAssetEmbeddedTags, getRawAsset } from '../../../utils/player/asset';
import { getJWPlayerProvider } from '../../../utils/player/player';
import { getAdConsentKeywords } from '../getAdPermission';
import { getTcfConsentKeywords } from '../getTcfConsentKeywords';

import { formatTagValue } from './formatTagValue';
import { getInvCode } from './getInvCode';
import { config } from './config';
import { getEnvironmentKeywords } from './getEnvironmentKeywords';
import { getQueryStringKeywords } from './getQueryStringKeywords';
import { getRelevantYield } from './getRelevantYield';

/**
 * @see {@link https://docs.google.com/document/d/1ggtj5p4KSsNZVNsJXCcGRdivNkwxIZiQvqP-dlAVDBA/edit|Specification} for details
 */
const getAdnSettings: GetAdnSettings = async (
  context,
  options,
  stream,
  defaults,
  player
) => {
  const manager = context.autoplayManager!;
  manager.setPlayer(player);

  const { site } = options;

  const siteConfig = config[site as keyof typeof config];
  if (!siteConfig) {
    console.warn(`Player ads config for site '${site}' not found`);
  }

  const asset = getRawAsset(player);
  if (!siteConfig || !asset) {
    return null;
  }

  const { member, invCodeTemplate, afpTemplate, formats, mainSection } =
    siteConfig;
  const defaultKeywords: XandrKeywords = {
    ...defaults,
    ...siteConfig.keywords,
  };
  const deviceType = String(defaultKeywords.supplyType || '').replace(
    'web_',
    ''
  ) as DeviceType;

  const useAFP = getCategoryMetadataValue(asset.category, 'afp') === 'true';
  const tags = getAssetEmbeddedTags(asset).map(({ tag }) =>
    formatTagValue(tag)
  );

  const muted = player.getMute() ?? Boolean(player.getRawConfig().mute);

  const prerollFormat = manager.getPrerollFormat();

  const categoryShortPath = getCategoryShortPath(asset.category);
  const keywords: Partial<XandrKeywords> = {
    'aa-sch-country_code': 'se',
    'aa-sch-supply_type': defaultKeywords.supplyType,
    'aa-sch-publisher': defaultKeywords.publisherName,
    'aa-sch-page_type': defaultKeywords.pageType,
    'se-aftonbladet-section': [
      mainSection,
      ...(options.ads?.sectionKeywords ?? []),
      categoryShortPath,
    ]
      .filter((v): v is string => Boolean(v))
      .join(','),
    'se-generic-section': [
      mainSection,
      ...(options.ads?.sectionKeywords ?? []),
      categoryShortPath,
    ]
      .filter((v): v is string => Boolean(v))
      .join(','),
    'se-generic-articleid': String(asset.id),
    'article-id': String(asset.id),
    'se-generic-tags': tags,
    'se-generic-page': 'article',
    ...getEnvironmentKeywords(player, deviceType),
    ...getQueryStringKeywords(),
    ...(await getAdConsentKeywords(context.consentManager)),
    ...(await getTcfConsentKeywords()),
  };

  const gamParams: GamKeywords = prepareGamKeywords({
    'country_code': 'se',
    'publisher': defaultKeywords.publisherName,
    'supply_type': defaultKeywords.supplyType,
    'page': 'article',
    'aftonbladet-section': [
      mainSection,
      ...(options.ads?.sectionKeywords ?? []),
      categoryShortPath,
    ]
      .filter((v): v is string => Boolean(v))
      .join(','),
    'section': [
      mainSection,
      ...(options.ads?.sectionKeywords ?? []),
      categoryShortPath,
    ]
      .filter((v): v is string => Boolean(v))
      .join(','),
    tags,
    ...keywords,
  });

  const invTemplate = useAFP && afpTemplate ? afpTemplate : invCodeTemplate;
  const userId = getUserId();

  const settings: AppnexusSettings = {
    member,

    options: {
      autoplayMuted: muted && prerollFormat === 'bumper',
      vpaidcontrols: true,
    },

    adSlots: {
      schedule: {
        preroll: formats?.preroll
          ? prepareXandrKeywords({
              'inv_code': getInvCode(
                invTemplate!,
                prerollFormat,
                deviceType,
                useAFP
              ),
              'se-generic-adformat': useAFP
                ? `afp_${prerollFormat}`
                : prerollFormat,
            })
          : null,
        midroll: formats?.midroll
          ? prepareXandrKeywords({
              'inv_code': getInvCode(
                invTemplate!,
                'midroll',
                deviceType,
                false // afp is not supported for midrolls
              ),
              'se-generic-adformat': 'midroll',
            })
          : null,
      },
      params: prepareXandrKeywords(keywords),
    },

    adPlacements:
      formats?.pause && getJWPlayerProvider(player) !== 'chromecast'
        ? ({
            schedule: {
              pause: {
                sizes: [
                  [1280, 720],
                  [825, 424],
                ],
                invCode: getInvCode(invTemplate!, 'pause', deviceType, useAFP),
                keywords: prepareXandrKeywords({
                  'se-generic-adformat': useAFP ? 'afp_pause' : 'pause',
                }),
              },
            },
            params: {
              keywords: prepareXandrKeywords(keywords),
            },
          } as AppnexusSettings['adPlacements'])
        : null,

    ...(options.ads?.enableRelevantYield && {
      relevantYield: getRelevantYield(
        gamParams,
        deviceType,
        useAFP,
        prerollFormat,
        context.consentManager?.type === 'tcf',
        userId
      ),
    }),
  };

  if (userId) {
    settings.adSlots.params.external_uid = userId;

    if (settings.adPlacements) {
      settings.adPlacements.params.keywords = {
        ...settings.adPlacements.params.keywords,
        external_uid: userId,
      };
    }
  }

  return settings;
};

export default getAdnSettings;
