// see https://github.schibsted.io/vgtv/frontend/issues/414 for reference
const formatTagValue = (tag = '') =>
  tag
    .trim()
    .toLowerCase()
    .replace(/["'=!+#*~;^()<>[\]@:?%|]/g, '')
    .replace(/,+/g, '-')
    .replace(/\\/g, '-')
    .replace(/\//g, '-')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .replace(/&/g, '--')
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o');

export { formatTagValue };
