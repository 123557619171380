export const ACTIVE = 12000;
export const ACTIVE_PREVIEW = 12001;

export const NOT_ACTIVE = 14001;
export const NOT_ACTIVE_GEOBLOCKED = 14002;
export const NOT_ACTIVE_TOKEN = 14005;
export const NOT_FOUND = 14003;
export const NOT_ACTIVE_PAST = 14004;

export const GENERAL_ERROR = 15000;
export const NETWORK_ERROR = 15101;
export const TOKEN_FETCH_ERROR = 15102;

const statusMessages = {
    [ACTIVE]: 'Stream active',
    [ACTIVE_PREVIEW]: 'Preview mode',
    [NOT_ACTIVE]: 'Stream is not active',
    [NOT_ACTIVE_GEOBLOCKED]:
        'Stream failed to play due to geoblock restrictions',
    [NOT_ACTIVE_TOKEN]: 'Stream failed to play due to unauthorized access',
    [NOT_FOUND]: 'Stream not found',
    [NOT_ACTIVE_PAST]: 'Stream has been unpublished',
    [GENERAL_ERROR]: 'General error',
    [NETWORK_ERROR]: 'Network error',
    [TOKEN_FETCH_ERROR]: 'Token fetch error',
};

/**
 * Get localized messages
 *
 * @param {number} errorCode
 */
export default (errorCode) =>
    statusMessages[errorCode] || statusMessages[GENERAL_ERROR];
