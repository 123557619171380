import { type ConsentManager, PERMISSION_VALUE } from '@vgtv/consent-manager';

export const getAdPermission = async (consentManager?: ConsentManager) => {
  if (!consentManager) {
    return PERMISSION_VALUE.ACCEPTED;
  }

  const cmpConsent = await consentManager.getConsentedToAll();

  return cmpConsent ? '1' : '0';
};

export const getAdConsentKeywords = async (consentManager?: ConsentManager) => {
  const cmpConsent = await getAdPermission(consentManager);

  return {
    'aa-sch-cmp_advertising': cmpConsent,
    'cmp_advertising': cmpConsent,
  };
};
