export default {
  set(key: string, value: unknown) {
    try {
      sessionStorage.setItem(String(key), JSON.stringify(value));
    } catch (error) {
      console.error(`Failed saving ${key} to session storage`);
    }
  },

  get(key: string) {
    try {
      const serialized = sessionStorage.getItem(String(key));

      return JSON.parse(serialized!);
    } catch (error) {
      console.error(`Failed retrieving ${key} from session storage`);
    }

    return undefined;
  },

  clear(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error(`Failed removing ${key} from session storage`);
    }
  },
};

export const AUTH_REDIRECT_KEY = 'paywall-auth-redirect';
