/* eslint-disable @typescript-eslint/naming-convention */
import { Provider } from '@vgtv/api-client';
import type {
  ABAccess,
  VGTVAccess,
} from '@vgtv/svp-access/lib/access_mappings';

import type { PlayerConfigPaywall } from '../../types/global';

import { getSessionCookie } from './spid';

const state = {
  loggedIn: false,
  hasAccess: false,
};

function getCookie(key: string) {
  const matches = document.cookie.match(new RegExp(`\\b${key}=([^ ;]+)`)) || [];
  const cookie = matches.length > 0 ? matches[1] : null;

  return cookie ? decodeURIComponent(cookie) : null;
}

const TOKEN_API_HOSTNAME: Record<Provider, { pre: string; pro: string }> = {
  vgtv: {
    pre: '//api-stage.vgnett.no/',
    pro: 'https://api.vg.no/',
  },
  ab: {
    pre: 'https://svp-token-api.aftonbladet.dev/',
    pro: 'https://svp-token-api.aftonbladet.se/',
  },
};

async function fetchAccessToken<P extends Provider>(
  vendor: P,
  usePre: boolean | undefined,
  assetId: number,
  accessLevel: P extends Provider.VGTV ? VGTVAccess : ABAccess
): Promise<{
  expiry?: number;
  value?: string;
  loggedIn?: boolean;
  hasAccess?: boolean;
}> {
  const sp_id = getCookie('SP_ID');
  const id_jwt = getCookie('id-jwt');
  const iap_id = getCookie('IAP_ID');

  const headers: HeadersInit = {};

  if (sp_id || id_jwt) {
    headers['x-sp-id'] = (sp_id || id_jwt)!;
  }

  if (iap_id) {
    headers['x-iap-id'] = iap_id;
  }

  if (!(sp_id || id_jwt || iap_id)) {
    const cookie = await getSessionCookie();

    if (cookie) {
      headers['x-sp-id'] = cookie;
    } else {
      // no need to check the API if we have no authentication methods available
      return Promise.resolve({ loggedIn: false, hasAccess: false });
    }
  }

  const tokenApiHostname = TOKEN_API_HOSTNAME[vendor][usePre ? 'pre' : 'pro'];

  return fetch(
    `${tokenApiHostname}svp/token/${assetId}?access=${accessLevel}`,
    { headers }
  ).then((response) => response.json());
}

export default function getPlayerToken<P extends Provider>(
  config: PlayerConfigPaywall,
  vendor = Provider.VGTV
) {
  return (
    assetId: number,
    access?: P extends Provider.VGTV ? VGTVAccess : ABAccess
  ) => {
    return new Promise((resolve) => {
      if (!access) {
        resolve({});
        return;
      }

      fetchAccessToken(vendor, config.usePre, assetId, access)
        .then((response) => {
          state.loggedIn = response.loggedIn || false;
          state.hasAccess = response.hasAccess || false;

          if (response.value && response.expiry) {
            resolve({
              value: response.value,
              expiry: response.expiry,
            });
          } else {
            resolve({});
          }
        })
        .catch(() => {
          resolve({});
        });
    });
  };
}

export function isLoggedIn() {
  return Promise.resolve(state.loggedIn);
}

export function hasAccess() {
  return Promise.resolve(state.hasAccess);
}
