import type { Asset } from '@schibsted-svp/svp-api-types';

import { getAssetAspectRatio } from '../../../utils/player/asset';

const threshold = 0.05;
const arSeries = [
  [1, 1],
  [16, 9],
  [4, 3],
];

/**
 * This utility function returns asset aspect ratio in the { width, height } format
 * If none of the predefined values are matched, it returns undefined
 */
export const getAspectRatioCompound = (asset: Asset) => {
  const ar = getAssetAspectRatio(asset);

  for (let i = 0; i < arSeries.length; i++) {
    const dimensions = arSeries[i];
    // Include both portrait and landscape variants
    const variations = [dimensions, [...dimensions].reverse()];

    for (let j = 0; j < variations.length; j++) {
      const [width, height] = variations[j];
      if (Math.abs(ar - width / height) < threshold) {
        return { width, height };
      }
    }
  }

  return undefined;
};
