import type { Asset } from '@schibsted-svp/svp-api-types';
import { assetAdditionalData, relatedApi } from '../api';

export async function getRelatedAssets({
    vendor,
    assetId,
    limit,
}: {
    vendor: string;
    assetId: number;
    limit?: number;
}): Promise<Asset[]> {
    const query = [
        `additional=${assetAdditionalData}`,
        `ignoreAspectRatio=true`,
    ];
    if (limit) {
        query.push(`limit=${limit}`);
    }

    return relatedApi({ path: `${vendor}/${assetId}`, query: query.join('&') });
}
