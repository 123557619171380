import Eventable from 'utils/eventable';

/**
 * @typedef AdEventsAdData
 * @property {string} id
 * @property {false|number[]} adSequenceDuration
 * @property {number} duration integer
 * @property {number} position integer
 * @property {number} sequence ad sequence position
 * @property {number} count
 * @property {boolean} start
 * @property {string} type
 */

/**
 * Parse SVP Player events and tailor data to pulse requirements
 *
 * Available events: Play, Pause, Stop
 */
class AdEvents extends Eventable {
    constructor(player) {
        super();
        this.player = player;
        /**
         * @type {AdEventsAdData}
         */
        this.adData = { adSequenceDuration: false };
        this.beforeUnload = [
            'onpagehide' in window.self ? 'pagehide' : 'beforeunload',
            this.onAdSlotComplete.bind(this),
            true,
        ];

        this.listenTo(this.player, 'playlistItem', this.onPlaylistItem, this);
        this.listenTo(this.player, 'adSlotStart', this.onAdSlotStart, this);
        this.listenTo(this.player, 'adStarted', this.onAdStarted, this);
        this.listenTo(this.player, 'adError', this.onAdError, this);
        this.listenTo(this.player, 'adProgress', this.onAdProgress, this);
        this.listenTo(
            this.player,
            'adSlotComplete',
            this.onAdSlotComplete,
            this,
        );
        this.listenTo(this.player, 'adFinished', this.onAdFinished, this);

        this.listenTo(this.player, 'adPause', this.onAdPause, this);
        this.listenTo(this.player, 'adPlay', this.onAdPlay, this);

        this.listenTo(
            this.player,
            'PausePlugin:adVisible',
            this.onPauseAdVisible,
            this,
        );

        this.listenTo(this.player, 'playlistItem', () => {
            this.adData.adSequenceDuration = false;
        });

        window.addEventListener(...this.beforeUnload);
    }

    onPlaylistItem() {
        this.listenToOnce(this.player, 'complete', this.onAdSlotComplete, this);
    }

    onAdSlotStart(slotData) {
        Object.assign(this.adData, {
            type: slotData.position,
        });
    }

    onAdProgress(time) {
        if (!this.adData.id) {
            return;
        }

        const position = Math.floor(time);

        Object.assign(this.adData, {
            position,
        });
    }

    onAdError(adErrorData) {
        this.sendAttemptEvent(adErrorData);
    }

    onAdSlotComplete() {
        this.sendStopEvent();
        window.removeEventListener(...this.beforeUnload);
    }

    onAdFinished() {
        this.sendStopEvent();
    }

    onAdStarted({ meta: adData }) {
        Object.assign(this.adData, {
            start: true,
        });

        // adData is missing from homad events
        if (!adData) {
            return;
        }

        Object.assign(this.adData, {
            id: adData.adId,
            sequence: adData.sequence,
            count: adData.podcount,
            position: 0,
        });

        if (!this.adData.adSequenceDuration) {
            this.adData.adSequenceDuration = Array(adData.podcount);
        }

        const adType = adData?.adschedule?.offset;
        // Preroll ads only
        if (adType === 'pre' && adData.duration) {
            this.adData.adSequenceDuration[adData.sequence - 1] =
                adData.duration;
        }
    }

    onAdPlay() {
        this.trigger('Play', this.adData);
        // start indicates first play of ad
        this.adData.start = false;
    }

    onAdPause() {
        this.trigger('Pause', this.adData);
    }

    onPauseAdVisible(data) {
        this.trigger('View', {
            id: data.creativeId,
            type: 'Pausead',
        });
    }

    sendStopEvent() {
        if (this.adData.id) {
            this.trigger('Stop', {
                ...this.adData,
                duration: this.adData.position,
            });
            // keep adSequenceDuration for playback tracking purposes
            this.adData = {
                adSequenceDuration: this.adData.adSequenceDuration,
            };
        }
    }

    sendAttemptEvent(adErrorData) {
        this.trigger('Attempt', {
            ...this.adData,
            id: '000000',
            position: 0,
            type: adErrorData?.adposition
                ? `${adErrorData.adposition}roll`
                : undefined,
            sequence: 1,
            count: 0,
            adSequenceDuration: [0],
        });
    }

    destroy() {
        window.removeEventListener(...this.beforeUnload);
        this.stopListening();
    }
}

export default AdEvents;
