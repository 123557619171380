import { GamKeywords } from '../types';

const getPath = (
  deviceType: string,
  useAFP: boolean,
  adType: 'preroll' | 'bumper' | 'midroll'
) => {
  const deviceStringPreroll =
    deviceType === 'desktop' ? 'desktop_web_tv' : 'phone_web_tv';
  const deviceStringMidroll =
    deviceType === 'desktop' ? 'desktop_web' : 'phone_web';
  const deviceString = ['preroll', 'bumper'].includes(adType)
    ? deviceStringPreroll
    : deviceStringMidroll;
  const deviceShortcut = deviceType === 'desktop' ? 'wde' : 'wph';

  if (useAFP) {
    return `/21832178469/se-sms-aftonbladet/${deviceString}/se-aftonbladet-tv-${deviceShortcut}-afp_${adType}`;
  }
  return `/21832178469/se-sms-aftonbladet/${deviceString}/se-aftonbladet-${deviceShortcut}-other-${adType}`;
};

export const getRelevantYield = (
  gamParams: GamKeywords,
  deviceType: string,
  useAFP: boolean,
  prerollFormat: 'preroll' | 'bumper',
  tcfEnabled: boolean,
  userId?: string
) => {
  const prerollPath = getPath(deviceType, useAFP, prerollFormat);
  const midrollPath = getPath(deviceType, useAFP, 'midroll');
  const relevantYield = {
    // every ad client/page has different config id
    configId: '639c6673581480875f1a88b4',
    consentManagement: tcfEnabled
      ? { gdpr: { cmpApi: 'iab', timeout: 8000 } }
      : { cmpApi: 'none' },
    ...(userId && { userId }),
    // every ad client/page has different script url - if client don't want to download script, player supports fallback for downloading
    // please read paragraph above about performance concerns
    scriptUrl:
      'https://schibsted-cdn.relevant-digital.com/static/tags/632483bee006f28c61dae680.js',
    adSlots: {
      preroll: {
        path: prerollPath,
        params: {
          // each slot can have own custom params
          // 'name': 'test',
          'adformat': prerollFormat,
          'kw_se-generic-adformat': prerollFormat,
          'se-generic-adformat': prerollFormat,
        },
      },
      midroll: {
        path: midrollPath,
        params: {
          'adformat': 'midroll',
          'kw_se-generic-adformat': 'midroll',
          'se-generic-adformat': 'midroll',
        },
      },
    },
    params: {
      ...gamParams,
      // common params for every ad slot
      'se-generic-test': `prebid-${prerollFormat}-test`,
      'adformat': prerollFormat,
      'min_ad_duration': '1000',
      'max_ad_duration': '20000',
    },
  };

  return relevantYield;
};
