/*
 * @see https://github.schibsted.io/smp-distribution/hyperion/blob/c9c7a85d8800869e1fa223349cac079ce78b2bea/src/features/ads/preparePageOptions.ts#L54
 * @see https://docs.xandr.com/bundle/monetize_monetize-standard/page/topics/key-value-targeting.html
 */
const formatTagValue = (tag = '') => {
  if (!tag?.trim()) {
    return undefined;
  }

  return tag
    .trim()
    .toLowerCase()
    .replace(/["'=!+#*~;^()<>@:?%|,.[\]]/g, '')
    .replace(/&/g, 'and')
    .replace(/[-\s]+/g, '-')
    .replace(/å/g, 'aa')
    .replace(/ø/g, 'oe')
    .replace(/æ/g, 'ae')
    .replace(/^/, 'vg_');
};

export { formatTagValue };
