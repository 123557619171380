import type { DeviceType } from '../types';

/**
 * Returns a correct abbreviation for a given {@link DeviceType} to use in an {@link InventoryCode}.
 */
function getDevice(deviceType: DeviceType) {
  return deviceType === 'phone' ? 'wph' : 'wde'; // AB doesn't have a separate placement for tablets
}

export { getDevice };
