import utils from 'utils/utils';
import locale from 'player/model/locale';
import PluginModel from './model';

/**
 * Displays Age Limit labels in player
 *
 * @param options
 * @constructor
 */
class AgeLimitPlugin extends PluginModel {
    constructor(options) {
        super(options);
        this.ageLimit = options.ageLimit;
        this.hideAfter =
            options.settings && options.settings.hideAfter != null
                ? options.settings.hideAfter
                : 3000;
    }

    // eslint-disable-next-line
    getName() {
        return 'AgeLimitPlugin';
    }

    setup() {
        this.listenToOnce(this.player, 'assetPlay', this.render, this);

        this.once('render', this.show, this);
    }

    render() {
        // return number when there is no translation available
        const ageLimit = locale.translate(
            `ageLimit-${this.ageLimit}`,
            this.ageLimit,
        );

        this.el = utils.createNode(`
          <div class="svp-age-limit-label svp-age-limit-${ageLimit}">
            <svg width="100%" height="100%" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                <rect
                  fill="#000" fill-opacity="0.03" stroke="currentColor" stroke-width="15"
                  rx="50" x="10" y="10" width="980" height="980"
                />
                <path fill="#fff" stroke="currentColor" stroke-width="80" d="M180 180 820 180 820 820 180 820 z" />
            </svg>

            <span>${ageLimit}</span>
          </div>
        `);
        this.player.getContainer().appendChild(this.el);

        this.trigger('render');
    }

    destroy() {
        this.stopListening(this.player);

        if (this.el) {
            this.player.getContainer().removeChild(this.el);
            this.el = null;
        }
    }

    show() {
        if (this.ageLimit !== null) {
            utils.addClass(this.player.getContainer(), 'svp-display-age-limit');

            if (this.hideAfter) {
                this.listenToOnce(
                    this.player,
                    'time',
                    setTimeout.bind(null, this.hide.bind(this), this.hideAfter),
                );
            }
        }
    }

    hide() {
        utils.removeClass(this.player.getContainer(), 'svp-display-age-limit');
    }
}

export default AgeLimitPlugin;
