import config from 'player/config';
import utils from 'utils/utils';

/**
 * @param {string} url
 * @param {Object<string, string|number>} params
 * @returns {string}
 */
function buildUrlString(url, params) {
    const cb = Math.round(Math.random() * 100000000000);
    const queryParams = utils.jsonToUrl(utils.extend({ cb }, params));
    return `${url}?${queryParams}`;
}

/**
 * This is only for overriding/preview ads live on the page
 * @see https://github.schibsted.io/svp/web-player/issues/833
 * @param {string|number} overriddenId
 * @returns {string}
 */
export function getOverriddenCrTag(overriddenId) {
    const overriddenParams = { id: overriddenId, format: 'vast' };
    return buildUrlString(config.ads.appnexus.cr, overriddenParams);
}

/**
 * @param {Record<key, *>} params
 * @returns {string}
 */
export function getPtvTag(params) {
    return buildUrlString(config.ads.appnexus.ptv, params);
}

/**
 * @param {Record<key, *>} params
 * @returns {string}
 */
export function getVmapTag(params) {
    return buildUrlString(config.ads.appnexus.vmap, params);
}

/**
 * @param {Object} tagOptions
 * @param {Object} globalOptions
 * @returns {Object}
 */
export function getAstConfig(tagOptions, globalOptions) {
    return utils.extend({}, utils.merge(globalOptions, tagOptions));
}

/**
 * @param {Stream & Eventable} stream
 * @returns {Object}
 */
export function getDefaultParams(stream) {
    const publisherName = stream.getVendor();
    const countryCode = config.vendors.se.includes(publisherName) ? 'se' : 'no';
    const supplyType = `web_${utils.device.getDeviceType()}`;
    return {
        countryCode,
        supplyType,
        publisherName,
        slotIds: { preroll: 1, midroll: 2, postroll: 3 },
    };
}
