/**
 * @param {HTMLElement} container
 * @return {Promise<void>}
 */
const waitForControls = (container) =>
    new Promise((resolve) => {
        const checkIfControlsLoaded = () => {
            const hasControlsLoaded = container.querySelector('.jw-controlbar');

            if (hasControlsLoaded) {
                resolve();
            } else {
                window.requestAnimationFrame(checkIfControlsLoaded);
            }
        };

        window.requestAnimationFrame(checkIfControlsLoaded);
    });

/**
 * If jwplayer chooses to use bundle without embedded controls, it can load them asynchronously later,
 * in that case, we must ensure their presence when e.g. resolving audio skin.
 *
 * @param {HTMLElement} container
 * @return {Promise<void>}
 */
export const ensureIfControlsLoaded = async (container) => {
    const controlsLoaded = Boolean(container.querySelector('.jw-controlbar'));

    if (controlsLoaded) {
        return;
    }

    await waitForControls(container);
};
