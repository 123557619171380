import utils from 'utils/utils';
import locale from 'player/model/locale';
import PluginModel from './model';

const SVP_FLAG_NATIVE_LINK = 'svp-flag-native-link';
const SVP_NATIVE_LINK_OVERLAY = 'svp-native-link-overlay';
const SVP_NATIVE_LINK_BUTTON = 'svp-native-link-button';
const SVP_LOGIN_OVERLAY = 'svp-login-overlay';

/**
 * Allowing using native link for playing podcasts on hermes apps
 *
 * @param options
 * @constructor
 */
class PodcastNativeLinkPlugin extends PluginModel {
    constructor(options) {
        super(options);
        this.link = null;
    }

    // eslint-disable-next-line
    getName() {
        return 'PodcastNativeLinkPlugin';
    }

    setup() {
        const asset = this.player.getAsset({
            shouldLogDeprecatedWarning: false,
        });

        if (utils.device.isHermesApp()) {
            this.link = `audio:${asset.getVendor()}:${asset.getId()}`;
        }

        this.listenToOnce(this.player, 'viewable', this.render, this);
    }

    render() {
        if (!this.link) {
            return;
        }

        const translations = locale.getTranslations();
        const container = this.player.getContainer();

        // do not show when there is login overlay
        const loginOverlay = container.querySelector(`.${SVP_LOGIN_OVERLAY}`);
        if (loginOverlay) {
            return;
        }

        // Native link has unsupported format and cannot be created using JW utils.createElement
        // @see https://github.schibsted.io/svp/web-player/issues/1003
        const linkAnchor = document.createElement('a');
        const linkSpan = document.createElement('span');
        linkSpan.classList.add(SVP_NATIVE_LINK_BUTTON);
        linkSpan.appendChild(
            document.createTextNode(translations.player.listen),
        );
        linkAnchor.appendChild(linkSpan);
        linkAnchor.classList.add(SVP_NATIVE_LINK_OVERLAY);
        linkAnchor.setAttribute('href', this.link);

        this.el = linkAnchor;

        utils.addClass(container, SVP_FLAG_NATIVE_LINK);
        container.appendChild(this.el);
    }

    destroy() {
        if (this.el) {
            const container = this.player.getContainer();

            utils.removeClass(container, SVP_FLAG_NATIVE_LINK);

            const overlay = container.querySelector(
                `.${SVP_NATIVE_LINK_OVERLAY}`,
            );
            if (overlay) {
                overlay.parentNode.removeChild(overlay);
            }
        }
    }
}

export default PodcastNativeLinkPlugin;
