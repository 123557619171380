const warned: Record<string, boolean> = {};

export const warnOnce = (id: string, message: string) => {
    if (warned[id]) {
        return;
    }
    warned[id] = true;
    // eslint-disable-next-line no-console
    console.warn(message);
};
