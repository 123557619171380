import { onPlaylistItem } from './playlist';
import { onComplete } from './complete';
import { onError } from './error';

/**
 * Remove single plugin instance
 * Clear all listeners
 * Remove from plugins hashmap
 *
 * @param {PluginModel} plugin
 */
export function destroyPlugin(plugin) {
    if (plugin.off) {
        plugin.off();
    }
    plugin.destroy();

    this.stopListening(plugin);
    delete this.plugins[plugin.getName()];
}

/**
 * Proxy player events
 */
export function onPlayerEvent(event, ...args) {
    let callFunction;

    switch (event) {
        case 'playlistItem':
            callFunction = onPlaylistItem.bind(this, ...args);
            break;
        case 'complete':
            callFunction = onComplete.bind(this, ...args);
            break;
        case 'error':
            callFunction = onError.bind(this, ...args);
            break;
        default:
            callFunction = this.trigger.bind(this, event, ...args);
    }

    callFunction();
}
