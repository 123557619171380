import config from 'player/config';
import { scriptLoader } from 'utils/script-loader';
import ServerTime from 'utils/server-time';

// eslint-disable-next-line
const loadSvpLibrary = () => {
    return import(/* webpackMode: "eager" */ './index').then((SVP) => [
        SVP.default,
        SVP.preInitialize,
        SVP.postInitialize,
    ]);
};

/**
 * @param {Window} root
 */
const loadJwPlayer = async (root) => {
    const PLAYER_LIB = `${config.cdn.player}/jwplayer.js`;

    return scriptLoader(PLAYER_LIB).then((jwplayer = root.jwplayer) => {
        root.jwplayer = jwplayer;
    });
};

(async function (root) {
    // onSvpPlayerReady is required callback to handle due to SDK async loading
    const onReady = root.onSvpPlayerReady;

    await Promise.all([ServerTime.fetch(config.time), loadJwPlayer(root)]);

    const library = await loadSvpLibrary();
    const [SVP, preInitialize, postInitialize] = library || [];

    if (!root?.SVP || root.SVP?.isLoaded !== true) {
        root.SVP = SVP;
        root.SVP.isLoaded = true;

        preInitialize();

        if (window.dispatchEvent) {
            const event = document.createEvent('Event');
            event.initEvent('onSvpPlayerReady', false, true);
            window.dispatchEvent(event);
        }

        if (onReady) {
            onReady();
        }

        postInitialize();
    }
})(window);
