import utils from 'utils/utils';
import logger from 'utils/logger';
import ServerTime from 'utils/server-time';
import Asset from '../model/asset';
import NextAsset from '../model/next-asset';
import Stream from '../model/stream';
import PausePlugin from '../plugins/pause';
import AgeLimitPlugin from '../plugins/age-limit';
import SponsorLabelPlugin from '../plugins/sponsor-label';
import PodcastExperimentPlugin, {
    prepareVariantsForExperiment,
    chooseVariant,
} from '../plugins/podcast-experiment';
import CountdownPlugin from '../plugins/countdown';
import RecommendedPlugin from '../plugins/recommended';
import PulseStatsPlugin from '../plugins/pulse-stats';
import PodcastNativeLinkPlugin from '../plugins/podcast-native-link';
import AABPlugin from '../plugins/anti-adblock';
import statusCode from '../playback/config/status-codes';

import { clearError, onError } from './error';
import { destroyPlugin } from './helpers';

/**
 * this always points to Player object
 * @memberOf {Player}
 */
export async function onAssetReady() {
    const { config: configuration } = this;

    const stream = new Stream(this.asset.attributes);
    const settings = configuration.getSettings();
    const node = document.getElementById(configuration.get('node'));
    const hasPlaylist = stream.hasPlaylist();

    if (node) {
        clearError(node);
    }

    // turn off recommended plugin for all tts assets
    if (this.model.config.shouldApplyTextToSpeechSkin(stream)) {
        this.config.set('recommended', false);
    }

    const isAssetFromPlaylist = this.model.isAssetFromPlaylist(stream.getId());

    if (!isAssetFromPlaylist) {
        const masterAsset = hasPlaylist
            ? new Asset(utils.extend({}, this.asset.attributes))
            : null;
        this.model.setMasterAsset(masterAsset);
    }

    // add hasNext checking
    stream.set(
        'hasNext',
        !hasPlaylist ||
            !isAssetFromPlaylist ||
            !stream.isDisabledNextVideo() ||
            NextAsset.hasPlayNext(this.asset),
    );

    if (this.asset.get('access')) {
        const access = Object.keys(this.asset.get('access'))[0] || null;
        stream.set('access', access);
    }

    configuration.setStream(stream);

    // cleanup plugins as they maybe not required for new stream
    utils.each(this.plugins, destroyPlugin, this);

    this.addPlugin(new AABPlugin());

    // set start time if chapter is provided (not applicable for playlists)
    if (!hasPlaylist && !isAssetFromPlaylist && configuration.get('chapter')) {
        const chapter = this.asset.getChapter(configuration.get('chapter'));

        if (chapter) {
            stream.set('playAhead', chapter.timeline);
        }
    }

    // set playahead time
    if (!isAssetFromPlaylist && configuration.get('time')) {
        stream.set('playAhead', configuration.get('time'));
    }

    if (configuration.hasRecommended()) {
        this.addPlugin(
            new RecommendedPlugin({
                settings: configuration.getRecommended(),
            }),
        );
    }

    // stream is not available yet, display countdown
    if (
        stream.getTimeToStart() > 0 &&
        !configuration.get('disableCountdownPlugin')
    ) {
        // countdown is disable in preview mode on non live streams
        if (settings.preview !== true || stream.isLive()) {
            this.addPlugin(
                new CountdownPlugin({
                    streamStart: new Date(
                        ServerTime.getTime() + stream.getTimeToStart() * 1000,
                    ),
                }),
            );

            // play stream after countdown ends
            this.on('countdown:end', this.play, this);
        }
    }

    if (this.asset.getAgeLimit() > -1) {
        this.addPlugin(
            new AgeLimitPlugin({
                ageLimit: this.asset.getAgeLimit(),
                settings: configuration.getAgeLimit(),
            }),
        );
    }

    if (configuration.get('showSponsorLabel') && this.asset.isSponsored()) {
        this.addPlugin(
            new SponsorLabelPlugin({
                sponsor: this.asset.getCategory().getTitle(),
            }),
        );
    }

    const metadata = this.asset.get('metadata') || {};

    let experiment;
    let isExperimentEnabled;
    // do not load an experiment feature when the player is in preview mode
    const isPodcastWithExperiment =
        settings.preview !== true &&
        metadata.experimentId &&
        metadata.isPodcast === 'true';

    if (isPodcastWithExperiment) {
        try {
            let experimentRange = JSON.parse(
                sessionStorage.getItem('svpExperimentRange'),
            );
            if (!experimentRange) {
                experimentRange = Math.random() * 100;
                sessionStorage.setItem(
                    'svpExperimentRange',
                    JSON.stringify(experimentRange),
                );
            }
            experiment = {
                id: metadata.experimentId,
                variant: chooseVariant({
                    variants: prepareVariantsForExperiment(metadata),
                    rangeNumber: experimentRange,
                }),
            };

            const userStatusCfg = configuration.get('userStatus');
            let userStatus = {};
            if (typeof userStatusCfg === 'function') {
                userStatus = await userStatusCfg(this.asset);
            } else {
                userStatus = await (userStatusCfg || {});
            }
            if (
                userStatus.loggedIn &&
                !userStatus.hasProduct &&
                experiment.variant.Type === 'Subscription'
            ) {
                isExperimentEnabled = true;
            } else if (userStatus.loggedIn) {
                isExperimentEnabled = false;
            } else {
                isExperimentEnabled = true;
            }

            if (isExperimentEnabled) {
                this.addPlugin(
                    new PodcastExperimentPlugin(experiment, {
                        loginUrl: await configuration.get('loginUrl'),
                        subscriptionUrl:
                            await configuration.get('subscriptionUrl'),
                        articleUrl: await configuration.get('articleUrl'),
                    }),
                );
            }
        } catch (error) {
            logger('ExperimentPlugin').error(
                'An error occur while initializing experiment',
                error,
            );
        }
    }

    /**
     * Disable Pulse tracking for mock/ads only playback
     */

    const pulseConfig = configuration.get('pulse');
    if (pulseConfig?.provider) {
        this.addPlugin(
            new PulseStatsPlugin(
                {
                    ...pulseConfig,
                    tts: {
                        enabled: stream.hasTextSpeech(),
                    },
                },
                experiment,
                isExperimentEnabled,
                stream.isMock(),
            ),
        );
    }

    this.addPlugin(new PausePlugin());

    if (
        (stream.isAudio() || configuration.shouldApplyTextToSpeechSkin()) &&
        configuration.get('nativeLink') !== false
    ) {
        this.addPlugin(new PodcastNativeLinkPlugin());
    }

    // set stream to display error
    this.model.setStream(stream);

    configuration
        .isStreamPlayable()
        .then(() => {
            // restart asset state
            // this.isCompleted = false;
            this.trigger('assetReady');
        })
        .catch(({ code, referenceId }) => {
            this.trigger('assetError', {
                message: statusCode(code),
                code,
                referenceId,
            });

            onError.call(this, {
                type: 'assetError',
                // message is translated in next functions
                message: statusCode(code),
                code,
                referenceId,
            });
        });
}
