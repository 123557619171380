/**
 * @typedef {'pre'|'mid'|'post'} PotOffset
 */
/**
 * @typedef {Record<PotOffset, { offset: PotOffset; tag: string }>} AdSchedule
 */

/**
 * @param {PotOffset} offset
 * @param {AdSchedule} adschedule
 * @returns {null|string}
 */
function getAdPotTag(offset, adschedule) {
    const keys = Object.keys(adschedule);
    let tag;

    keys.forEach(function (key) {
        if (key.indexOf(offset) > -1) {
            tag = adschedule[key].tag;
        }
    });

    return tag;
}

/**
 * @param {PotOffset} potOffset
 * @returns {string|false}
 */
function getTagUrl(potOffset) {
    return function (player) {
        const playlistItem = player.getPlaylist()[0];

        let tag;
        if (playlistItem.adschedule) {
            tag = getAdPotTag(potOffset, playlistItem.adschedule);
        }

        /**
         * @see https://docs.xandr.com/bundle/monetize_monetize-standard/page/topics/target-video-attributes-via-query-string-parameters.html
         */
        if (tag) {
            return tag.replace('vmap', 'ssvmap');
        }
        return false;
    };
}

/**
 * @typedef HomadConfig
 * @property {string} innerWarpper.style (yes, there is a typo)
 * @property {string} container.style
 * @property {string} video.style
 * @property {string} video.style.position
 * @property {*} video.attributes
 * @property {boolean} noClickThroughOnVideoTag
 * @property {*} outstream
 * @property {*} bypass
 * @property {*} tcf_config
 * @property {*} gdpr
 * @property {*} iqfix
 * @property {*} runInsideContainer
 * @property {*} use_videotag_as_container
 * @property {boolean} throwVideoTag
 * @property {*} vpaid
 * @property {*} takeover_ratio
 * @property {*} verticalizr
 * @property {*} ad_fallback
 * @property {*} ad_fallback_config
 * @property {*} skipOffset
 * @property {*} viewableImporession
 * @property {string} clickthrough_target
 * @property {*} welectPlacementTitle (yes, there is a typo)
 * @property {*} clickThroughBox
 * @property {string} globalConfig
 * @property {string} clientConfig
 * @property {boolean} skipable
 * @property {boolean} skipAdOnContextChange
 * @property {boolean} runInsideContainer
 * @property {boolean} adjustAdVolumeToContentPlayer
 * @property {boolean} postrollUseCompleteEvent
 * @property {number} zIndex
 * @property {string|((JWPlayer) => string)} prerollAdTag
 * @property {string|((JWPlayer) => string)} midrollAdTag
 * @property {string|((JWPlayer) => string)} postrollAdTag
 */

/**
 * @type {HomadConfig}
 */
export const homadDefaultConfig = {
    globalConfig:
        'https://s3.amazonaws.com/homad-global-configs.schneevonmorgen.com/global_config.json',
    clientConfig: '/homad-config.json',
    skipable: true,
    skipAdOnContextChange: true,
    runInsideContainer: true,
    adjustAdVolumeToContentPlayer: true,
    postrollUseCompleteEvent: false,
    // by default the ad opens in the same tab. _blank is often blocked
    clickthrough_target: '_target',
    zIndex: 200,
    prerollAdTag: getTagUrl('pre'),
    midrollAdTag: getTagUrl('mid'),
    postrollAdTag: getTagUrl('post'),
};
