import config from 'player/config';
import { scriptLoader } from 'utils/script-loader';

let request = null;

export const youboraSdk = {
    state: 'UNLOADED',
    options: {},
    forceLoad: false,

    load(options = {}, forceLoad = false) {
        this.options = options;
        this.forceLoad = forceLoad;
        if (request === null) {
            this.state = 'LOADING';

            request = scriptLoader(config.cdn.youbora);
            request.then((youbora = window.youbora) => {
                this.state = 'READY';
                window.youbora = youbora;
            });
        }

        return request;
    },

    /**
     * @param {jwplayer.JWPlayer} player
     * @param {*} options
     */
    initialize(player, options = {}) {
        // skip when library is not loaded
        if (this.state !== 'READY' || this.state === 'LOADED') {
            return;
        }

        const { youbora } = window;

        // skip if youbora sdk does not exist
        if (!youbora) {
            return;
        }

        try {
            const plugin = new youbora.Plugin({
                ...options,
                ...this.options,
            });

            plugin.setAdapter(new youbora.adapters.JWPlayer(player));

            this.state = 'LOADED';
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Could not load youbora plugin', e);
        }
    },
};
