import locale from 'player/model/locale';

export function floatingSkinResolver(svpPlayer) {
    svpPlayer.on('adSlotStart', () => {
        const container = svpPlayer.getContainer();
        const floatBarTitle = container.querySelector('.jw-float-bar-title');
        if (floatBarTitle) {
            container.querySelector('.jw-float-bar-title').innerHTML =
                locale.getTranslations().player?.close;
        }
    });
}
