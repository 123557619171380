export const resolveIn = <T>(timeout: number, value: T): Promise<T> => {
  return new Promise<T>((resolve) => {
    setTimeout(() => resolve(value), timeout);
  });
};

export const resolveWithTimeout = async <T>({
  promise,
  fallback,
  timeout,
}: {
  promise: Promise<T>;
  fallback: T;
  timeout: number;
}) => {
  return Promise.race([promise, resolveIn(timeout, fallback)]);
};
