import { getTranslations } from './config/translations';
import jwKeys from './config/jw-keys';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const __BUILD_VERSION__: string;

const VERSION = __BUILD_VERSION__;

const config = {
    version: VERSION,
    player: {
        keys: jwKeys,
    },
    /**
     * SVP API configuration
     */
    api: {
        url: 'https://svp.vg.no/svp/api/v1/',
        cb: false,
        tokenUrl: 'https://svp.vg.no/svp/token/v1/',
        thumbnailsUrl: 'https://svp.vg.no/svp/thumbnails/v1/',
        relatedApiUrl: 'https://related.stream.schibsted.media',
        vmapUrl: 'https://vmap.stream.schibsted.media',

        vendor: null,
        /**
         * API identifier
         */
        identifier: 'svp-player',

        /**
         * Get full endpoint url to requested resource
         */
        getUrl(vendor: string, endpoint: string): string {
            const joiner = endpoint.indexOf('?') > -1 ? '&' : '?';
            let url = `${this.url + vendor + endpoint + joiner}appName=${
                this.identifier
            }`;

            if (this.cb === true) {
                url += `&cb=${Math.random() * 10000}`;
            }

            return url;
        },
    },

    ads: {
        appnexus: {
            vmap: 'https://ib.adnxs.com/vmap',
            ptv: 'https://ib.adnxs.com/ptv',
            cr: 'https://ib.adnxs.com/cr',
            ast: 'https://acdn.adnxs.com/ast/ast.js',
            debug: false,
        },
    },

    cdn: {
        svp: `https://cdn.stream.schibsted.media/player/${VERSION}`,
        player: 'https://cdn.stream.schibsted.media/jw/jwplayer-8.34.5',
        youbora:
            'https://smartplugin.youbora.com/v6/js/adapters/jwplayer/6.8.1/sp.min.js',
        poster: `https://cdn.stream.schibsted.media/player/${VERSION}/img/poster.gif`,
        fonts: {
            Graphik: {
                400: `https://cdn.stream.schibsted.media/player/fonts/Graphik.woff`,
                500: `https://cdn.stream.schibsted.media/player/fonts/Graphik-Medium.woff`,
                600: `https://cdn.stream.schibsted.media/player/fonts/Graphik-Semibold.woff`,
            },
        },
    },

    time: 'https://time.akamai.com',

    images: {
        sizes: {
            poster: {
                autoHeight: [
                    380, 480, 580, 680, 780, 800, 980, 1080, 1440, 2048,
                ],
                autoWidth: [452, 613, 740, 900, 1080, 1440],
            },
        },
    },

    liveVideoPreview: 'livepreview',
    videoPreview: {
        default: 'preview_360p',
        vgtv: 'preview_vivi_category',
    },

    /**
     * Default skins for providers
     */
    skins: {
        default: {
            url: `https://cdn.stream.schibsted.media/player/${VERSION}/css/player.css`,
            name: 'svp-default jw-skin-seven',
        },
    },

    about: {
        vgtv: {
            link: 'http://www.vgtv.no/',
            text: 'VGTV',
        },
    },

    sharing: {
        global: {
            sites: ['facebook', 'twitter'],
        },

        vgtv: {
            link: 'http://www.vgtv.no/#!/MEDIAID',
            code: '<iframe width="560" height="315" src="https://www.vgtv.no/embed/?id=MEDIAID" frameborder="0" allowfullscreen="true"></iframe>',
        },
    },

    domains: {
        blacklist: {
            global: [],
            ab: ['*avpixlat.info', '*exponerat.net'],
        },

        whitelist: {
            global: [
                '*localhost',
                '*schibsted.io',
                '*schibsted.media',
                '*schibsted.ws',
                '*schibsted.se',
                '*smp.no',
                '*plan3dev.se',
                '*plan3.se',
                '*vg.no',
                '*vgtv.no',
                '*vgnett.no',
                '*aftonbladet.se',
                '*aftenposten.no',
                '*ap.no',
                '*sa.no',
                '*aftenbladet.no',
                '*vglive.no',
                '*e24.no',

                // brandstudio cms
                '*vev.design',
                // stream cluster
                '*pubops.net',

                '*adresseavisen.no',
                '*polarismedia.no',
                '*adressa.no',
                '*bt.no',
                '*fvn.no',

                '*htmlinstant.com',
                '*ht.no',
                '*rbnett.no',
                '*itromso.no',
                '*folkebladet.no',
                '*framtidinord.no',
                '*osloby.no',
                '*av-avis.no',
                '*medianorge.no',
                '*herokuapp.com',
                '*bergenstidende.no',
                '*bonansa.no',
                '*sysla.no',
                '*bygdanytt.no',
                '*vestnytt.no',
                '*strilen.no',
                '*snd.no',
                '*fedrelandsvennen.no',
                '*lister24.no',
                '*l-a.no',
                '*lindesnesfyr.no',

                // Polaris related newspapers
                // addressa.no
                '*adressa.no',
                '*avisa-st.no',
                '*banett.no',
                '*fosna-folket.no',
                '*hitra-froya.no',
                '*innherred.no',
                '*mb.no',
                '*bladet.no',
                '*opdalingen.no',
                '*tronderbladet.no',
                // rbnett
                '*rbnett.no',
                '*andalsnes-avis.no',
                '*driva.no',
                // smp newspaper
                '*smp.no',
                '*dolen.no',
                '*fjordabladet.no',
                '*fjt.no',
                '*fjordingen.no',
                '*fjuken.no',
                '*heroynytt.no',
                '*morenytt.no',
                '*sunnmoringen.no',
                '*vigga.no',
                '*vikebladet.no',
                // itromso.no
                '*itromso.no',
                '*framtidinord.no',
                // ht.no
                '*ht.no',
                '*vol.no',
                // t-a.no
                '*t-a.no',
                '*namdalsavisa.no',
                // other polaris newspapers
                '*altaposten.no',
                '*folkebladet.no',
                '*svd.se',
                // marketplaces
                '*finn.no',
                '*familieklubben.no',
                '*tek.no',

                // testing env
                '*mylocal.com',
            ],
        },

        preview: {
            global: [
                '*vgnett.no',
                '*svp.schibsted.io',
                '*cms.schibsted.media',
                '*localhost',
                '*pubops.net',
                '*stream.schibsted.media',
            ],
        },
    },

    vendors: {
        se: ['ab', 'svd'],
    },

    translations: getTranslations,
};

export default config;
