import Eventable from 'utils/eventable';
import utils from 'utils/utils';
import session from './session';
import { isCustomIntervalFalsy } from './helpers';

/**
 * Parse SVP Player events and tailor data to pulse requirements
 *
 * Available events: Play, Pause, Stop, Watch
 */
class PlaybackEvents extends Eventable {
    constructor(player, options) {
        super();
        this.player = player;
        this.watchData = {
            time: 0,
            duration: -1,
        };

        this.previousTime = -1;

        this.session = this.createSession();
        this.beforeStreamStart = true;
        this.beforeUnload = [
            'onpagehide' in window.self ? 'pagehide' : 'beforeunload',
            this.onBeforeUnload.bind(this),
            true,
        ];
        this.playMethod = null;

        this.vodInterval = options?.vodInterval;
        this.liveInterval = options?.liveInterval;

        this.listenTo(this.player, 'playlistItem', this.onPlaylistItem, this);
        this.listenTo(this.player, 'assetPlay', this.onAssetPlay, this);
        this.listenTo(this.player, 'pause', this.onPause, this);
        this.listenTo(this.player, 'play', this.onPlay, this);
        this.listenTo(this.player, 'playNext', this.onPlayNext, this);

        if (this.stream.isLive()) {
            this.listenTo(this.player, 'time', this.onLiveTime, this);
        } else {
            this.listenTo(this.player, 'time', this.onTime, this);
        }

        this.listenTo(
            this.player,
            'RecommendedPlugin:nextAssetPlay',
            (asset, mode, opts) => {
                let playbackSource =
                    mode === 'auto' ? 'autoplayNext' : 'manualNext';
                if (opts.source && opts.source !== 'byId') {
                    playbackSource += `-${opts.source}`;
                }
                return this.onRecommendedPlay(playbackSource);
            },
        );

        this.listenTo(this.player, 'nextAutoAdvance', () =>
            this.onRecommendedPlay('autoplayNext-playlist'),
        );

        this.listenTo(this.player, 'nextClick', () =>
            this.onRecommendedPlay('manualNext-playlist'),
        );

        this.listenTo(this.player, 'relatedReady', () => {
            if (!this.jwRelatedPlugin) {
                return;
            }
            this.jwRelatedPlugin.on('feedClick', () => {
                this.beforeStreamStart = false;
                this.onComplete();
                this.onRecommendedPlay('manualNext-playlist');
            });
        });

        this.listenTo(this.player, 'playlistComplete', () => {
            this.listenToOnce(this.player, 'displayClick', () =>
                this.onRecommendedPlay('manualNext-playlist'),
            );
        });

        this.listenTo(this.player, 'RecommendedPlugin:playRecommended', () => {
            this.onRecommendedPlay('manualNext-grid');
        });

        this.listenToOnce(this.player, 'play', ({ playReason }) => {
            this.playMethod = playReason;
        });

        window.addEventListener(...this.beforeUnload);
    }

    get eventData() {
        const data = {
            playMethod: this.playMethod,
        };
        const { previousAssetId } = this.session;

        if (previousAssetId) {
            data.previousAssetId = previousAssetId;
            data.playbackSource = this.session.playbackSource;
        }

        data.duration = this.watchData.duration;

        try {
            data.position = Math.floor(this.player.getCurrentTime());
        } catch (e) {
            // could not parse time
            // do not set any value in this case
        }

        return data;
    }

    get stream() {
        try {
            return this.player.config.stream || {};
        } catch (e) {
            return {};
        }
    }

    get jw() {
        try {
            return this.player.model.player || {};
        } catch (e) {
            return {};
        }
    }

    get jwRelatedPlugin() {
        try {
            return this.jw.getPlugin('related') || null;
        } catch (e) {
            return null;
        }
    }

    getPosition() {
        try {
            return Math.floor(this.player.getCurrentTime());
        } catch (e) {
            return 0;
        }
    }

    createSession() {
        // node is unique player identifier
        const node = this.player.config.get('node');
        const id = node.id || node;

        /**
         * Initialize session object to keep reference
         */
        if (!session[id]) {
            session[id] = {};
        }

        return session[id];
    }

    trackTimeEvent({ time, defaultInterval = 1, customInterval }) {
        const eventInterval = utils.isNumber(customInterval)
            ? customInterval
            : defaultInterval;

        // track event data with one second interval
        if (time % 1 === 0 && this.previousTime !== time) {
            this.watchData.duration += 1;
            this.previousTime = time;
        }

        if (time % eventInterval === 0 && this.watchData.time !== time) {
            this.watchData.time = time;

            // skip when custom interval is turned off
            if (isCustomIntervalFalsy(customInterval)) {
                return;
            }

            this.trigger(
                'Watch',
                Object.assign(this.eventData, {
                    position: time,
                }),
            );
        }
    }

    onBeforeUnload() {
        if (this.beforeStreamStart === false) {
            this.onComplete();
        }
    }

    onPlaylistItem() {
        this.listenToOnce(
            this.player,
            'complete nextClick',
            this.onComplete,
            this,
        );
        const track = this.trigger.bind(
            this,
            'Load',
            Object.assign(this.eventData, {
                position: this.stream.getPlaybackTime('begin') || 0,
                duration: 0,
            }),
        );

        /**
         * Only first playback has to wait for viewable event
         * Playnext/grid will work without it
         */
        if (this.session.wasViewed === true) {
            track();
        } else {
            // wait for player to be viewable
            this.listenTo(this.player.model.player, 'viewable', (data) => {
                if (data.viewable) {
                    track();
                    this.stopListening(this.player.model.player, 'viewable');
                    this.session.wasViewed = true;
                }
            });
        }

        this.previousAssetId = null;
    }

    onAssetPlay() {
        this.beforeStreamStart = false;

        return this.trigger(
            'Play',
            Object.assign(this.eventData, {
                start: true,
                duration: 0,
            }),
        );
    }

    onPause() {
        this.trigger('Pause', this.eventData);
    }

    onPlay() {
        // play can't be triggered before assetPlay
        if (!this.beforeStreamStart) {
            this.trigger('Play', this.eventData);
        }
    }

    /**
     * Intervals for VOD are defined as follow
     *
     * every 5s - during the first 10 seconds of content playback (no matter of where playback has started)
     * every 10s - for the range (10-60s) of content playback, after seeking intervals should be triggered in same time
     * every 30s - for the rest
     * seek 42, then first trigger should be in 50
     */
    onTime(eventTime) {
        const time = Math.floor(eventTime);
        let eventInterval;

        if (eventTime <= 10) {
            eventInterval = 5;
        } else if (eventTime <= 120) {
            eventInterval = 10;
        } else {
            eventInterval = 30;
        }
        this.trackTimeEvent({
            time,
            defaultInterval: eventInterval,
            customInterval: this.vodInterval,
        });
    }

    /**
     * Track live events by checking time from flight time to current date
     * DVR seek is also included in calculations
     */
    onLiveTime() {
        // after playback stars time to start will be negative
        // add DVR shift from playback
        const eventTime = Math.floor(
            this.stream.getTimeToStart() * -1 + this.jw.getPosition(),
        );
        // constant tracking interval
        this.trackTimeEvent({
            time: eventTime,
            defaultInterval: 30,
            customInterval: this.liveInterval,
        });
    }

    onComplete(reason) {
        if (reason === 'manualNext') {
            this.onRecommendedPlay('manualNext');
        }

        /**
         * Track stop event only when playback has started
         */
        if (this.beforeStreamStart === false) {
            this.trigger('Stop', this.eventData);
            // store asset id in memory to attach it to play next video
            this.session.previousAssetId = this.stream.getId();
        }
        this.beforeStreamStart = true;
    }

    /**
     * @param {Number} id
     * @param {Object} [options]
     * @param {Object} [options.pulse]
     * @param {String} [options.source] - A source that will be added as a playback source for pulse metrics
     */
    onPlayNext(id, options) {
        if (options && options.pulse) {
            Object.assign(this.session, options.pulse);
        }
    }

    onRecommendedPlay(playbackSource) {
        this.session.playbackSource = playbackSource;
    }

    destroy() {
        window.removeEventListener(...this.beforeUnload);
        this.stopListening();
    }
}

export default PlaybackEvents;
