import type { Asset } from '@schibsted-svp/svp-api-types';
import { ConsentManager } from '@vgtv/consent-manager';

import {
  getDeviceType,
  isAndroid,
  isHermesApp,
  isIOS,
} from '../../../utils/device';
import {
  getAssetEmbeddedTags,
  getAssetMetadata,
} from '../../../utils/player/asset';
import { getTcfConsentKeywords } from '../getTcfConsentKeywords';
import { getAdConsentKeywords } from '../getAdPermission';
import { prepareXandrKeywords } from '../prepareKeywords';

import { formatTagValue } from './formatTagValue';
import { getAspectRatioCompound } from './getAspectRatioCompound';
import { getGlimrTags } from './glimr';

function getAppTypeKeyword(): Record<string, string> {
  if (!isHermesApp || (!isIOS && !isAndroid)) {
    return {};
  }
  const deviceType = getDeviceType();

  return {
    'no-sno-apptype': `${isIOS ? 'ios' : 'android'}_${deviceType}_hybrid`,
  } as const;
}

function getAssetKeywords(asset: Asset, dynamicAR = false) {
  const categoryTitle = asset.category.title;
  const { contentType } = getAssetMetadata(asset);
  const keywords: Record<
    string,
    string | (string | undefined)[] | boolean | undefined
  > = {
    'aa-sch-content_type': [
      `${formatTagValue(categoryTitle)}_${contentType}`,
      formatTagValue(contentType),
    ],
    'no-sno-news-sponsors': getAssetEmbeddedTags(asset)
      .map(({ tag }) => tag)
      .map(formatTagValue),
  };

  const { width, height } = getAspectRatioCompound(asset) ?? {};
  if (width && height) {
    keywords['aa-sch-instream_aspectratio'] = `${width}-${height}`;
  }

  if (dynamicAR) {
    keywords['aa-sch-instream_dynamicenabled'] = dynamicAR;
  }

  return keywords;
}

function getGlimrKeywords() {
  const geoTags = getGlimrTags();

  return geoTags
    ? {
        'no-sno-geo': geoTags,
        'no-sno-weather': geoTags,
      }
    : {};
}

export const getGlobalKeywords = async (
  keywords: Partial<
    Record<
      | 'countryCode'
      | 'supplyType'
      | 'publisherName'
      | 'pageType'
      | 'publisherGroup'
      | 'newsSection',
      string | string[]
    >
  >,
  asset: Asset,
  consentManager?: ConsentManager,
  dynamicAR: boolean = false
) => {
  return prepareXandrKeywords(
    Object.assign(
      {
        'aa-sch-country_code': keywords.countryCode,
        'aa-sch-supply_type': keywords.supplyType,
        'aa-sch-publisher': keywords.publisherName,
        'aa-sch-page_type': keywords.pageType,
        'no-sno-publishergroup': keywords.publisherGroup,
        'no-sno-news-section': keywords.newsSection,
      },
      getAssetKeywords(asset, dynamicAR),
      getGlimrKeywords(),
      getAppTypeKeyword(),
      ...(await Promise.all([
        getTcfConsentKeywords(),
        getAdConsentKeywords(consentManager),
      ]))
    )
  );
};
