import { Provider } from '@vgtv/api-client';
import { Category, ConsentManager } from '@vgtv/consent-manager';

const getAllowlist = (provider: Provider): Category[] => {
  if (provider === 'vgtv') {
    return ['CMP:advertising', 'CMP:personalisation'];
  }

  return ['CMP:advertising'];
};

export const getConsentManager = (provider: Provider) => {
  return new ConsentManager({ allowlist: getAllowlist(provider) });
};
