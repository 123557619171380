var idCounter = 0;

// we do not include underscore as it is our hard dependency
// eslint-disable-next-line @typescript-eslint/naming-convention
const { _ } = jwplayer;
/**
 * @param {string|number} key
 * @return {(obj: Object|Array|null) => *}
 */
const property = function (key) {
    return function (obj) {
        return obj == null ? undefined : obj[key];
    };
};
const MAX_ARRAY_INDEX = 2 ** 53 - 1;
const getLength = property('length');
const isArrayLike = function (collection) {
    var length = getLength(collection);
    return (
        typeof length === 'number' && length >= 0 && length <= MAX_ARRAY_INDEX
    );
};

const isArguments = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Arguments]';
};

/**
 * @param {*} obj
 * @returns {boolean}
 */
export function isEmpty(obj) {
    if (obj == null) return true;
    if (
        isArrayLike(obj) &&
        (Array.isArray(obj) || typeof obj === 'string' || isArguments(obj))
    )
        return obj.length === 0;
    return Object.keys(obj).length === 0;
}

/**
 * @param {string} prefix
 * @returns {string}
 */
export function uniqueId(prefix) {
    var id = `${++idCounter}`;
    return prefix ? prefix + id : id;
}

export const size = function (obj) {
    if (obj == null) {
        return 0;
    }
    return obj.length === +obj.length ? obj.length : Object.keys(obj).length;
};

export default _;
