import logger from 'utils/logger';
import locale from 'player/model/locale';
import PluginModel from './model';

const SVP_LOGIN_OVERLAY = 'svp-login-overlay';

/**
 * @param {String} actual
 * @param {String} expected
 */
function assertUppercased(actual, expected) {
    return actual && actual.toUpperCase() === expected;
}

export function chooseVariant({ variants = {}, rangeNumber }) {
    const winner = Object.values(variants)
        .reduce((prev, { id, Percentage }, index) => {
            const percentage = Number(Percentage);
            if (index) {
                return prev.concat({
                    id,
                    min: prev[index - 1].max,
                    max: prev[index - 1].max + percentage,
                });
            }
            return [{ id, min: 0, max: percentage }];
        }, [])
        .find(({ min, max }) => rangeNumber > min && rangeNumber < max);
    return winner && variants[winner.id];
}

export function prepareVariantsForExperiment(metadata = {}) {
    const variantIds = metadata.variants && metadata.variants.split(',');
    const experimentMetadataKeys = Object.keys(metadata).filter((key) =>
        key.match(/^\d+variant/g),
    );

    return experimentMetadataKeys.reduce((prev, current) => {
        const [, id, , key] = /^(\d+)(variant)(\D+)/g.exec(current);
        if (variantIds.includes(id)) {
            prev[id] = Object.assign(prev[id] || {}, {
                id,
                [key]: metadata[current],
            });
        }
        return prev;
    }, {});
}

function isLoginVariantType(variant = {}) {
    return assertUppercased(variant.Type, 'LOGIN');
}

function isSubscriptionVariantType(variant = {}) {
    return assertUppercased(variant.Type, 'SUBSCRIPTION');
}

const getUiElementId = (provider, articleUrl, assetId) =>
    `sdrn:${provider}:article:${articleUrl}:element:${assetId}`;

class PodcastExperimentPlugin extends PluginModel {
    constructor({ id, variant }, { loginUrl, subscriptionUrl, articleUrl }) {
        super({ id, variant });
        this.id = id;
        this.variant = variant;
        this.loginUrl = loginUrl;
        this.subscriptionUrl = subscriptionUrl;
        this.articleUrl = articleUrl;
        this.pluginName = 'PodcastExperimentPlugin';
    }

    getName() {
        return this.pluginName;
    }

    setup() {
        logger('ExperimentPlugin').log('setup', this);
        this.listenToOnce(this.player, 'viewable', this.render, this);
    }

    /**
     * @returns {string}
     */
    getButtonText() {
        const { variant = {} } = this;
        const translate = locale.translate('player');

        if (isLoginVariantType(variant)) {
            return translate.loginToListen;
        }
        if (isSubscriptionVariantType(variant)) {
            return translate.subscribeToListen;
        }
        return translate.listen;
    }

    render() {
        const { variant, loginUrl, subscriptionUrl, articleUrl, player } = this;
        const { id, provider } = player.asset.attributes;
        const jwPlayer = player.model.player;
        const container = jwPlayer.getContainer();
        const buttonText = this.getButtonText();
        const uiElementId = getUiElementId(
            provider,
            encodeURIComponent(articleUrl),
            id,
        );
        const pulseStatsPlugin = player.plugins.PulseStats;

        function trackEngagement() {
            if (pulseStatsPlugin) {
                pulseStatsPlugin.track('Engagement podcast experiment', {
                    provider: { productType: 'Web' },
                    type: 'Engagement',
                    object: {
                        '@id': uiElementId,
                        type: 'UIElement',
                        name: buttonText,
                    },
                });
            }
        }

        if (pulseStatsPlugin) {
            pulseStatsPlugin.playbackEvents.once('Load', () => {
                pulseStatsPlugin.track('View podcast experiment', {
                    provider: { productType: 'Web' },
                    type: 'View',
                    object: {
                        '@id': uiElementId,
                        type: 'UIElement',
                        name: buttonText,
                    },
                });
            });
        }

        if (!variant) {
            player.once('initialPlay', () => {
                trackEngagement();
            });
            return;
        }

        const redirectUrl = isLoginVariantType(variant)
            ? loginUrl
            : subscriptionUrl;

        const linkAnchor = document.createElement('a');
        const linkSpan = document.createElement('span');
        linkSpan.classList.add('svp-login-button');
        linkSpan.appendChild(document.createTextNode(buttonText));
        linkAnchor.appendChild(linkSpan);
        linkAnchor.classList.add(SVP_LOGIN_OVERLAY);
        linkAnchor.setAttribute('href', redirectUrl);

        container.appendChild(linkAnchor);
    }

    destroy() {
        this.stopListening(this.player);
        const jwPlayer = this.player.model.player;
        const container = jwPlayer.getContainer();

        const experimentOverlay = container.querySelector(
            `.${SVP_LOGIN_OVERLAY}`,
        );
        if (experimentOverlay) {
            experimentOverlay.parentNode.removeChild(experimentOverlay);
        }

        logger('ExperimentPlugin').log('destroy', this);
    }
}

export default PodcastExperimentPlugin;
