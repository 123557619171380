/* eslint-disable no-param-reassign */
import { getById } from 'player/services/api/asset';
import utils from 'utils/utils';
import Model from 'utils/model';
import { cloneDeep } from 'utils/clone-deep';
import Category from './category';

/**
 * @typedef AssetChapter
 * @property {string|null} description
 * @property {boolean} featured
 * @property {number} timeline
 * @property {string} title
 */

function unifyTimestamp(time) {
    if (!time) {
        return null;
    }

    if (time.toString().length === 10) {
        return time * 1000;
    }

    return time;
}

const Asset = function (options) {
    this.attributes = {
        id: 0,
    };

    this.initialize(options);
};

Asset.prototype = {
    /**
     * @param {SvpAsset} data
     */
    initialize(data) {
        utils.extend(this.attributes, this.parse(data));
    },

    /**
     * @returns {import('@schibsted-svp/svp-api-types').Asset|undefined}
     */
    getRawAsset() {
        return this.rawAsset;
    },

    /**
     * @param {SvpAsset} data
     * @returns {ParsedAsset}
     */
    parse(data) {
        this.rawAsset = cloneDeep(data || {});
        const result = cloneDeep(data || {});

        // rewrite additional fields
        if (result.additional) {
            utils.each(result.additional, (value, key) => {
                // featured chapters are fetched on the lists
                // merge them
                if (key === 'featuredChapters') {
                    key = 'chapters';
                }

                if (key === 'subtitles') {
                    key = 'captions';
                }

                if (typeof result[key] === 'undefined') {
                    result[key] = value;
                }
            });

            if (result.additional.settings) {
                if (result.additional.settings.ageLimit !== undefined) {
                    result.ageLimit = result.additional.settings.ageLimit;
                }
            }
        }

        // multiply timestamps by 1000 to use ms instead of s
        if (result.flightTimes) {
            result.flightTimes = {
                start: result.flightTimes.start
                    ? unifyTimestamp(result.flightTimes.start)
                    : null,
                end: result.flightTimes.end
                    ? unifyTimestamp(result.flightTimes.end)
                    : null,
            };
        }

        if (result.category && !(result.category instanceof Category)) {
            result.category = new Category(result.category);
        }

        if (result.chapters) {
            utils.each(result.chapters, (chapter, index) => {
                chapter.time = utils.string.getTimeFormatted(chapter.timeline);
                chapter.index = index + 1;
            });
        }

        // next asset is optional, fill with basic object to avoid undefined errors
        if (!result.nextAsset) {
            result.nextAsset = {};
        }

        result.tags = result.tags || [];

        if (result._embedded && result._embedded.tags) {
            result.tags = result._embedded.tags;
        }

        result.playlist = [];

        if (result._embedded && result._embedded.playlist) {
            result.playlist = result._embedded.playlist;
        }

        return result;
    },

    /**
     * @returns {Promise<import('@schibsted-svp/svp-api-types').Asset>}
     */
    async fetch() {
        const asset = await getById({
            vendor: this.getVendor(),
            assetId: this.getId(),
        });
        Object.assign(this.attributes, this.parse(asset));
    },

    /**
     * @returns {string}
     */
    getVendor() {
        return this.get('vendor');
    },

    /**
     * @returns {number}
     */
    getId() {
        return this.get('id');
    },

    /**
     * @returns {'live'|'wasLive'|'vod']}
     */
    getStreamType() {
        return this.get('streamType');
    },

    /**
     * @returns {number|undefined}
     */
    getNextAssetId() {
        return this.get('nextAsset').id;
    },

    destroy() {
        // in case of any cleanup
    },

    /**
     * @returns {number}
     */
    getAgeLimit() {
        return utils.isNumber(this.get('ageLimit')) ? this.get('ageLimit') : -1;
    },

    /**
     * @returns {boolean}
     */
    isSponsored() {
        const metadata = this.get('metadata');

        let isSponsored = false;

        /**
         * SVP API metadata values are always strings. There can be different structures serialized to string though.
         */
        if (metadata && metadata.sponsored) {
            try {
                isSponsored =
                    JSON.parse(metadata.sponsored.toLowerCase()) === true;
            } catch (e) {
                // All the cases that fail to be json decoded are treated as false
            }
        }

        return isSponsored;
    },

    /**
     * Get chapter by its index (numbered from 1)
     *
     * @param chapterIndex
     * @returns {*} or undefined if not found
     */
    getChapter(chapterIndex) {
        return this.getChapters()[chapterIndex - 1];
    },

    /**
     * Get chapters for single stream
     *
     * @returns {AssetChapter[]}
     */
    getChapters() {
        return this.get('chapters') || [];
    },

    /**
     * @returns {string}
     */
    getThumbnail() {
        return this.get('images').main;
    },

    /**
     * @returns {string}
     */
    getTitle() {
        return this.get('title');
    },

    /**
     * @returns {Category}
     */
    getCategory() {
        return this.get('category');
    },

    /**
     * @returns {boolean}
     */
    isAudio() {
        return this.get('assetType') === 'audio';
    },
};

utils.extend(Asset.prototype, Model);

export default Asset;
