import type { Player } from '@schibsted-svp/web-player';
import { Provider } from '@vgtv/api-client';

export const getConfigProvider = (player: Player): Provider => {
  return player.getRawConfig().vendor as Provider;
};

export const getAssetProvider = (player: Player): Provider | 'brandstudio' => {
  return player.getRawAsset()?.provider || getConfigProvider(player);
};

export const getJWPlayerProvider = (player: Player) => {
  return player.getJWPlayer()?.getProvider()?.name;
};
