// see https://github.schibsted.io/spt-dataanalytics/event-sdk-js/blob/master/docs/docs/ADID.md
const escaped = encodeURIComponent('_pulse2data').replace(/[-.+*]/g, '\\$&');

const regexp = new RegExp(
  `(?:(?:^|.*;)\\s*${escaped}\\s*\\=\\s*([^;]*).*$)|^.*$`
);

export default function getUserId() {
  const cookie = decodeURIComponent(document.cookie.replace(regexp, '$1'));

  if (!cookie) {
    return undefined;
  }

  return cookie.split(',')[9];
}
