import utils from 'utils/utils';
import videoPreviewHelper from 'utils/video-preview';
import locale from '../model/locale';

/**
 * @param {Object} playlistItem
 * @param {number} playlistItem.id
 */
function playNextFromPlaylist(playlistItem) {
    const { asset } = this;
    const { masterAsset } = this.model;

    if (!asset || !masterAsset || asset.getId() === playlistItem.id) {
        return;
    }

    const nextAssetData =
        masterAsset.getId() === playlistItem.id
            ? utils.extend({}, masterAsset.attributes)
            : masterAsset
                  .get('playlist')
                  .find((assetData) => assetData.id === playlistItem.id);

    if (!nextAssetData) {
        return;
    }

    this.setAsset(nextAssetData);
}

/**
 * Player initialized and single playlistItem is laoded
 * @param {Object} playlistItem
 */
export function onPlaylistItem(playlistItem) {
    const container = this.getContainer();
    // add class for live streams

    utils.removeClass(container, 'svp-player-live');

    if (this.model.getStream().isLive()) {
        utils.addClass(container, 'svp-player-live');

        const icon = container.getElementsByClassName('jw-icon-display')[0];

        if (icon) {
            // add translation for live button via data-label
            icon.setAttribute('data-label', locale.translate('Live button'));
        }
    }

    const videoElement = container.querySelector('video.jw-video');

    if (videoElement) {
        videoElement.loop = this.config.get('repeat');
    }

    if (
        !this.model.config.shouldApplyTextToSpeechSkin() &&
        !this.model.stream.isAudio()
    ) {
        videoPreviewHelper.addVideoPreviewElement.call(this);
    }

    playNextFromPlaylist.call(this, playlistItem);

    this.trigger('playlistItem');
}
