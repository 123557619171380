import config from 'player/config';

/**
 * @param {string} vendor
 * @returns {(config: JWConfig) => JWConfig}
 * @template JWConfig
 */
export default function (vendor) {
    // additional config options required for playback
    return function (jwConfig) {
        const { keys } = config.player;

        jwConfig.key = keys[vendor] || keys.default;

        return jwConfig;
    };
}
