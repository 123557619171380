/**
 * Server time helper required for syncing browser time with stream vendors servers (SMP Stream)
 */
const ServerTime = {
    time: null,
    responseTime: null,
    fetched: false,

    /**
     * @param {string} url
     * @returns {Promise<void>}
     */
    fetch: function (url) {
        return new Promise((resolve) => {
            /**
             * @param {number} time
             */
            const resolveRequest = (time) => {
                this.time = time;
                this.responseTime = Date.now();
                resolve();
            };

            fetch(url)
                .then((result) => result.text())
                .then(
                    (text) => {
                        const serverTime = parseInt(text, 10);

                        if (Number.isNaN(Number(serverTime))) {
                            // set current microtime if server will return value different than int
                            resolveRequest(Date.now());
                        } else {
                            // adjust to miliseconds
                            resolveRequest(serverTime * 1000);
                            ServerTime.fetched = true;
                        }
                    },
                    function () {
                        resolveRequest(Date.now());
                    },
                );
        });
    },

    /**
     * @returns {number}
     */
    getTime: function () {
        return this.time + (Date.now() - this.responseTime);
    },
};

export default ServerTime;
