import type { Config } from '@schibsted-svp/web-player';
import { Provider } from '@vgtv/api-client';
import { getCachedOrDefaultConsentsForPulse } from '@vgtv/consent-manager';

import type { AdnContext } from '../types/global';
import { resolveWithTimeout } from '../utils/async';
import { getAssetAspectRatio } from '../utils/player/asset';

import { getPortalHostname } from './helpers/getPortalHostname';

export type PulseDecorator = Exclude<
  Exclude<Config['pulse'], undefined>['decorator'],
  undefined
>;

const getSubscriptionName = (provider: Provider, accesses: string[]) => {
  if (provider === Provider.AB) {
    if (accesses.includes('plus')) {
      return 'plus';
    }
  } else if (provider === Provider.VGTV) {
    if (accesses.includes('sport')) {
      return 'VG+ Sport';
    }

    if (accesses.includes('plus')) {
      return 'VG+';
    }
  }

  return 'No';
};

const DEFAULT_TIMEOUT_IN_MILLISECONDS = 2500;

export const getPulseActor = async (
  provider: Provider,
  getUserIdAsync: () => Promise<number | null>,
  getUserAccesses: () => Promise<string[]>
) => {
  const [id, accesses] = await Promise.all([
    resolveWithTimeout({
      promise: getUserIdAsync(),
      fallback: null,
      timeout: DEFAULT_TIMEOUT_IN_MILLISECONDS,
    }),
    resolveWithTimeout({
      promise: getUserAccesses(),
      fallback: [],
      timeout: DEFAULT_TIMEOUT_IN_MILLISECONDS,
    }),
  ]);

  // Pulse CIS sync expects actor to return an object with id,
  // even if the user is logged out
  if (!id) {
    return {
      id: undefined,
    };
  }

  const realm = provider === Provider.VGTV ? 'spid.no' : 'schibsted.com';

  return {
    id,
    realm,
    subscriptionName: getSubscriptionName(provider, accesses),
  };
};

export const createPulseDecorator =
  (
    provider: Provider,
    forceAttachActor: boolean,
    adnContext: AdnContext,
    getUserIdAsync: () => Promise<number | null>,
    getUserAccesses: () => Promise<string[]>,
    originalDecorator?: PulseDecorator
  ): PulseDecorator =>
  (eventData, assetModel) => {
    const { rawAsset: asset } = assetModel;

    /* eslint-disable no-param-reassign */
    if (forceAttachActor) {
      eventData.actor = getPulseActor(
        provider,
        getUserIdAsync,
        getUserAccesses
      );
    }

    const object = eventData.object as Record<string, unknown>;

    // overwrite the object.embed field:
    // embed = true for everything except for SViP hostname (excluding /embed)
    object.embed = !(
      window.location.hostname === getPortalHostname(provider) &&
      !/\/embed\/?/.test(window.location.pathname)
    );

    const { pcFormat } = adnContext.adnPlugin ?? {};
    if (pcFormat) {
      // To satisfy both schema and documentation
      eventData.pcFormat = pcFormat;
      object.pcFormat = pcFormat;
    }

    const custom = (object['spt:custom'] || {}) as Record<
      string,
      string | number | boolean
    >;
    object['spt:custom'] = custom;

    custom['spt:assetAspectRatio'] = getAssetAspectRatio(asset);
    if (asset.series) {
      custom['spt:assetEpisodeNumber'] = asset.series.episodeNumber;
      custom['spt:assetSeasonNumber'] = asset.series.seasonNumber;
    }

    if (eventData.intent === 'Stop') {
      const data = eventData as {
        position: number;
        object: { duration: number };
      };
      custom['spt:completionRate'] = Math.round(
        (data.position * 100) / data.object.duration
      );
    }

    const consents = getCachedOrDefaultConsentsForPulse();
    if (consents) {
      eventData.consents = consents;
    }

    if (originalDecorator) {
      return originalDecorator(eventData, assetModel);
    }
    return eventData;
  };
