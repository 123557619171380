/**
 * @param {PlayerModel} player
 * @param {Homad} homad
 * @param {PulseStatsPlugin} pulse
 */
export function homadAdTracker(player, homad, pulse) {
    let adSequencePosition = 0;
    let adSequenceCount = 0;
    let adIds = [];

    if (pulse) {
        const resetSequence = () => {
            adSequencePosition = 0;
            adSequenceCount = 0;
        };

        /**
         * @param {Object} data Homad event details
         * @param {{ adID: string }[]} data.adIds
         * @param {'preroll'|'midroll'|'postroll'} data.state
         * @param {number} data.duration
         * @param {number} data.currentTime
         */
        const onHomadAdStart = (data) => {
            const { adEvents } = pulse;

            if (!adSequencePosition) {
                adSequenceCount = data.adIds.length;
            }

            adSequencePosition += 1;
            adIds = [...data.adIds];
            const { adID } = adIds[adIds.length - 1];

            Object.assign(adEvents.adData, {
                type: data.state,
                // duration in milliseconds
                duration: data.duration * 1000,
                // Both Homad and JWplayer can't seem to poroperly extract adId value from VAST response
                // This bug prevents Watch and Complete tracking events from being dispatched
                // We're going to use homadImpressionAdId until this is fixed for Homad
                id: adID || data.homadImpressionAdId,
                sequence: adSequencePosition,
                podcount: adSequenceCount,
                position: 0,
            });

            // Simulate 'adEvents.onAdMeta' call
            if (!adEvents.adData.adSequenceDuration) {
                adEvents.adData.adSequenceDuration = Array(adSequenceCount);
            }

            if (data.state === 'preroll') {
                // duration in seconds
                adEvents.adData.adSequenceDuration[adSequencePosition - 1] =
                    data.duration;
            }

            // Trigger Pulse MediaAd:Play event
            adEvents.onAdPlay();
        };

        const onHomadAdPause = () => {
            pulse.adEvents.onAdPause();
        };

        const onHomadAdTime = (data) => {
            pulse.adEvents.onAdProgress(data.currentTime);
        };

        // MediaAd.Watch
        homad.on('adTime', onHomadAdTime);

        // MediaAd.Play
        homad.on('adStart', onHomadAdStart);

        // MediaAd.Pause
        homad.on('adPause', onHomadAdPause);

        // MediaAd.Stop
        homad.on('adComplete', pulse.adEvents.onAdFinished, pulse.adEvents);

        player.on('playlistItem', resetSequence);
        homad.on('adRequest', resetSequence);
    }
}
