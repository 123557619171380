import utils from 'utils/utils';
import appnexus from 'player/playback/ads/appnexus';

/**
 * @param {string} streamUrl
 * @param {Stream} stream
 * @param {*} options
 * @param {*} settings
 * @returns {(config: Config) => Config}
 * @template Config
 */
function getPlaylist(
    streamUrl,
    stream,
    options,
    settings,
    { forceImaClientLoading, shouldUseAudioSkin } = {
        forceImaClientLoading: false,
        shouldUseAudioSkin: false,
    },
) {
    const { locale, poster, title, description, minDvrWindow } = options;

    /**
     * @param {PlaylistItem} playlistItem
     * @returns {PlaylistItem}
     */
    function addPlaylistMetadata(playlistItem) {
        const mediaId = stream.getExternalId();
        const startTime = stream.getPlaybackTime('begin');

        // set media id only if it exists
        if (mediaId) {
            playlistItem.mediaid = mediaId;
        }

        // add title
        if (title) {
            playlistItem.title = title;
        }

        if (description) {
            playlistItem.description = description;
        }

        // seek stream if play ahead time is set
        if (startTime) {
            playlistItem.starttime = startTime;
        }

        if (minDvrWindow) {
            playlistItem.minDvrWindow = minDvrWindow;
        }
        return playlistItem;
    }

    /**
     * @param {PlaylistItem} playlistItem
     * @returns {PlaylistItem}
     */
    function addSnapshots(playlistItem) {
        if (stream.hasSnapshots()) {
            // add snapshots for each stream
            playlistItem.tracks.push({
                file: stream.getSnapshots(),
                kind: 'thumbnails',
            });
        }

        return playlistItem;
    }

    /**
     * @param {PlaylistItem} playlistItem
     * @returns {PlaylistItem}
     */
    function addCaptions(playlistItem) {
        if (stream.getCaptions().length > 0 && !stream.hasSubtitlesInHls()) {
            utils.each(stream.getCaptions(), function (caption) {
                playlistItem.tracks.push({
                    file: caption.url,
                    label: locale.translate(caption.language),
                    kind: 'captions',
                    default: caption.default === true,
                });
            });
        }

        return playlistItem;
    }

    /**
     * @param {*} adConfig
     * @param {PlaylistItem} playlistItem
     * @returns {PlaylistItem & { adPlacements?: *, adschedule?: * }}
     */
    function addAppnexusAds(adConfig, playlistItem) {
        const { adSchedule, adPlacements } = adConfig || {};

        // check if there are ads
        if (adSchedule && Object.keys(adSchedule).length > 0) {
            utils.extend(playlistItem, {
                adschedule: adSchedule,
            });
        }

        // check if there are ads
        if (adPlacements) {
            playlistItem.adPlacements = adPlacements;
        }

        return playlistItem;
    }

    return function (config) {
        const type = stream.isAudio() && stream.hasMp4Streams() ? 'mp4' : 'hls';

        return Promise.resolve({
            id: stream.getId(),
            image: stream.isAudio() && shouldUseAudioSkin ? undefined : poster,
            sources: [
                {
                    file: type === 'mp4' ? stream.getUrl(type) : streamUrl,
                    // force stream type for mock stream which contains mp4 blob
                    type: stream.isMock() ? 'video/mp4' : type,
                    default: true,
                    preload: type === 'mp4' ? 'none' : 'auto',
                },
            ],
            mediaType: stream.get('mediaType'),
            tracks: [],
        })
            .then(addPlaylistMetadata)
            .then(addCaptions)
            .then(addSnapshots)
            .then(async function (playlistItem) {
                if (
                    (stream.isPodcast() || settings.vmap) &&
                    !stream.hasAdsDisabled() &&
                    !settings.na
                ) {
                    config.advertising = utils.extend(
                        {
                            client: 'vast',
                        },
                        locale.translate('ads', true),
                    );

                    const vmapParams =
                        settings.vmap instanceof Function
                            ? await settings.vmap()
                            : {};

                    return utils.extend(playlistItem, {
                        adschedule: stream.getVmapApiUrl(vmapParams),
                    });
                }

                return appnexus
                    .getConfig(stream, settings)
                    .then(function (adConfig) {
                        const adOptions = adConfig.adOptions || {};

                        const shouldEnableIma =
                            adConfig.adSchedule &&
                            Object.keys(adConfig.adSchedule || {}).some(
                                (schedule) => schedule.endsWith('prebid'),
                            );

                        config.advertising = utils.extend(
                            {
                                client:
                                    shouldEnableIma || forceImaClientLoading
                                        ? 'googima'
                                        : 'vast',
                                maxRedirects: 7,
                                locale:
                                    locale.getTranslations().id === 'se'
                                        ? 'sv'
                                        : locale.getTranslations().id,
                            },
                            locale.translate('ads', true),
                        );

                        if (typeof adOptions.autoplayMuted !== 'undefined') {
                            config.advertising.autoplayadsmuted =
                                adOptions.autoplayMuted;
                        }

                        if (adOptions.vpaidcontrols === true) {
                            config.advertising.vpaidcontrols = true;
                        }

                        if (adOptions.preloadAds === true) {
                            config.advertising.preloadAds = true;
                        }

                        // do not add any ads to playlist when it is in preload mode
                        if (
                            stream.isMock() &&
                            stream.getVendor() !== 'appnexus'
                        ) {
                            return playlistItem;
                        }

                        return addAppnexusAds(adConfig, playlistItem);
                    });
            })
            .then(function (playlistItem) {
                if (!config.playlist) {
                    config.playlist = [];
                }
                config.playlist.push(playlistItem);

                playlistItem.svp = {
                    stream: stream,
                    settings: settings,
                };

                return config;
            });
    };
}

export default getPlaylist;
