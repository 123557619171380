import { scriptLoader } from 'utils/script-loader';
import utils from 'utils/utils';

function getRandomId() {
    return Math.floor(Math.random() * 100) + 1;
}

function getJwAdsConfig(jwAdScheduleSlots, stream, settings) {
    const adSlots = jwAdScheduleSlots || {};

    const { hasNext } = settings || {};
    const startTime = stream.getPlaybackTime('begin') || 0;

    const adsConfig = {};

    if (adSlots.preroll) {
        adsConfig[`preroll${getRandomId()}-prebid`] = {
            offset: 'pre',
            extra: 1,
            tag: adSlots.preroll.tag,
        };
    }

    utils.each(stream.getCuePoints(), function (cuePoint) {
        if (cuePoint?.timeline > startTime) {
            if (adSlots.midroll) {
                adsConfig[`midroll${getRandomId()}-prebid`] = {
                    offset: cuePoint.timeline,
                    tag: adSlots.midroll.tag,
                };
            }
        }
    });

    if (
        hasNext === false ||
        (hasNext !== true && stream.get('hasNext') === false)
    ) {
        if (adSlots.postroll) {
            adsConfig[`postroll${getRandomId()}-prebid`] = {
                offset: 'post',
                tag: adSlots.postroll.tag,
            };
        }
    }

    return adsConfig;
}

function getScheduleSlots(adSlots, adtTagUrls) {
    return Object.keys(adSlots).reduce((prev, current, index) => {
        prev[current] = {
            offset: current,
            tag: adtTagUrls[index],
        };
        return prev;
    }, {});
}

const CACHE_URL = 'https://pbs.relevant-digital.com/analytics_cache/cache';

async function loadAdTagUrls(config) {
    return new Promise((resolve, reject) => {
        if (!window.relevantDigital)
            reject(new Error('Cannot find relevantDigital on global object'));

        window.relevantDigital.cmd.push(() => {
            const prebidConfig = {
                consentManagement: config.consentManagement ?? {
                    cmpApi: 'none',
                },
                cache: {
                    url: CACHE_URL,
                },
            };

            if (config.userId) {
                prebidConfig.ortb2 = {
                    user: {
                        id: config.userId,
                    },
                };
            }

            if (config.eids) {
                const eids = config.eids.map(({ source, id }) => ({
                    source,
                    uids: [{ id, atype: 1, ext: { stype: 'ppuid' } }],
                }));

                prebidConfig.userSync = {
                    userIds: [{ name: 'pubProvidedId', params: { eids } }],
                };
            }

            window.relevantDigital.addPrebidConfig(prebidConfig);

            const slots = window.relevantDigital
                .defineVideoSlots(
                    Object.values(config.adSlots).map((adSlot) => ({
                        path: adSlot.path,
                        custParams: {
                            ...adSlot.params,
                            ...config.params,
                        },
                    })),
                )
                .map((s) => s.getSlotElementId());

            window.relevantDigital.loadPrebid({
                configId: config.configId,
                collapseEmptyDivs: true,
                collapseBeforeAdFetch: false,
                allowedDivIds: null, // set to an array to only load certain <div>s, example - ["divId1", "divId2"]
                noSlotReload: true,
                allowedPlacementType: 'instream',
                onSlotAndUnit: function ({ slot, unit }) {
                    const xandrKeywords = Object.fromEntries(
                        Object.entries(slot.custParams || {})
                            .filter(([, value]) => Boolean(value))
                            .map(([key, value]) => [
                                key,
                                value.toString().split(','),
                            ]),
                    );
                    const { pbAdUnit } = unit; // AdUnit in Prebid
                    const xandrBids = pbAdUnit.bids.filter(
                        (b) => b.bidder.indexOf('appnexus') === 0,
                    );
                    xandrBids.forEach(({ params }) => {
                        params.keywords = Object.assign(
                            params.keywords || {},
                            xandrKeywords,
                        );
                    });
                },
            });

            window.relevantDigital.loadVideoUrls(slots, (urls) => {
                resolve(urls);
            });
        });
    });
}

async function loadRelevantScript(url) {
    if (window.relevantDigital) return true;
    try {
        await scriptLoader(url);
        return true;
    } catch {
        return false;
    }
}

export async function getRelevantYieldConfig(config, stream, settings) {
    const result = await loadRelevantScript(config.scriptUrl);
    if (!result) return null;

    const adTagUrls = await loadAdTagUrls(config);
    const scheduleSlots = getScheduleSlots(config.adSlots, adTagUrls);
    return getJwAdsConfig(scheduleSlots, stream, settings);
}

export const onAdEventImpression = (ev) => {
    if (!window.relevantDigital) return;
    window.relevantDigital.cmd.push(() => {
        // Loop through all ad ids starting from the innermost ad.
        // registerImpressionByAdId() will return true if it registered an impression
        const adIds = [ev.getAd().getAdId(), ...ev.getAd().getWrapperAdIds()];
        // eslint-disable-next-line no-restricted-syntax
        for (const id of adIds) {
            if (window.relevantDigital.Auction.registerImpressionByAdId(id)) {
                return;
            }
        }
    });
};
