import { getConfig } from '../config/sharing';

const FACEBOOK_SHARE_URL = 'http://www.facebook.com/sharer/sharer.php?u=';
const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet?url=';

/**
 * Check if link uses JW MEDIAID replacement
 * @param link
 * @return {*|boolean}
 */
const hasMediaId = (link) => link && link.indexOf('MEDIAID') > -1;

/**
 * Create sharing url to services like twitter or facebook
 *
 * @param root
 * @param link
 * @return {string}
 */
const getSharingLink = (root, link = window.location.href) =>
    `${root}${encodeURIComponent(link)}`;

/**
 * Update node element only if exists and value is correctly set
 * @param el
 * @param value
 */
const updateElement = (el, value) => {
    if (el && value) {
        el.value = value;
    }
};

/**
 * Replace JW Sharing button event listener
 *
 * @param sourceEl
 * @param link
 */
const replaceElement = (sourceEl, link) => {
    if (sourceEl) {
        const newEl = sourceEl.cloneNode(true);
        sourceEl.parentNode.replaceChild(newEl, sourceEl);

        newEl.addEventListener(
            'click',
            () => {
                window.open(link, '_blank');
            },
            true,
        );
    }
};

/**
 * Update JW sharing box to work with our playnext
 *
 * @param container
 * @param stream
 * @param options
 */
const updateSharingBox = (container, stream, options) => {
    const { link, code } =
        getConfig({
            stream,
            options,
        }) || {};

    // mediaid is supported natively so we do not touch anything when it's passed
    if (code && hasMediaId(code) === false) {
        updateElement(
            container.querySelector(
                '.jw-sharing-copy[aria-label=embed] .jw-sharing-textarea',
            ),
            code,
        );
    }

    // link can be undefined
    if (link && hasMediaId(link) === false) {
        updateElement(
            container.querySelector(
                '.jw-sharing-copy[aria-label=link] .jw-sharing-textarea',
            ),
            link,
        );

        // this is tricky way to replace JW sharing buttons
        // facebook and twitter are replaced with custom sharing elements
        // this allows dynamic configuration via function
        replaceElement(
            container.querySelector('.jw-sharing-link[aria-label=facebook]'),
            getSharingLink(FACEBOOK_SHARE_URL, link),
        );
        replaceElement(
            container.querySelector('.jw-sharing-link[aria-label=twitter]'),
            getSharingLink(TWITTER_SHARE_URL, link),
        );
    }
};

export default (svpPlayer) => {
    svpPlayer.on('initialPlay', () =>
        updateSharingBox(
            svpPlayer.getContainer(),
            svpPlayer.getStream(),
            svpPlayer.config.get('sharing'),
        ),
    );
};
