import logger from 'utils/logger';
import utils from 'utils/utils';
import locale from 'player/model/locale';
import { forward30Icon, playbackRateIcon } from 'icons/audio';
import { ensureIfControlsLoaded } from './jw-controls';

const DEFAULT_USE_AUDIO_SKIN = true;

const CN_FORWARD_BUTTON = 'jw-icon-forward';
const CN_DURATION_INFO = 'svp-duration';
const CN_PLAYBACK_RATE = 'svp-playback-rate';
const COVER = 'svp-cover';
const COVER_CONTAINER = 'svp-cover-container';

const PLAYBACK_RATES = [1.0, 1.2, 1.5, 1.7, 2.0];

/** {string} An icon with proper class names */
const forwardIcon = forward30Icon.replace(
    '<svg',
    '<svg class="jw-svg-icon jw-svg-icon-forward"',
);

/**
 * @param {number} seconds
 * @param {Object} translatePlayerConfig
 * @return {string}
 */
function timeToText(seconds, translatePlayerConfig) {
    if (seconds < 60) {
        return `${Math.floor(seconds)} ${translatePlayerConfig.seconds}`;
    }
    if (seconds < 3600) {
        return `${Math.floor(seconds / 60)} ${translatePlayerConfig.minutes}`;
    }
    return `${Math.floor(seconds / 3600)}${
        translatePlayerConfig.hours
    } ${Math.floor((seconds - Math.floor(seconds / 3600) * 3600) / 60)}${
        translatePlayerConfig.minutes
    }`;
}

export function shouldUseAudioSkin(config) {
    if (typeof config.get('audioSkin') === 'boolean') {
        return config.get('audioSkin');
    }

    return !config.shouldApplyTextToSpeechSkin() && DEFAULT_USE_AUDIO_SKIN;
}

/**
 * Resolves whether the player needs an audio skin or not and adds necessary elements to the UI if needed
 * @param {PlayerModel} svpPlayer
 */
export default function audioSkinResolver(svpPlayer) {
    const translate = locale.translate('player');
    const { config, player: jwPlayer } = svpPlayer;
    const useAudioSkin = shouldUseAudioSkin(config);

    logger('AudioSkin').log(useAudioSkin ? 'on' : 'off');

    if (!useAudioSkin) {
        return;
    }

    const seekForward = () => {
        const position = jwPlayer.getPosition();
        const duration = jwPlayer.getDuration();
        svpPlayer.seek(Math.min(duration, position + 30));
    };

    const changePlaybackRate = () => {
        const rate = jwPlayer.getPlaybackRate();
        const nextRateIndex = PLAYBACK_RATES.findIndex(
            (currentRate) => currentRate === rate,
        );
        jwPlayer.setPlaybackRate(
            PLAYBACK_RATES[
                nextRateIndex >= PLAYBACK_RATES.length - 1
                    ? 0
                    : nextRateIndex + 1
            ],
        );
    };

    svpPlayer.on('playbackRateChanged', ({ playbackRate }) => {
        if (!svpPlayer.player.getControls()) {
            return;
        }

        const container = svpPlayer.getContainer();
        const playbackRateButton = container.querySelector(
            `.jw-controlbar .${CN_PLAYBACK_RATE}`,
        );
        const playbackRateButtonText = playbackRateButton.querySelector('text');

        playbackRateButtonText.textContent = playbackRate.toFixed(1);
        playbackRateButton.setAttribute(
            'aria-label',
            `${translate.playbackSpeed} ${playbackRateButtonText.textContent}`,
        );
    });

    svpPlayer.on('playlistItem', async () => {
        if (!svpPlayer.player.getControls()) {
            return;
        }

        const container = svpPlayer.getContainer();
        const stream = svpPlayer.getStream();
        const isAudio = stream.isAudio();
        const duration = stream.getDuration();
        const forwardTooltip = translate.forward;

        const addOrRemoveClass = isAudio ? utils.addClass : utils.removeClass;
        addOrRemoveClass(container, 'svp-flag-media-audio');

        await ensureIfControlsLoaded(container);

        const displayControl = container.querySelector(
            '.jw-display-icon-display .jw-icon-display',
        );
        const cover = container.querySelector(`.jw-preview .${COVER}`);
        let forwardButton = container.querySelector(
            `.jw-controlbar .${CN_FORWARD_BUTTON}`,
        );
        let durationInfo = container.querySelector(
            `.jw-title .${CN_DURATION_INFO}`,
        );
        let playbackRateButton = container.querySelector(
            `.jw-controlbar .${CN_PLAYBACK_RATE}`,
        );

        if (!cover && isAudio) {
            container
                .querySelector('.jw-wrapper')
                .insertBefore(
                    utils.createNode(
                        `<div class="${COVER_CONTAINER}"><div class="${COVER}" /></div>/`,
                    ),
                    container.querySelector('.jw-preview'),
                );

            container.querySelector(`.${COVER_CONTAINER}`).style[
                'background-image'
            ] = `url("${config.getPoster(stream)}")`;
        }

        if (!forwardButton) {
            jwPlayer.addButton(
                forwardIcon,
                forwardTooltip,
                seekForward,
                'forward',
                CN_FORWARD_BUTTON,
            );
            forwardButton = container.querySelector(
                `.jw-controlbar .${CN_FORWARD_BUTTON}`,
            );
        }

        if (!durationInfo) {
            const title = container.querySelector('.jw-title');
            durationInfo = utils.createNode(
                `<div class="${CN_DURATION_INFO}" />`,
            );
            title.appendChild(durationInfo);
        }

        if (!playbackRateButton) {
            jwPlayer.addButton(
                playbackRateIcon,
                '',
                changePlaybackRate,
                'playback-rate',
                CN_PLAYBACK_RATE,
            );
            const playbackRateButtonText = container.querySelector(
                `.jw-controlbar .${CN_PLAYBACK_RATE} text`,
            );
            playbackRateButtonText.textContent = jwPlayer
                .getPlaybackRate()
                .toFixed(1);

            playbackRateButton = container.querySelector(
                `.jw-controlbar .${CN_PLAYBACK_RATE}`,
            );
            playbackRateButton.setAttribute(
                'aria-label',
                `${translate.playbackSpeed} ${playbackRateButtonText.textContent}`,
            );
        }

        const displayStyle = isAudio ? null : 'none';
        displayControl.setAttribute(
            'aria-label',
            isAudio ? translate.listen : translate.playback,
        );
        forwardButton.style.display = displayStyle;
        durationInfo.style.display = displayStyle;
        playbackRateButton.style.display = displayStyle;
        durationInfo.innerText = isAudio ? timeToText(duration, translate) : '';
    });
}
