import utils from 'utils/utils';
import { APPNEXUS_PROVIDER_ID } from 'player/services/api';

const APPNEXUS_CLASS_NAME = 'svp-appnxs';
const SVP_FLAG_STOPPED = 'svp-flag-stopped';

const handleAppnexusClass = (container, vendor) => {
    // handle ads only playback
    // class name is scrambled to avoid adblockers
    if (vendor === APPNEXUS_PROVIDER_ID) {
        utils.addClass(container, APPNEXUS_CLASS_NAME);
    } else {
        utils.removeClass(container, APPNEXUS_CLASS_NAME);
    }
};

export { APPNEXUS_CLASS_NAME, SVP_FLAG_STOPPED, handleAppnexusClass };

export default function (svpPlayerModel) {
    svpPlayerModel.on('playlistItem', () => {
        const container = svpPlayerModel.getContainer();

        handleAppnexusClass(container, svpPlayerModel.getStream().getVendor());

        container.classList.remove(SVP_FLAG_STOPPED);
    });

    svpPlayerModel.on('complete', (reason) => {
        const container = svpPlayerModel.getContainer();

        if (reason === 'stop') {
            container.classList.add(SVP_FLAG_STOPPED);
        }
    });
}
