const GLIMR_TAG_STORAGE_KEY = 'glimrTags';

export type GlimrTags = string[];
export type GlimrTagMappings = Record<string, string[]>;

export interface GlimrLocalStorageItem {
  tags: GlimrTags;
  tagMappings: GlimrTagMappings;
}

function getGlimrLocalStorageItem(): GlimrLocalStorageItem | null {
  const item = window.localStorage.getItem(GLIMR_TAG_STORAGE_KEY);

  if (!item) {
    return null;
  }

  try {
    const { timestamp, data } = JSON.parse(item);
    const MAX_CACHE_AGE = 24 * 60 * 60 * 1000;

    if (timestamp + MAX_CACHE_AGE > Date.now()) {
      return data;
    }

    return null;
  } catch (e) {
    return null;
  }
}

export function getGlimrTags(): GlimrTags | undefined {
  const glimrData = getGlimrLocalStorageItem();
  return glimrData?.tags;
}
