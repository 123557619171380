import { isEmpty } from 'utils/underscore';
import utils from 'utils/utils';
import logger from 'utils/logger';
import { getRelevantYieldConfig } from './relevant-yield';
import * as appnexusTagBuilder from './appnexus-tag-builder';
import JwAdsConfig from './appnexus-jw-config';

/**
 * @param {{adSlots, params: ({member: string}|Object)}} adConfig
 * @param {Stream&Eventable} stream
 * @param {*} settings
 * @returns {*}
 */
// move to appnexus jw
function getAdsConfig(adConfig, stream, settings) {
    const adSlots = adConfig.adSlots || {};
    const params = adConfig.params || [];
    const adsConfig = new JwAdsConfig(stream);
    const { hasNext } = settings || {};
    const startTime = stream.getPlaybackTime('begin') || 0;

    adsConfig.setParams(params);
    adsConfig.add(adSlots.preroll, 'pre', 'preroll');

    utils.each(stream.getCuePoints(), function (cuePoint) {
        if (cuePoint?.timeline > startTime) {
            adsConfig.add(adSlots.midroll, cuePoint.timeline, 'midroll');
        }
    });

    // postroll is disabled when playnext is available
    if (
        hasNext === false ||
        (hasNext !== true && stream.get('hasNext') === false)
    ) {
        adsConfig.add(adSlots.postroll, 'post', 'postroll');
    }

    return adsConfig.getConfig();
}

/**
 * @param {Params} params
 * @param {string} memberId
 * @returns {Params & { member: string }}
 * @template Params
 */
function getParams(params, memberId) {
    if (memberId) {
        return { member: memberId, ...params };
    }

    return params;
}

/**
 * @param {*} adPlacements
 * @param {*} globalParams
 */
function parseAdPlacements(adPlacements, globalParams) {
    const placements = {};

    utils.each(adPlacements, function (values, placementId) {
        placements[placementId] = appnexusTagBuilder.getAstConfig(
            values,
            globalParams,
        );
    });

    return placements;
}

/**
 * @param {*} clientConfig
 * @param {Stream} stream
 * @param {*} settings
 * @returns {*}
 */
function getJwAdSchedule(clientConfig, stream, settings) {
    const { adSlots } = clientConfig;
    if (adSlots) {
        return getAdsConfig(
            {
                adSlots: adSlots.schedule,
                params: getParams(adSlots.params, clientConfig.member),
            },
            stream,
            settings,
        );
    }

    return {};
}

/**
 * @param {*} clientConfig
 * @returns {*}
 */
function getAdPlacements(clientConfig) {
    const { adPlacements } = clientConfig;

    if (clientConfig.member && adPlacements) {
        return parseAdPlacements(
            adPlacements.schedule,
            getParams(adPlacements.params, clientConfig.member),
        );
    }

    return {};
}

/**
 * @param {Stream} stream
 * @param {*} settings
 * @returns {Promise<*>}
 */
const getClientConfig = async (stream, settings) => {
    // ads are disabled
    // check stream config also
    const { na, adn } = settings;

    if (na === true || !adn || stream.hasAdsDisabled()) {
        return null;
    }

    let svpAdConfig = {};

    if (typeof adn === 'function') {
        svpAdConfig = await adn(
            stream,
            appnexusTagBuilder.getDefaultParams(stream),
            adn.svpPlayer,
        );
    }

    logger('SVP').log('adsConfig', svpAdConfig);

    return svpAdConfig;
};

/**
 * Handle midroll scheduling in player
 *
 * @param {string} tag
 * @param {number|'NOW'} startTime
 * @returns {{onEnter: (cb: () => void) => void, setTime: (time: number) => void, onReady: (cb: () => void) => void}}
 */
function createMidroll({ tag, startTime }) {
    let hasEntered = false;
    let onEnterCallback = () => {};

    /**
     * @param {() => void} callback
     */
    const onEnter = (callback) => {
        onEnterCallback = callback;
    };

    /**
     * @param {() => void} callback
     */
    const onReady = (callback) => {
        if (tag) {
            callback();
        }
    };

    /**
     * @param {number} time
     */
    const setTime = (time) => {
        if (
            hasEntered === false &&
            (time >= startTime || startTime === 'NOW')
        ) {
            onEnterCallback(tag);
            hasEntered = true;
        }
    };

    return {
        setTime,
        onEnter,
        onReady,
    };
}

/**
 * @typedef AppnexusConfig
 * @property {*} adSchedule
 * @property {*} adPlacements
 * @property {*} adOptions
 */

/**
 * @param {Stream} stream
 * @param {*} settings
 * @returns {Promise<AppnexusConfig|{}>}
 */
async function getConfig(stream, settings) {
    const clientConfig = await getClientConfig(stream, settings);

    if (clientConfig === null) {
        return {};
    }

    if (!isEmpty(clientConfig.relevantYield)) {
        return {
            adSchedule: await getRelevantYieldConfig(
                clientConfig.relevantYield,
                stream,
                settings,
            ),
            adPlacements: getAdPlacements(clientConfig),
            adOptions: clientConfig.options || {},
        };
    }

    return {
        adSchedule: getJwAdSchedule(clientConfig, stream, settings),
        adPlacements: getAdPlacements(clientConfig),
        adOptions: clientConfig.options || {},
    };
}

/**
 * @param {number} maxDuration
 * @param {Stream} stream
 * @param {Object} settings
 * @returns {Promise<string|undefined>}
 */
function getLiveMidrollTag(maxDuration, stream, settings) {
    return getClientConfig(stream, settings).then(function (clientConfig) {
        if (!clientConfig) {
            return undefined;
        }

        const { adSlots } = clientConfig;
        const midroll = adSlots && adSlots.schedule && adSlots.schedule.midroll;
        if (!midroll) {
            return undefined;
        }

        const params = getParams((adSlots || {}).params, clientConfig.member);
        params.vmaxduration = maxDuration;

        return appnexusTagBuilder.getVmapTag(utils.merge(params, midroll));
    });
}

export default {
    getConfig,
    getLiveMidrollTag,
    createMidroll,
};
