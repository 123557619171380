export type OrientationType = 'landscape' | 'portrait';

export const isScreenOrientationSupported =
  typeof ScreenOrientation !== 'undefined';

export const getScreenOrientation = (): OrientationType => {
  if (isScreenOrientationSupported) {
    return window.screen.orientation.type.includes('portrait')
      ? 'portrait'
      : 'landscape';
  }

  // `orientation` property is missing in desktop browsers
  if (typeof window.orientation === 'undefined') {
    return 'landscape';
  }

  return Math.abs(window.orientation) !== 90 ? 'portrait' : 'landscape';
};
