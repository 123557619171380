import Events from './backbone.events';

const SimpleModel = {
    /**
     * @param {string} attr
     */
    get(attr) {
        this.attributes = this.attributes || {};
        return this.attributes[attr];
    },
    /**
     * @param {string} attr
     * @param {*} val
     */
    set(attr, val) {
        this.attributes = this.attributes || {};

        if (this.attributes[attr] === val) {
            return;
        }
        const oldVal = this.attributes[attr];
        this.attributes[attr] = val;
        this.trigger(`change:${attr}`, this, val, oldVal);
    },
    clone() {
        return { ...this.attributes };
    },
    ...Events,
};

export default SimpleModel;
