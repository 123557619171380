import nextAsset from './next-asset';
import { fetchFromRelated } from './recommended/list';

class Recommended {
    /**
     * @param {Asset} asset
     */
    constructor({ asset }) {
        /**
         * @type {Asset}
         */
        this.asset = asset;
    }

    /**
     * Fetch next asset
     *
     * @returns {Promise}
     */
    getNextAsset(options = {}) {
        const { asset } = this;

        // next asset can be provided in player configuration
        if (options.asset) {
            return nextAsset.fetchNextById({
                // set vendor is specified or use main asset as default
                vendor: options.asset.vendor || asset.getVendor(),
                // asset can be object or number with asset id
                assetId: options.asset.id || options.asset,
            });
        }

        return nextAsset.fetchNext(asset);
    }

    /**
     * Fetch recommended asset list
     *
     * @returns {*}
     */
    async getItems() {
        const vendor = this.asset.getVendor();
        const assetId = this.asset.getId();

        return fetchFromRelated({
            vendor,
            assetId,
        });
    }
}

export default Recommended;
