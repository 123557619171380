/**
 * @param {HTMLElement} parent
 * @param {string | HTMLElement | Object} child
 * @returns {HTMLElement}
 */
export function appendChild(parent, child) {
    if (Array.isArray(child)) {
        child.forEach((c) => appendChild(parent, c));
    } else if (typeof child === 'string') {
        parent.appendChild(document.createTextNode(child));
    } else if (child instanceof HTMLElement) {
        parent.appendChild(child);
    } else {
        throw new Error('Invalid child type');
    }

    return parent;
}

/**
 * @param {string} tagName
 * @param {Object} attributes
 * @param {string | HTMLElement | Object | (string | HTMLElement | Object)[]} children
 * @returns {HTMLElement}
 */
export function createElement(tagName, attributes = {}, children = []) {
    const element = document.createElement(tagName);
    Object.keys(attributes).forEach((attribute) => {
        element.setAttribute(attribute, attributes[attribute]);
    });
    appendChild(element, children);

    return element;
}
