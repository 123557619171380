/* eslint-disable no-multi-assign */

/**
 * @param {string} path
 * @param {(value: *) => void} onSuccess
 * @param {(reason?: *) => void} onError
 */
function loadScript(path, onSuccess, onError) {
    var script = document.createElement('script'),
        done = false;

    const callback = onSuccess || function () {};

    script.type = 'text/javascript';
    script.src = path;
    script.async = true;
    script.onreadystatechange = script.onload = function () {
        if (
            !done &&
            (!this.readyState ||
                this.readyState === 'loaded' ||
                this.readyState === 'complete')
        ) {
            done = true;
            callback();

            // Handle memory leak in IE
            script.onload = script.onreadystatechange = null;
        }
    };

    script.onerror = onError;

    document.body.appendChild(script);
}

/**
 * @param {string} path
 */
export const scriptLoader = (path) =>
    new Promise((resolve, reject) => {
        loadScript(path, resolve, reject);
    });
