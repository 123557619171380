import { copyToClipboard } from 'utils/clipboard';

export default (svpPlayer, { icon }) => {
    if (!svpPlayer.player.getControls()) {
        return;
    }

    let isLoaded = false;

    svpPlayer.once('ready', () => {
        const container = svpPlayer.getContainer();

        container.addEventListener(
            'contextmenu',
            () => {
                if (isLoaded === false) {
                    const item = container.querySelectorAll(
                        '.jw-rightclick-list .jw-rightclick-item',
                    )[0];
                    if (!item) {
                        return;
                    }

                    item.classList.add('svp-config-link');

                    const infoNode = document.createElement('span');
                    infoNode.innerHTML = icon;
                    infoNode.classList.add('jw-icon', 'svp-config-info');
                    infoNode.dataset.tip = 'Click to copy';

                    item.appendChild(infoNode);

                    infoNode.addEventListener(
                        'click',
                        async () => {
                            const config = svpPlayer.config.dump();

                            infoNode.classList.add('svp-link-tip');
                            infoNode.dataset.tip = 'Copied';

                            copyToClipboard(config);

                            setTimeout(() => {
                                infoNode.classList.remove('svp-link-tip');
                            }, 300);
                        },
                        false,
                    );

                    isLoaded = true;
                }
            },
            false,
        );
    });
};
