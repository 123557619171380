import utils from 'utils/utils';
import * as appnexusTag from './appnexus-tag-builder';

const OVERRIDE_SEPARATOR = ':';
const OVERRIDE_SLOT_PARAM = 'ast_override_slot';
const OVERRIDE_SLOT_TAG_ID = 'ast_override_tag_id';

/**
 * @returns {Record<string, string>|undefined}
 */
export function getOverriddenParams() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has(OVERRIDE_SLOT_PARAM)) {
        const [slotName, creativeId] = urlParams
            .get(OVERRIDE_SLOT_PARAM)
            .split(OVERRIDE_SEPARATOR);
        return { [slotName]: creativeId };
    }

    if (urlParams.has(OVERRIDE_SLOT_TAG_ID)) {
        const [tagId, creativeId] = urlParams
            .get(OVERRIDE_SLOT_TAG_ID)
            .split(OVERRIDE_SEPARATOR);
        return { [tagId]: creativeId };
    }

    return undefined;
}

export default class JwAdsConfig {
    /**
     * @param {Stream & Eventable} stream
     */
    constructor(stream) {
        this.stream = stream;
        this.config = {};
        this.params = [];
        this.overriddenParams = getOverriddenParams();
    }

    /**
     * @param {Object<string, *>} params
     */
    setParams(params) {
        this.params = params;
    }

    /**
     * @param {Object} adSlotConfig
     * @param {string} adLabel
     * @return {string}
     */
    getTag(adSlotConfig, adLabel) {
        const adConfig = utils.merge(this.params, adSlotConfig);
        const overriddenId =
            this.overriddenParams &&
            (this.overriddenParams[adLabel] ||
                (adConfig.id && this.overriddenParams[adConfig.id]));
        if (overriddenId) {
            return appnexusTag.getOverriddenCrTag(overriddenId);
        }

        if (this.stream.isAudio()) {
            return appnexusTag.getPtvTag(adConfig);
        }

        return appnexusTag.getVmapTag(adConfig);
    }

    /**
     * @param {Object} adSlotConfig
     * @param {string} offset
     * @param {string} adLabel
     */
    add(adSlotConfig, offset, adLabel) {
        // do not add tag when id is empty
        if (adSlotConfig) {
            this.config[adLabel + Math.floor(Math.random() * 100) + 1] = {
                offset: offset,
                tag: this.getTag(adSlotConfig, adLabel),
            };
        }
    }

    getConfig() {
        return this.config;
    }
}
