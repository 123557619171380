import { AdFormat, DeviceType } from '../types';

import { getDevice } from './getDevice';

/**
 * Returns a complete {@link InventoryCode}, based on the given template and parameters.
 *
 * @param invCodeTemplate - `${section}`, `${device}`, `${format}` will be replaced with valid values
 * @param {AdFormat} format
 * @param deviceType
 * @param useAFP - whether AFP (Ad Financed Production) placement should be used
 *
 * @return {InventoryCode}
 *
 * @see {@link https://docs.google.com/spreadsheets/d/1ejKO4uIZBCaQrTX8rOfdriouKYLFrdk1EG2Cml0UOFY/edit#gid=0|AB placements}
 */
function getInvCode(
  invCodeTemplate: string,
  format: AdFormat,
  deviceType: DeviceType,
  useAFP = false
) {
  let section: 'afp' | 'other' = useAFP ? 'afp' : 'other';
  const device = getDevice(deviceType);

  // this is a temporary change, until instream (preroll, midroll) ads setup is aligned as well
  // see vgtv/frontend#340 for details
  if (format === 'pause') {
    if (useAFP) {
      section = 'afp';
    }
  }

  return invCodeTemplate
    .replace('${section}', section)
    .replace('${device}', device)
    .replace('${format}', format);
}

export { getInvCode };
