export default function (options = {}) {
    // additional config options required for playback
    return function (jwConfig) {
        if (options.native === true) {
            jwConfig.renderCaptionsNatively = true;
            delete options.native;
        }

        if (Object.keys(options).length > 0) {
            jwConfig.captions = options;
        }

        return jwConfig;
    };
}
