/**
 * @param {Homad} homad
 */
const createProxyMethods = (homad) => ({
    /**
     * @this {PlayerModel}
     */
    onFirstFrameLoad(onFirstFrameLoad) {
        this.player.once(
            'play',
            function () {
                this.player.once(
                    'providerFirstFrame',
                    function () {
                        onFirstFrameLoad.call(this, this.playlistItemId);
                    },
                    this,
                );
            },
            this,
        );
    },

    /**
     * @this {PlayerModel}
     */
    onTime(onTime, args) {
        if (!this.adPlaying && !this.adPlayerLoading) {
            onTime.apply(this, args);
        }
    },

    /**
     * @this {PlayerModel}
     */
    // eslint-disable-next-line consistent-return
    onPlay(onPlay, args) {
        if (!this.adPlayerLoading) {
            onPlay.apply(this, args);
        }
    },
    /**
     * @this {PlayerModel}
     */
    onPause(onPause, args) {
        if (this.adPlaying === false && !this.adPlayerLoading) {
            onPause.apply(this, args);
        }
    },
    /**
     * @this {PlayerModel}
     */
    onComplete(onComplete, args) {
        if (this.adPlaying === false && !this.adPlayerLoading) {
            onComplete.apply(this, args);
        } else {
            this.listenToOnce(
                homad,
                'continueContent',
                function () {
                    onComplete.apply(this, args);
                },
                this,
            );
        }
    },
});

/**
 * Override player methods to play well with Homad
 * @param {PlayerModel} player
 * @param {Homad} homad
 */
export const applyPlayerMixin = (player, homad) => {
    const overrides = createProxyMethods(homad);
    Object.keys(overrides).forEach((name) => {
        const sourceMethod = player[name];

        player[name] = function (...args) {
            return overrides[name].call(player, sourceMethod, args);
        };
    });
};
