import createSvpAccess from '@vgtv/svp-access';
import UserSettings from '@vg/user-settings';
import { Provider } from '@vgtv/api-client';
import { ProviderEnvMap } from '@vgtv/svp-access/lib/svp_access';
import { ProviderAccessMap } from '@vgtv/svp-access/lib/access_mappings';
import type {
  LoginOptions,
  Identity,
  HasSessionSuccessResponse,
} from '@schibsted/account-sdk-browser/src/identity';
import type { Monetization } from '@schibsted/account-sdk-browser/src/monetization';

let identityInstancePromise: Promise<Identity>;
let monetizationInstancePromise: Promise<Monetization>;
let userId: number | null = null;
let username: string;
let userDataPromise: Promise<void>;
let userAccesses: ProviderAccessMap[Provider][];
let userAccessesPromise: Promise<void>;
let provider: Provider;
let schibstedAccountEnv: ProviderEnvMap[Provider];
let svpAccess: ReturnType<typeof createSvpAccess>;

let locationPromise: Promise<void>;
let location: {
  eea?: boolean;
  local?: boolean;
} = {};

async function setUserData() {
  const identity = await identityInstancePromise;

  try {
    const session = await identity.hasSession();
    if ('error' in session) {
      throw new Error(session.error.description);
    }

    userId = session.userId;
    username = session.givenName;
  } catch (err) {
    console.error(err);
  }
}

async function setLocation() {
  try {
    location = await svpAccess.checkLocation();
  } catch (err) {
    console.error(err);
  }
}

async function setUserAccesses() {
  try {
    userAccesses = await svpAccess.getAccesses();
  } catch (err) {
    console.error(err);
  }
}

export async function initIdentity(
  getIdentityInstance?: () => Promise<Identity>,
  forceReload?: boolean
): Promise<Identity> {
  if (identityInstancePromise && !forceReload) {
    return identityInstancePromise;
  }

  identityInstancePromise = new Promise((resolve) => {
    if (typeof getIdentityInstance === 'function') {
      getIdentityInstance().then((instance) => resolve(instance));
    }
  });

  if (typeof getIdentityInstance === 'function') {
    userDataPromise = setUserData();
  }

  return identityInstancePromise;
}

export async function initMonetization(
  getMonetizationInstance: undefined | (() => Promise<Monetization>),
  vendor: Provider,
  env: ProviderEnvMap[Provider],
  forceReload?: boolean
) {
  if (monetizationInstancePromise && !forceReload) {
    return monetizationInstancePromise;
  }

  monetizationInstancePromise = new Promise((resolve) => {
    if (typeof getMonetizationInstance === 'function') {
      getMonetizationInstance().then((instance) => resolve(instance));
    }
  });

  provider = vendor;
  schibstedAccountEnv = env;

  svpAccess = createSvpAccess({
    provider,
    env: schibstedAccountEnv,
    getIdentityInstance: () => identityInstancePromise,
  });

  locationPromise = setLocation();

  if (typeof getMonetizationInstance === 'function') {
    userAccessesPromise = setUserAccesses();
  }

  return monetizationInstancePromise;
}

export async function login(props: Partial<LoginOptions> = {}) {
  const identity = await identityInstancePromise;

  await identity.login({ state: Date.now().toString(), ...props });

  await Promise.all([setUserData(), setUserAccesses()]);
}

export async function clearSessionCache() {
  const identity = await identityInstancePromise;

  identity.clearCachedUserSession();
}

export function getIdentity() {
  return identityInstancePromise;
}

export async function clearAccessCache() {
  try {
    await svpAccess.clearAccessCache();
  } catch (err) {
    console.error(err);
  }
}

export async function getSignupUrl() {
  const identity = await identityInstancePromise;

  // @ts-expect-error not present in types, kinda undocumented?
  return identity.signupFlowUrl();
}

export async function getLoginUrl() {
  const identity = await identityInstancePromise;

  return identity.loginUrl({
    state: window.location.pathname,
  });
}

export async function getSessionCookie() {
  const identity = await identityInstancePromise;

  try {
    const session = await identity.hasSession();
    if ('error' in session) {
      throw new Error(session.error.description);
    }

    return session.sp_id;
  } catch (error) {
    return null;
  }
}

export async function getUserSettings() {
  const identity = await identityInstancePromise;

  const session = await identity.hasSession();

  return new UserSettings(session as HasSessionSuccessResponse);
}

export async function getSessionSignature() {
  const identity = await identityInstancePromise;

  try {
    const session = await identity.hasSession();
    if ('error' in session) {
      throw new Error(session.error.description);
    }

    return session.sig;
  } catch (error) {
    return null;
  }
}

export function getUserId() {
  return userId;
}

export async function getUserIdAsync() {
  // make first username getter blocking
  await userDataPromise;

  return userId;
}

export async function getUsername() {
  // make first username getter blocking
  await userDataPromise;

  return username;
}

export async function getUserAccesses<T extends Provider>() {
  // make first user accesses getter blocking
  await userAccessesPromise;

  return (userAccesses ?? []) as ProviderAccessMap[T][];
}

export async function getMonetization() {
  return monetizationInstancePromise;
}

export async function getLocation() {
  await locationPromise;
  return location;
}
